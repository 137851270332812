import React from "react";
import {
  CountryContext,
  CountryProvider,
  LanguageContext,
  LanguageProvider,
} from "../../Providers";

export default function WithProviders(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <LanguageProvider language={this.props.pageContext.language}>
          <CountryProvider>
            <LanguageContext.Consumer>
              {(languageContextProps) => (
                <CountryContext.Consumer>
                  {(countryContextProps) => (
                    <WrappedComponent
                      {...this.props}
                      {...countryContextProps}
                      {...languageContextProps}
                    />
                  )}
                </CountryContext.Consumer>
              )}
            </LanguageContext.Consumer>
          </CountryProvider>
        </LanguageProvider>
      );
    }
  };
}
