import React, { useEffect, useState } from "react";
// import { LanguageContext } from "../LanguageProvider";
import fetchTimeout from "../../utils/fetchTimeout";

const IPDataUrl = "https://ip.nf/me.json";

const CountryContext = React.createContext({
  updateCountryCode: () => {},
  countryCode: "IN",
});

const CountryProvider = (props) => {
  const localCountryCode =
    typeof window !== "undefined" &&
    window.localStorage.getItem("website.countryCode");
  const [countryCode, setCountryCode] = useState(localCountryCode || "IN");
  useEffect(() => {
    if (!localCountryCode) {
      const controller = new AbortController();
      fetchTimeout(IPDataUrl, 500, { signal: controller.signal }).then(
        (data) => {
          data
            .json()
            .then((readableData) => {
              setCountryCode(readableData?.ip?.country_code);
              window.localStorage.setItem(
                "website.countryCode",
                readableData?.ip?.country_code
              );
            })
            .catch((err) => {
              console.error(err);
              setCountryCode(localCountryCode || "IN");
            });
        }
      );
    }
  }, [localCountryCode]);

  const updateCountryCode = (countryCode) => {
    setCountryCode(countryCode);
    window.localStorage.setItem("website.countryCode", countryCode);
  };

  return (
    <CountryContext.Provider
      value={{
        updateCountryCode,
        countryCode,
      }}
    >
      {props.children}
    </CountryContext.Provider>
  );
};

export { CountryContext };
export default CountryProvider;
