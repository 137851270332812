const messages = {
  "header.home": "घर",
  "header.products": "उत्पादों",
  "header.technology": "प्रौद्योगिकी",
  "header.about.us": "हमारे बारे में",
  "header.careers": "करियर",
  "header.contact.us": "संपर्क करें",
  "header.language": "भाषा",
  english: "अंग्रेज़ी",
  hindi: "हिंदी",
  signin: "दाखिल करना",
  "signing.in": "साइनइन करने में",
  signup: "साइन अप करें",
  home: "होम",
  features: "विशेषताएं",
  pricing: "मूल्य निर्धारण",
  contact: "संपर्क करें",
  solutions: "समाधान",
  "contact.us": "संपर्क करें",
  "products.smartstore.eazy": "स्मार्टस्टोर ईज़ी",
  "products.smartstore.enterprise": "स्मार्टस्टोर एंटरप्राइज",
  "products.smartproduct.locator": "स्मार्ट उत्पाद लोकेटर",
  "products.smartpayment.gateway": "स्मार्ट पेमेंट गेटवे",
  "products.smartpoint.sale": "बिक्री का स्मार्ट प्वाइंट",
  "products.grocery.express": "किराना एक्सप्रेस",
  "smartstore.eazy.heading": "प्रमुख विशेषताऐं",
  "smartstore.eazy.selling.online":
    "ऑनलाइन बिक्री शुरू करने के लिए आपको जो कुछ भी चाहिए",
  "smartstore.eazy.unique.store": "अपना अनूठा ऑनलाइन स्टोर प्राप्त करें",
  "smartstore.eazy.feature.standout":
    "अपना अनूठा वेबबाइट बनाकर भीड़ में सबसे अलग दिखें",
  "smartstore.eazy.feature.domain":
    "अपने खुद के डोमेन नाम का प्रयोग करें और अपनी ब्रांड वैल्यू बढ़ाएं",
  "smartstore.eazy.feature.colors":
    "ऐसे रंग और थीम चुनें जो आपके ब्रांड को दर्शाते हों",
  "smartstore.eazy.feature.banners":
    "आकर्षक बैनर अपलोड करके अपनी वेबसाइट को बेहतर बनाएं",
  "smartstore.eazy.setup.shop": "अपनी दुकान आसानी से स्थापित करें",
  "smartstore.eazy.create.own":
    "अपनी खुद की दुकान बनाएं - किसी तकनीकी कौशल की आवश्यकता नहीं है",
  "smartstore.eazy.feature.setup.online":
    "अपनी ऑनलाइन दुकान को आसानी से सेट करने के लिए हमारे चरण-दर-चरण मार्गदर्शिका का उपयोग करें",
  "smartstore.eazy.feature.text.boxes":
    "साधारण टेक्स्ट बॉक्स, ड्रॉप-डाउन और ड्रैग एंड ड्रॉप मेनू का उपयोग करके सभी परिवर्तन करें",
  "smartstore.eazy.feature.catalogue":
    "अपना कैटलॉग बनाएं, मूल्य निर्धारित करें, शिपिंग नियम निर्धारित करें और ऑनलाइन बिक्री शुरू करें",
  "smartstore.eazy.feature.mobile": "तुरंत मोबाइल जाओ",
  "smartstore.eazy.additional.sales":
    "मोबाइल उपकरणों पर बिक्री करके अतिरिक्त बिक्री प्राप्त करें",
  "smartstore.eazy.selling.mobile":
    "मोबाइल पर तुरंत बिक्री शुरू करें क्योंकि आपकी वेबसाइट मोबाइल उपकरणों पर और भी शानदार दिखेगी",
  "smartstore.eazy.shopping.convenience":
    "खरीदारी की सुविधा को सक्षम करें क्योंकि शॉपिंग कार्ट सभी उपकरणों में समान होगा",
  "smartstore.eazy.international.app":
    "अपना खुद का अंतरराष्ट्रीय गुणवत्ता वाला Android और iOS ऐप प्राप्त करें",
  "smartstore.eazy.manage.go": "इसे चलते-फिरते प्रबंधित करें",
  "smartstore.eazy.easy.access":
    "अपने Android मोबाइल पर अपने शॉप-एडमिन तक आसानी से पहुंचें",
  "smartstore.eazy.update.prices": "कीमतों, स्टॉक और छवियों को अपडेट करें",
  "smartstore.eazy.important.alerts":
    "ऑर्डर प्लेसमेंट, भुगतान और रद्दीकरण जैसी महत्वपूर्ण घटनाओं पर अलर्ट प्राप्त करें",
  "smartstore.eazy.customer.reply":
    "बिना किसी देरी के ग्राहक के ईमेल और चैट का जवाब दें",
  "smartstore.eazy.customer.reachout": "अपने ग्राहकों तक पहुंचें",
  "smartstore.eazy.powerful.seo":
    "हमारे शक्तिशाली SEO के साथ नए ग्राहक प्राप्त करें",
  "smartstore.eazy.offers.coupons":
    "अपने ग्राहकों के एक वर्ग को लक्षित करने वाले ऑफ़र और कूपन बनाएं",
  "smartstore.eazy.marketing.campaigns":
    "विशिष्ट ग्राहकों को एसएमएस, ईमेल और ऐप-सूचनाओं के माध्यम से लक्षित विपणन अभियान चलाएं",
  "smartstore.eazy.manage.customers": "अपने ग्राहकों को प्रबंधित करें",
  "smartstore.eazy.build.loyalty":
    "वफादारी बनाने के लिए हमारे शक्तिशाली सीआरएम का उपयोग करें",
  "smartstore.eazy.view.customers":
    "अपने सभी ग्राहकों को उनके विस्तृत खरीदारी इतिहास के साथ एक ही स्थान पर देखें",
  "smartstore.eazy.upload.details":
    "अपने ऑफ़लाइन-ग्राहकों को समान स्तर की सेवा प्रदान करने के लिए उनका विवरण अपलोड करें",
  "smartstore.eazy.add.edit.details":
    "अपने ग्राहकों का पता, ईमेल और फ़ोन नंबर जैसे विवरण जोड़ें या संपादित करें",
  message404:
    "आपने इंटरनेट नहीं तोड़ा, लेकिन हम वह नहीं ढूंढ सकते जो आप ढूंढ रहे हैं।",
  "go.home": "होमपेज पर जाये",
  "zopping.pricing": "मूल्य निर्धारण योजना",
  "pay.for.use":
    "हमें केवल उसी के लिए भुगतान करें जो आप हमारे माध्यम से ऑनलाइन बेचते हैं",
  "currency.selector": "मुद्रा चयनकर्ता",
  "everything.free": "डेस्कटॉप और मोबाइल वेब सहित सब कुछ मुफ़्त है",
  "android.app.customers": "ग्राहकों के लिए Android ऐप",
  "ios.app.customers": "ग्राहकों के लिए आईओएस ऐप",
  "picker.app.employees": "कर्मचारियों के लिए पिकर ऐप",
  "logistics.app.employees": "कर्मचारियों के लिए लॉजिस्टिक ऐप",
  "buy.now": "अभी खरीदें",
  "contact.us.support": "समर्थन के लिए हमसे संपर्क करें",
  "customization.integration": "अनुकूलन और एकीकरण के लिए हमसे संपर्क करें",
  "cant.find.answer": "उत्तर नहीं मिल रहा है?",
  "pricing.faq": "मूल्य निर्धारण - अक्सर पूछे जाने वाले प्रश्न",
  "view.more": "और देखो",
  "view.less": "कम देखें",
  "more.than": "इससे अधिक",
  "month.sales": "महीने की बिक्री",
  "upto.text": "तक",
  heroHeadingText: "अपना व्यवसाय ऑनलाइन बढ़ाएँ",
  heroParagraphText: "विक्रेताओं के लिए सर्वश्रेष्ठ ई-कॉमर्स प्लेटफॉर्म",
  heroFormButtonText: "मुफ्त में ऑनलाइन बिक्री शुरू करें",
  heroFormInputPlaceholder: "ईमेल पता या फोन नंबर",
  "email.two.days": "दो कार्य दिवसों के भीतर ईमेल",
  "email.instant.chat": "ईमेल और तत्काल चैट जितनी जल्दी हो सके",
  "email.and.telephonic": "जितनी जल्दी हो सके ईमेल, तत्काल चैट और टेलीफोनिक",
  "email.account.manager":
    "ईमेल, तत्काल चैट, टेलीफोनिक और खाता प्रबंधक को सौंपा जाएगा",
  testimonialsHeading: "हमारे ग्राहक जो करते हैं उसे पसंद करते हैं",
  testimonialsSubHeading:
    "हमारे ग्राहक हमें हमारी सेवाओं के साथ मुस्कान का गुच्छा भेजते हैं और हम उन्हें प्यार करते हैं",
  "select.language.heading": "भाषा का चयन करें",
  "my.account": "मेरा खाता",
  "logout.text": "लॉग आउट",
  "privacy.policy": "गोपनीयता नीति",
  "pricing.comparison.heading": "हमें क्यों चुना जाए?",
  "pricing.comparison.subheading": "दूसरों के साथ हमारी सुविधा की तुलना देखें",
  "compare.with.others": "दूसरों के साथ तुलना करें",
  customDomain: "कस्टम डोमेन",
  onlineStore: "ऑनलाइन स्टोर",
  catalogue: "सूची",
  payments: "भुगतान",
  marketingOffers: "मार्केटिंग और ऑफ़र",
  orderManagement: "आदेश का प्रबंधन",
  ratingsReviews: "रेटिंग और समीक्षाएं",
  support: "ईमेल, चैट और फोन कॉल के माध्यम से सहायता",
  salesChannels: "बिक्री चैनल",
  orderCreation: "मैनुअल ऑर्डर निर्माण",
  discounts: "उत्पाद छूट और कूपन छूट",
  ssl: "एसएसएल प्रमाणपत्र",
  cart: "परित्यक्त गाड़ी",
  giftCards: "उपहार कार्ड",
  reportsAnalytics: "रिपोर्ट और विश्लेषिकी",
  reportAnalytics: "रिपोर्ट और विश्लेषिकी",
  yes: "हां",
  no: "नहीं",
  limited: "सीमित",
  unlimited: "असीमित",
  hostingSsl: "होस्टिंग और एसएसएल प्रमाणपत्र",
  themes: "थीम",
  customerSignup: "ग्राहक साइनअप पृष्ठ",
  multiStore: "मल्टी स्टोर",
  multiSales: "मल्टी सेल्स चैनल",
  products: "उत्पादों",
  collectionTags: "संग्रह और टैग",
  weightBasedPrices: "वजन आधारित कीमतें",
  productSubstitution: "उत्पाद प्रतिस्थापन",
  stockManagement: "स्टॉक प्रबंधन",
  rackManagement: "रैक प्रबंधन",
  gatewayInteg: "गेटवे एकीकरण",
  walletCashback: "वॉलेट और कैशबैक",
  cashOnDelivery: "डिलवरी पर नकदी",
  voucherGifts: "वाउचर/उपहार कार्ड",
  campaigns: "अभियान (ईमेल/एसएमएस)",
  notifications: "एप्लिकेशन सूचनाएं",
  customSeo: "कस्टम एसईओ",
  coupons: "कूपन",
  offers: "उत्पाद/संग्रह/टैग पर ऑफ़र",
  manualOrder: "मैनुअल ऑर्डर क्रिएशन",
  pickerApp: "पिकर मोबाइल ऐप",
  intelligentPicking: "बुद्धिमान पिकिंग कतार",
  deliveryManagement: "वितरण प्रबंधन",
  surcharge: "अधिभार",
  slotCharges: "स्लॉट आधारित शुल्क",
  vehiclePlanning: "वाहन योजना",
  routePlanning: "रूट की योजना",
  deliveryPersonTracking: "डिलीवरी पर्सन ट्रैकिंग",
  thirdPartyLogistics: "तृतीय-पक्ष रसद एकीकरण",
  staffManagement: "कर्मचारी प्रबंधन",
  multiUsers: "मल्टी उपयोगकर्ता",
  employeeMobileApp: "कर्मचारी मोबाइल ऐप",
  attendanceSummary: "उपस्थिति सारांश",
  roleBasedPermissions: "भूमिका-आधारित अनुमतियां",
  reports: "रिपोर्ट",
  analytics: "विश्लेषिकी",
  monthlyFixedCharges: "मासिक निश्चित शुल्क",
  commissions: "आयोगों",
  freePlugins: "मुफ्त प्लगइन्स",
  customerMobileApp: "ग्राहक मोबाइल ऐप (आईओएस और एंड्रॉइड)",
  "contact.help.banner.question": "हम आपकी कैसे मदद कर सकते हैं?",
  "contact.help.banner.tagline":
    "बस अपना संपर्क विवरण नीचे दें और हमारे ई-कॉमर्स विशेषज्ञ आपसे जल्द से जल्द संपर्क करेंगे",
  "contact.placeholder.fullname": "पूरा नाम",
  "contact.placeholder.phone": "फ़ोन नंबर",
  "contact.placeholder.email": "मेल पता",
  "contact.placeholder.message": "मेसेज",
  "contact.reachout": "त्वरित प्रतिक्रिया के लिए हमसे संपर्क करें",
  "contact.reachout.question": "कोई सवाल है? हमारे साथ संपर्क में जाओ",
  "contact.chat": "हमारे साथ चैट करें",
  "map.message": "हम यहीं हैं",
  "map.viewmap": "मानचित्र में देखें",
  "zopping.welcome": "ज़ोपिंग में आपका स्वागत है",
  "zopping.loginForm.subheading": "अपना विवरण नीचे दर्ज करें",
  "loginForm.username.placeholder": "अपना ईमेल या फोन नंबर दर्ज करें",
  "feature.one.description":
    "नि:शुल्क शुरुआत करें और केवल उसी के लिए भुगतान करें जिसका आप उपयोग करते हैं",
  "feature.two.description":
    "सभी सुविधाएं और ऐप्स बिना किसी अतिरिक्त लागत के पूरी तरह से अनलॉक हो गए",
  "feature.three.description":
    "एक परिवर्तनीय मासिक शुल्क जो आपकी मासिक ऑनलाइन बिक्री के बाद आता है",
  "highlight.heading": "आपको क्या मिलता है",
  "monthly.store.sales": "मासिक ऑनलाइन स्टोर बिक्री",
  "charges.per.month": "प्रति माह शुल्क",
  "setup.store.free": "अपना स्टोर मुफ़्त में सेट करें!",
  password: "कुंजिका",
  "loginForm.password.placeholder": "अपना कूटशब्द भरें",
  "remember.me": "मुझे याद रखना",
  "dont.have.account": "खाता नहीं है",
  "create.account": "खाता बनाएं",
  name: "नाम",
  "enter.name": "अपना नाम दर्ज करें",
  "create.your.account": "अपने खाते बनाएँ",
  "creating.account": "अपना खाता बनाना",
  "already.have.account": "क्या आपके पास पहले से एक खाता मौजूद है",
  "forgot.password": "पासवर्ड भूल गए?",
  "submit.text": "प्रस्तुत करना",
  submitting: "भेजने से",
  "resetPassword.successMessage":
    "आपका पासवर्ड सफलतापूर्वक रीसेट कर दिया गया है",
  "resetPassword.loginLinkText": "लॉगिन पर जाएं",
  "can.try.zopping": "क्या मैं भुगतान किए बिना ज़ोपिंग आज़मा सकता हूँ?",
  "sign.up.free.trial":
    "हां। बस साइन अप करें और प्लेटफॉर्म के 15 दिनों के नि:शुल्क परीक्षण का लाभ उठाएं।",
  "enter.credit.card":
    "क्या ट्रायल में भाग लेने के लिए मुझे अपना क्रेडिट कार्ड नंबर या कोई अन्य भुगतान विवरण दर्ज करने की आवश्यकता है?",
  "no.requirement.payment.details":
    "नहीं, परीक्षण में भाग लेने के लिए कोई भुगतान विवरण दर्ज करने की आवश्यकता नहीं है। आप बस साइन-अप कर सकते हैं और प्लेटफॉर्म का उपयोग शुरू कर सकते हैं",
  "trial.over": "एक बार मेरा परीक्षण समाप्त हो जाने पर क्या होता है?",
  "continue.using.platform":
    "यदि आप प्लेटफॉर्म का उपयोग जारी रखना चाहते हैं, तो आपको अपने इन-बिल्ट वॉलेट को न्यूनतम 2,000 रुपये से रिचार्ज करके भुगतान करना होगा। यदि आप परीक्षण समाप्ति के 15 दिनों के भीतर अपने वॉलेट को रिचार्ज नहीं करते हैं, तो आपका खाता स्थायी रूप से हटा दिया जाएगा।",
  "how.do.i.pay": "मैं कैसे भुगतान करूं?",
  "account.wallet": "अकाउंट वॉलेट",
  "charge.admin.panel":
    "में पैसे स्टोर और रिचार्ज कर सकते हैं । हमारा भुगतान गेटवे सभी मानक क्रेडिट कार्ड, डेबिट कार्ड, बैंक हस्तांतरण, यूपीआई और ई-वॉलेट स्वीकार करता है।",
  "features.during.trial": "परीक्षण के दौरान मुझे क्या सुविधाएँ मिलती हैं?",
  "features.page": "सुविधाएँ पृष्ठ",
  "addon.features": "ऐड-ऑन विशेषताएं क्या हैं?",
  "addon.features.answer":
    "ऐड-ऑन सुविधाएं ऐसी कार्यक्षमताएं हैं जो अलग-अलग व्यवसायों के लिए विशिष्ट हैं और सभी के लिए इसकी आवश्यकता नहीं हो सकती है। आप इन सभी कार्यात्मकताओं को देख सकते हैं और अपनी आवश्यकता के अनुसार चालू कर सकते हैं।",
  "addon.features.charge": "क्या ऐड-ऑन सुविधाएं प्रभार्य हैं?",
  "addon.features.charge.answer":
    "अधिकांश ऐड-ऑन सुविधाएं निःशुल्क हैं और आपको उनका उपयोग शुरू करने के लिए बस उन्हें सक्षम करने की आवश्यकता है। सशुल्क ऐड-ऑन सुविधाओं के लिए, व्यक्तिगत सुविधा के मूल्य के अनुसार भुगतान आपके अकाउंट वॉलेट से काट लिया जाता है।",
  "turnoff.addon.features":
    "क्या मैं ऐड-ऑन सुविधाओं को कभी भी बंद कर सकता हूँ?",
  "turnoff.addon.features.answer":
    "हां। लेकिन इन सुविधाओं को बंद करते समय आपको बेहद सावधान रहने की जरूरत है क्योंकि आप उनका संबंधित डेटा स्थायी रूप से खो सकते हैं।",
  "remind.balance.low":
    "क्या आप मुझे याद दिलाएंगे कि मेरे खाते में वॉलेट का बैलेंस कम है या नहीं?",
  "remind.balance.low.answer":
    "हां। आपके खाते की शेष राशि 2000 रुपये से कम होने पर हम आपको एसएमएस और ईमेल के माध्यम से कई रिमाइंडर भेजेंगे।",
  "negative.balance.wallet":
    "क्या होता है जब मेरे खाते की वॉलेट शेष राशि मासिक शुल्क का भुगतान करने के लिए अपर्याप्त होती है?",
  "negative.balance.reminder":
    "हम आपके वॉलेट में अपर्याप्त धनराशि के बारे में ईमेल, पुश नोटिफिकेशन और एसएमएस जैसे कई चैनलों के माध्यम से आपको सचेत करते हैं और आपसे अपने वॉलेट को रिचार्ज करने का अनुरोध करते हैं।",
  "negative.balance.case.one":
    "केस 1: यदि वॉलेट बैलेंस लगातार 15 दिनों से अधिक समय तक नकारात्मक रहता है तो आपका खाता स्वचालित रूप से अक्षम हो जाएगा अर्थात आपकी वेबसाइट डाउन हो जाएगी, हालांकि आप अपने खाते-व्यवस्थापक तक पहुंच पाएंगे। जैसे ही आप अपने वॉलेट को रिचार्ज करेंगे और बैलेंस पॉजिटिव करेंगे, आपकी वेबसाइट फिर से लाइव हो जाएगी।",
  "negative.balance.case.two":
    "केस 2: यदि वॉलेट बैलेंस लगातार 60 दिनों से अधिक के लिए नकारात्मक है तो खाता अपने आप हटा दिया जाएगा अर्थात आपकी वेबसाइट और व्यवस्थापक खाता दोनों हटा दिए जाएंगे। आपका सारा डेटा स्थायी रूप से खो जाएगा।",
  "stop.using.platform":
    "अगर मैं आपके प्लेटफॉर्म का उपयोग बंद करना चाहता हूं, तो क्या मुझे अपना वॉलेट-पैसा वापस मिलेगा?",
  "stop.using.platform.answer":
    "हां। आप किसी भी समय हमें बता सकते हैं कि आप हमारे प्लेटफॉर्म का उपयोग बंद करना चाहते हैं। हम चल रहे महीने के किराये के भुगतान और अब तक की बकाया राशि की कटौती के बाद आपकी वॉलेट राशि वापस कर देंगे।",
  install: "इंस्टॉल",
  installing: "स्थापित कर रहा है",
  uninstall: "स्थापना रद्द करें",
  uninstalling: "की स्थापना रद्द",
  installed: "स्थापित",
  "registered.user.text": "आप पहले से ही पंजीकृत हैं",
  "zopping.features": "ज़ॉपिंग विशेषताएं",
  "feature.description":
    "ज़ोपिंग द्वारा की जा सकने वाली सभी आश्चर्यजनक चीज़ों की बेहतर समझ प्राप्त करें।",
  "login.or.text": "या",
  "login.with.google": "Google के साथ लॉगिन करें",
  "signup.with.google": "Google के साथ साइन अप करें",
  free: "मुफ़्त",
  customizable: "अनुकूलन",
  standard: "मानक",
  premium: "अधिमूल्य",
  "stay.in.touch": "संपर्क में रहना",
  "about.us": "हमारे बारे में",
  "company.text": "कंपनी",
  "blog.text": "ब्लॉग",
  "terms.conditions": "नियम और शर्तें",
  "hidden.costs": "क्या कोई छिपी हुई लागत है?",
  "hidden.costs.answer":
    "नहीं, कोई नहीं है। सभी सुविधाएं, थीम, एक्सटेंशन और ऐप्स पूरी तरह से निःशुल्क हैं। हालाँकि, यदि आप अपना स्वयं का डोमेन नाम चुनते हैं, तो आपको अपने डोमेन प्रदाता को भुगतान करना पड़ सकता है। डिलीवरी शुल्क और भुगतान गेटवे शुल्क, जैसा कि आपके और संबंधित पक्षों के बीच सहमति है, हमारे मूल्य निर्धारण योजना में शामिल नहीं हैं और इसलिए आपको वहन करना होगा।",
  "cost.online.store": "मेरा ऑनलाइन स्टोर स्थापित करने में कितना खर्च आता है?",
  "cost.online.store.answer":
    "अपना ऑनलाइन स्टोर स्थापित करना पूरी तरह से मुफ़्त है। आपको हमें कुछ भी भुगतान करने की आवश्यकता नहीं है जब तक कि आप न्यूनतम रु. आपके ऑनलाइन स्टोर के माध्यम से प्रति माह 20000 मूल्य के ऑर्डर। वहां से, शुल्क ऊपर उल्लिखित मूल्य निर्धारण योजना पर आधारित होते हैं। चूंकि आपके ऑनलाइन स्टोर के माध्यम से आपकी मासिक बिक्री हर महीने बदलती रहती है, इसलिए आपके मासिक शुल्क भी हैं।",
  "highlight.hosting": "होस्टिंग (ज़ोपिंग सबडोमेन) और एसएसएल सर्टिफिकेट",
  "highlight.themes": "पूरी तरह से अनुकूलन थीम",
  "highlight.product.listing": "असीमित उत्पाद लिस्टिंग",
  "highlight.unlimited.users": "असीमित उपयोगकर्ता",
  "highlight.unlimited.operations": "असीमित बहु-स्टोर संचालन",
  "highlight.apps": "उपभोक्ता ऐप, पिकर ऐप, डिलीवरी ऐप और एडमिन ऐप",
  "highlight.extensions": "सभी प्लगइन्स / एक्सटेंशन",
  "highlight.gateway.integrations": "भुगतान गेटवे एकीकरण",
  "highlight.delivery.partner": "डिलीवरी पार्टनर इंटीग्रेशन",
  "highlight.social.media": "सोशल मीडिया और गूगल एपीआई एकीकरण",
  "highlight.customer.support": "24x7 ग्राहक सहायता",
  "less.than": "से कम",
  "email.error.msg": "कृपया एक मान्य ईमेल पता प्रविष्ट करें। जैसे-abc@xyz.com",
  "anyone.can.sell.anything": "कोई भी ऑनलाइन कुछ भी बेच सकता है!",
  "brands.retailers.resellers":
    "ब्रांड से लेकर खुदरा विक्रेताओं से लेकर पुनर्विक्रेताओं से लेकर ऑनलाइन-प्रथम उद्यमियों तक कोई भी एक स्थापित कर सकता है और चला सकता है",
  "matter.few.minutes": "कुछ ही मिनटों में",
  "setup.free.online.store": "अपना मुफ़्त ऑनलाइन स्टोर सेटअप करें",
  "schedule.demo": "एक डेमो शेड्यूल करें",
  "zero.web.dev.skills": "शून्य वेब विकास कौशल की आवश्यकता",
  "payments.handle.deliveries":
    "भुगतान, वितरण संभालें, अपने ब्रांड का प्रचार करें और हमारे सहज और अनुकूलन योग्य इंटरफ़ेस का उपयोग करके आसानी से ऑनलाइन बढ़ें।",
  "your.store.customers.rules":
    "आपका स्टोर, आपके ग्राहक, आपका मार्जिन, आपके नियम",
  "experience.online.marketplaces":
    "Amazon, Flipkart, Udaan, AliExpress, eBay, आदि जैसे ऑनलाइन मार्केटप्लेस के माध्यम से बेचने के अनुभव से निराश और अपने प्रतिस्पर्धियों के लिए अपने कड़ी मेहनत से जीते गए ग्राहकों को खो देना? अपने ऑनलाइन स्टोर और मोबाइल ऐप के साथ, आप अपने ब्रांड और ग्राहक अनुभव को नियंत्रित करते हैं, अपनी मेहनत की कमाई और ग्राहक डेटा को पूरी तरह से अपने पास रखते हैं।",
  "explore.our.features": "हमारी सुविधाओं का अन्वेषण करें",
  "platform.specific.business":
    "आपके व्यवसाय की विशिष्ट प्रकृति के लिए बनाया गया एक मंच",
  "handle.thousands.transactions":
    "एक दिन में हजारों लेन-देन को सहजता से संभालता है और आपके व्यवसाय को बढ़ाता है।",
  "explore.prices": "कीमतों का अन्वेषण करें",
  "start.online.store": "कुछ क्लिक के साथ अपना ऑनलाइन स्टोर शुरू करें",
  "sell.groceries.medicines":
    "किराने का सामान, दवाएं, फर्नीचर, किताबें, इलेक्ट्रॉनिक्स, सौंदर्य उत्पाद, आभूषण, कपड़े, हस्तशिल्प, बेकरी उत्पाद या कुछ भी ऑनलाइन बेचें।",
  "store.information": "दुकान की जानकारी",
  "enter.store.name.logo": "अपने स्टोर का नाम और लोगो दर्ज करें",
  "select.domain": "डोमेन चुनें",
  "choose.store.url": "अपना स्टोर URL चुनें",
  "select.industry": "उद्योग का चयन करें",
  "pick.business.operate": "वह व्यवसाय चुनें जो आप संचालित करते हैं",
  "select.theme": "चुनिंदा विषय",
  "choose.theme.liking": "अपनी पसंद की थीम या रंग चुनें",
  "download.now": "अभी डाउनलोड करें",
  "unlock.ecommerce.power": "ज़ोपिंग ऐप के साथ ई-कॉमर्स की शक्ति को अनलॉक करें",
  "features.banner.one.description":
    "मैं अपना ऑनलाइन स्टोर स्थापित करना और चलाना बहुत आसान हो गया",
  "features.banner.two.description":
    "मैं कई 'इंस्टॉल करने योग्य' एक्सटेंशन के माध्यम से उन्नत अनुकूलन संभव हुए हैं",
  "features.banner.three.description":
    "मैं ज़ोपिंग मोबाइल ऐप के माध्यम से सभी सुविधाओं तक पहुँचें",
  hosting: "होस्टिंग",
  "hosting.desc":
    "साइन अप करने पर मुफ़्त होस्टिंग के साथ एक मुफ़्त @zopping.com डोमेन प्राप्त करें। आप तुरंत या बाद की तारीख में अपने डोमेन पर जा सकते हैं।",
  "ssl.desc":
    "हम आपके ग्राहकों के लिए एक सुरक्षित और सुरक्षित खरीदारी अनुभव को सक्षम करने के लिए आपके डोमेन के लिए एक निःशुल्क 256-बिट एसएसएल प्रमाणपत्र प्रदान करते हैं।",
  "themes.desc":
    "हमारे टेम्प्लेट की लाइब्रेरी में से चुनें जो आपके व्यवसाय की प्रकृति से मेल खाती हो। अपने स्टोर को अपने ब्रांड को दर्शाने के लिए पेज लेआउट, मेनू और रंग अनुकूलित करें, लोगो, फ़ेविकॉन, स्थिर पृष्ठ और ब्लॉग जोड़ें। अपनी वेबसाइट को तोड़े बिना आसानी से थीम के बीच स्विच करें।",
  "search.box": "खोज बॉक्स",
  "search.box.desc":
    "हमारे बुद्धिमान और व्यक्तिगत खोज कार्यक्षमता का उपयोग करके अपने ग्राहकों को अपने स्टोर से उत्पादों की खोज करने में सक्षम बनाएं। किसी विशेष उत्पाद/श्रेणी/ब्रांड को बढ़ावा देने के लिए विशिष्ट खोज शब्दों के लिए खोज परिणामों को तैयार और ठीक करें।",
  "multi.language": "बहु-भाषा",
  "multi.language.desc":
    "उन भाषाओं को सेट करें जिन्हें आप अपने ई-स्टोर पर समर्थन देना चाहते हैं और अपने ग्राहक को अपनी पसंद की भाषा में अपने ई-स्टोर पर ब्राउज़ करने और खरीदारी करने की अनुमति दें।",
  "product.listing": "उत्पाद प्रविष्टि",
  "product.listing.desc":
    "अपने उत्पादों को श्रेणियों और बहु-स्तरीय उप-श्रेणियों में अपलोड और आसानी से समूहित करें। अंतर्निहित कस्टम फ़ील्ड का उपयोग करके चित्र, विवरण, टैग, स्टॉक, मूल्य, छूट, कर की दरें, उपकर और बहुत कुछ जोड़ें। अपने उत्पादों को आसानी से व्यक्तिगत रूप से या बल्क में अपलोड या संपादित करें।",
  "sell.by.brand": "ब्रांड द्वारा बेचना",
  "sell.by.brand.desc":
    "कैटलॉग पर अपने उत्पादों में एक 'ब्रांड' फ़ील्ड जोड़ें ताकि आपके ग्राहक ब्रांड नाम के आधार पर उत्पादों को खोज और फ़िल्टर कर सकें।",
  "items.sold.by.weight": "वजन के हिसाब से बिकने वाले आइटम",
  "items.sold.by-weight.desc":
    "ढीले सामान और वजन के हिसाब से बेचे जाने वाले सामान जैसे फल, सब्जियां, अनाज, मांस, धातु आदि बेचना शुरू करें।",
  "product.variants": "उत्पाद प्रकार",
  "product.variants.desc":
    "रंग, आकार, वजन आदि के आधार पर विविधता वाले उत्पादों को निर्बाध रूप से संभालें। प्रत्येक संस्करण के लिए फ़ोटो, मूल्य और छूट अपडेट करें।",
  "product.subsitution": "उत्पाद प्रतिस्थापन",
  "product.subsitution.desc":
    "मूल रूप से ऑर्डर किया गया उत्पाद अनुपलब्ध होने की स्थिति में अपने उत्पादों के लिए विकल्प सेट करें और स्थानापन्न उत्पाद भेजकर अपनी भरण दर में सुधार करें।",
  "stock.management.desc":
    "स्टॉक डेटा अपलोड या डाउनलोड करें, बफर स्टॉक सेट करें और जब कोई उत्पाद स्टॉक से बाहर हो जाए तो अलर्ट प्राप्त करें।",
  "payment.gateway.3rd.party": "तृतीय - पक्ष भुगतान गेटवे एकीकरण",
  "payment.gateway.3rd.party.desc":
    "अपने भुगतान तंत्र को शीघ्रता से स्थापित करने के लिए पूर्व-कॉन्फ़िगर किए गए तृतीय पक्ष भुगतान गेटवे के संपूर्ण होस्ट का लाभ उठाएं । अपने व्यापारी लेनदेन शुल्क को अनुकूलित करने के लिए कई गेटवे के माध्यम से अपने भुगतान मार्ग को बुद्धिमानी से स्वचालित करें।",
  "paypal.integration": "पेपैल एकीकरण",
  "paypal.integration.desc":
    "हमारे अंतर्निर्मित पेपैल एकीकरण के साथ अंतरराष्ट्रीय आदेश और भुगतान स्वीकार करें।",
  "your.ewallet": "आपका ई-वॉलेट",
  "your.ewallet.desc":
    "अपने ग्राहकों की वफादारी में सुधार करें और ग्राहकों को अपने ब्रांडेड ई-वॉलेट की पेशकश करके उन्हें पुरस्कृत करें। आपके ग्राहक अपने वॉलेट में पैसे जोड़ सकते हैं और भविष्य की खरीदारी के दौरान इसका इस्तेमाल कर सकते हैं।",
  "gift.cards.desc":
    "अपने ग्राहकों को अनुकूलन योग्य ई-गिफ्ट कार्ड बेचकर अपने प्रियजनों को उपहार देने में सक्षम करें जिन्हें केवल आपके स्टोर में भुनाया जा सकता है।",
  cod: "डिलीवरी पर नकद (सीओडी)",
  "cod.desc": "अपने ग्राहकों को सीओडी कार्यक्षमता प्रदान करें।",
  "webpages.and.banners": "वेबपेज और बैनर",
  "webpages.and.banners.desc":
    "नए उत्पाद लॉन्च, ऑफ़र, मौसमी और उत्सव की बिक्री आदि को बढ़ावा देने के लिए अपनी वेबसाइट के विभिन्न पृष्ठों के लिए कस्टम वेब पेज और वेब बैनर बनाएं और शेड्यूल करें।",
  "offers.management": "ऑफ़र प्रबंधन",
  "offers.management.desc":
    "उत्पादों/श्रेणियों/ब्रांडों/ग्राहकों पर ऑफ़र के 10+ वेरिएंट बनाएं, कस्टमाइज़ करें, चलाएं और ट्रैक करें (जैसे फ़्लैट ऑफ़/% ऑफ़/मिनट. ख़रीद/कॉम्बो/बाय-वन-गेट-वन/% अतिरिक्त) खंड। किसी ऑफ़र की प्रयोज्यता के लिए अपनी सीमाएं और नियम निर्धारित करें।",
  "campaign.management": "अभियान प्रबंधन",
  "campaign.management.desc":
    "अपने ग्राहकों को ऐप नोटिफिकेशन, ईमेल और एसएमएस के माध्यम से प्रचार अभियान बनाएं, शेड्यूल करें, चलाएं और मॉनिटर करें। कूपन वितरित करें, उत्पाद लॉन्च की घोषणा करें, कीमतों में गिरावट की चेतावनी दें, आदि। लक्षित अभियान चलाने के लिए इन-बिल्ट ग्राहक विभाजन उपकरण का उपयोग करें।",
  "coupons.desc":
    "अपने ग्राहकों को उत्पाद या शिपिंग छूट की पेशकश करने के लिए अद्वितीय या मानक कूपन उत्पन्न और वितरित करें। डिस्काउंट सीमा निर्धारित करें और ऑर्डर मूल्य/भुगतान विकल्प/आदेश दिवस/ग्राहक खंड/स्टोर के आधार पर कूपन की प्रयोज्यता को सीमित करें। कूपन के वितरण और परिणामी बिक्री की सफलता को मापें और उसकी निगरानी करें।",
  seo: "सर्च इंजन ऑप्टिमाइजेशन (एसईओ)",
  "seo.desc":
    "जब आपके ग्राहक इंटरनेट पर आपको या आपके उत्पादों को खोजते हैं तो उन्हें पता चलता है। अपने पेज के शीर्षक, विवरण और कीवर्ड को Google खोजों में उच्च प्रदर्शित करने के लिए सेट करें।",
  "orders.dashboard": "ऑर्डर डैशबोर्ड",
  "orders.dashboard.desc":
    "ऑर्डर नंबर, ग्राहक का नाम, संपर्क विवरण, ऑर्डर का समय, ऑर्डर की स्थिति, भुगतान की स्थिति, ऑर्डर वैल्यू आदि जैसे संपूर्ण विवरण के साथ आपके सभी ऑर्डर को एक ही स्थान पर देखने के लिए एक सहज और आसानी से नेविगेट करने वाला डैशबोर्ड।",
  "orders.alerts": "ऑर्डर अलर्ट",
  "orders.alerts.desc":
    "ऑर्डर प्लेसमेंट, कैंसिलेशन, डिलीवरी आदि जैसी महत्वपूर्ण घटनाओं पर तत्काल एसएमएस / पुश नोटिफिकेशन प्राप्त करके अपने ग्राहकों के ऑर्डर के शीर्ष पर रहें।",
  "delivery.area.management": "वितरण क्षेत्र प्रबंधन",
  "delivery.area.management.desc":
    "उन क्षेत्रों को प्रतिबंधित करें जहां आप पिनकोड या स्टोर से दूरी या मानचित्र पर ड्राइंग के आधार पर वितरित कर सकते हैं।",
  "delivery.partner.integration": "डिलीवरी पार्टनर इंटीग्रेशन",
  "delivery.partner.integration.desc":
    "अपने ग्राहक ऑर्डर खुद डिलीवर करें या हमारे डिलीवरी पार्टनर्स की सूची में से अपने शहर में या भारत में कहीं भी शिप करने के लिए चुनें।",
  "customer.details": "ग्राहक विवरण",
  "customer.details.desc":
    "आपके सभी ग्राहक डेटा और उनका खरीद इतिहास आपके संपादन, डाउनलोड, खोज या समूह के लिए एक ही स्थान पर उपलब्ध हैं। अपना मौजूदा ग्राहक डेटा आयात करें और सब कुछ एक ही स्थान पर रखें।",
  "live.chat": "लाइव चैट",
  "live.chat.desc":
    "अपने स्टोर में खरीदारी करते समय अपने ग्राहकों के प्रश्नों को लाइव चैट विजेट के माध्यम से हल करें।",
  "order.returns": "ऑर्डर रिटर्न",
  "order.returns.desc":
    "ऑर्डर रिटर्न को निर्बाध रूप से प्रबंधित करें, अपनी इन्वेंट्री को स्वचालित रूप से समायोजित करें और अपने ग्राहकों को धनवापसी करें।",
  "multi.user.access": "बहु-उपयोगकर्ता पहुंच",
  "multi.user.access.desc":
    "अपने कर्मचारियों को अपने स्टोर का प्रबंधन करने दें। भूमिकाएँ और अनुमतियाँ सेट करें। शिफ्ट और उपस्थिति का प्रबंधन करें।",
  "multi.store": "मल्टी-स्टोर",
  "multi.store.desc":
    "एक से अधिक भौतिक स्टोर हैं? हम आपको कीमतों, ऑफ़र, डिलीवरी शुल्क के आधार पर प्रबंधन करने देते हैं",
  "reports.desc":
    "मानक बिक्री, विपणन, संचालन, ग्राहक, स्टॉक रिपोर्ट डाउनलोड करें या अपनी कस्टम रिपोर्ट बनाएं।",
  "analytics.desc":
    "अपने व्यवसाय के स्वास्थ्य को ट्रैक करने के लिए हमारे डैशबोर्ड का उपयोग करें। सार्थक अंतर्दृष्टि और व्यावसायिक बुद्धिमत्ता प्राप्त करने के लिए अपनी बिक्री, विपणन अभियानों, संचालन, आदेशों, ग्राहक वृद्धि, स्टॉक की निगरानी और तुलना करें।",
  "fb.analytics": "फेसबुक एनालिटिक्स",
  "fb.analytics.desc":
    "अपनी फेसबुक पिक्सेल आईडी का उपयोग करके अपने फेसबुक विज्ञापनों के प्रदर्शन को आसानी से एकीकृत और ट्रैक करें।",
  "google.analytics": "Google विश्लेषिकी",
  "google.analytics.desc":
    "अपने ग्राहक अधिग्रहण चैनल, जनसांख्यिकी, राजस्व और अन्य समृद्ध अंतर्दृष्टि को ट्रैक करने के लिए अपने Google Analytics को अपने ई-स्टोर के साथ आसानी से एकीकृत करें।",
  "customer.app": "ग्राहक ऐप",
  "customer.app.desc":
    "आपके स्टोर के लिए एक निःशुल्क अनुकूलित और ब्रांडेड आईओएस और एंड्रॉइड ग्राहक ऐप। अपने ब्रांड को दर्शाने के लिए अपना ऐप नाम, लॉन्च आइकन और स्प्लैश स्क्रीन सेट करें।",
  "delivery.app": "डिलिवरी ऐप",
  "delivery.app.desc":
    "आपके डिलीवरी स्टाफ के लिए ऑर्डर लेने, प्राथमिकता देने और डिलीवर करने के लिए एक मुफ्त एंड्रॉइड ऐप।",
  "picker.app": "पिकर ऐप",
  "picker.app.desc":
    "आपके कर्मचारियों के लिए ऑर्डर लेने, पैक करने और सत्यापित करने और लेबल प्रिंट करने के लिए एक निःशुल्क एंड्रॉइड ऐप।",
  "admin.app": "व्यवस्थापक ऐप",
  "admin.app.desc":
    "आपके मोबाइल पर अपने ऑनलाइन स्टोर का प्रबंधन करने के लिए एक निःशुल्क आईओएस और एंड्रॉइड ऐप। अपनी बिक्री को ट्रैक करें, ऑर्डर की समीक्षा करें, अपने उत्पादों को संशोधित करें, मार्केटिंग अभियान चलाएं और चलते-फिरते अपनी स्टोर सेटिंग बदलें।",
  "custom.password.format": "ग्राहक पासवर्ड प्रारूप सेटअप",
  "custom.password.format.desc":
    "कस्टम नियम सेट करें जो आपके ग्राहकों को पासवर्ड बनाते समय पालन करना चाहिए जिससे सुरक्षा में सुधार हो।",
  "data.security": "डेटा सुरक्षा",
  "data.security.desc": "आपका डेटा केवल आपके लिए उपलब्ध है।",
  "data.and.security": "डेटा और सुरक्षा",
  apps: "ऐप्स",
  channels: "चैनल",
  "customer.management": "ग्राहक प्रबंधन",
  delivery: "वितरण",
  marketing: "विपणन",
  cataloguing: "कैटलॉगिंग",
  "testimonials.ambros":
    "I was completely new to e-commerce and scared about the last-mile delivery operations. I couldn’t have asked for a better or easier solution than the Zopping e-commerce platform. The entire process from order placement on web/mobiles apps through last-mile delivery is easy and extremely efficient. A fantastic experience overall! Amazing solution for retailers at an affordable price from the Zopping team!",
  "testimonials.medinmin":
    "मैं वेब और ऐप डेवलपमेंट की तलाश करने वाले सभी लोगों को ज़ोपिंग ई-कॉम प्लेटफॉर्म की सलाह देता हूं। उनके पास प्लग-एंड-प्ले कार्यात्मकताएं हैं जो आपकी आवश्यकताओं को तुरंत पूरा करती हैं। उन्होंने न केवल मेरी उम्मीदों को पूरा किया बल्कि उनसे भी आगे निकल गए। वेब और मोबाइल ऐप बहुत आकर्षक हैं और उपयोगकर्ताओं के साथ शानदार जुड़ाव भी प्रदान करते हैं। मैं वास्तव में उनकी सेवा, सॉफ्टवेयर और व्यावसायिकता से प्रभावित हूं।",
  "testimonials.blossom":
    "ज़ोपिंग ने हमें सही मार्गदर्शन प्रदान किया जब हमें अपने पुस्तकों के व्यवसाय को ऑनलाइन करने के लिए एक आवेदन की आवश्यकता थी। इससे पहले कि मैं निर्णय लेता, हमने उनकी बिक्री टीम के साथ चर्चा की और इसने हमें निर्देशित किया कि हम अपने विचार को ऑनलाइन बुक स्टोर में कैसे परिवर्तित करें। बाद में, ऑनबोर्डिंग टीम पूरी सेट-अप प्रक्रिया के दौरान हमारे संपर्क में थी और यह सुनिश्चित करती थी कि वे हमारी आवश्यकताओं के अनुसार वेबसाइट को अनुकूलित करें। धन्यवाद एक टन, ज़ोपिंग टीम!",
  "testimonials.name.ambros": "रोहित कुमार",
  "testimonials.designation.ambros": "निदेशक",
  "testimonials.company.ambros": "एम्ब्रोस रिटेल",
  "testimonials.name.medinmin": "इरफान बी",
  "testimonials.designation.medinmin": "संचालन प्रबंधक",
  "testimonials.company.medinmin": "मेडिनमिनट्स",
  "testimonials.name.blossom": "माईगौड़ा",
  "testimonials.designation.blossom": "प्रबंध निदेशक",
  "testimonials.company.blossom": "ब्लॉसम बुक हाउस",
  Hyperlocal: "हाइपरलोकल",
  "Hyperlocal.descroption":
    "ज़ोपिंग आपके पड़ोस या इलाके में बेचने और डिजिटल मार्केटप्लेस में सफल होने के लिए एकदम सही ईकॉमर्स प्लेटफॉर्म प्रदान करता है। यही कारण है कि ज़ोपिंग बाकियों से अलग है|",
  "Create.Your.Online.Store": "अपना ऑनलाइन स्टोर बनाएं",
  "Best.suited.for.selling": "बेचने के लिए सबसे उपयुक्त",
  Grocery: "किराना",
  "Food.and.Beverage": "खाद्य और पेय पदार्थ",
  Medicine: "दवा",
  Bakery: "बेकरी",
  Diary: "डायरी",
  Fish: "मछली",
  "Embrace.Convenience": "सुविधा को गले लगाओ",
  "Embrace.Convenience.discription1":
    "ज़ोपिंग आपके ग्राहकों की प्राथमिकताओं को पूरा करते हुए स्टोर पिकअप और डिलीवरी विकल्प प्रदान करता है।",
  "Embrace.Convenience.discription2":
    "अपने कैटलॉग, इन्वेंट्री, ऑर्डर और ग्राहक डेटा को ज़ोपिंग के ओमनीचैनल पॉइंट ऑफ़ सेल (पीओएस) सिस्टम के साथ सिंक करें।",
  "Flexible.Order.Management": "लचीला आदेश प्रबंधन",
  "Flexible.Order.Management.description1":
    "अपने सेवा क्षेत्रों को पिनकोड/क्षेत्र/अपार्टमेंट द्वारा परिभाषित करें और कुशलतापूर्वक अपने स्थानीय ग्राहक आधार की सेवा करें..ज़ोपिंग आपके ग्राहकों की प्राथमिकताओं को पूरा करते हुए स्टोर पिकअप और डिलीवरी विकल्प प्रदान करता है। ",
  "Flexible.Order.Management.description2":
    "ज़ोपिंग कई शाखाओं या स्टोर वाले व्यवसायों का समर्थन करता है, संचालन को सुव्यवस्थित करता है और व्यापक ग्राहक आधार तक पहुंचता है।",
  "Flexible.Order.Management.description3":
    "विशिष्ट पिकअप या डिलीवरी स्लॉट को अधिभार या छूट के साथ प्रोत्साहित करें।",
  "Flexible.Order.Management.description4":
    " आदेशों को कुशलतापूर्वक प्रबंधित करने और चरम अवधि के दौरान अत्यधिक मांगों को रोकने के लिए सीमाएँ निर्धारित करें।",
  "Simplified.Delivery.Management": "सरलीकृत वितरण प्रबंधन",
  "Simplified.Delivery.Management.description1":
    " ज़ोपिंग डिलीवरी ऐप त्वरित और विश्वसनीय सेवा सुनिश्चित करते हुए ऑर्डर हैंडलिंग में क्रांतिकारी बदलाव लाता है।",
  "Simplified.Delivery.Management.description2":
    "यात्रा की दूरी के आधार पर अलग-अलग डिलीवरी शुल्क लागत को कवर करते हुए उचित मूल्य निर्धारण सुनिश्चित करते हैं।",
  "Delight.your.customers": "अपने ग्राहकों को प्रसन्न करें",
  "Delight.your.customers.description1":
    " अपनी वेबसाइट और मोबाइल ऐप पर एक सहज और सुसंगत खरीदारी अनुभव प्रदान करें.",
  "Delight.your.customers.description2":
    "कहीं से भी, कभी भी ऑर्डर देने की सुविधा प्रदान करें।",
  "Zopping.Advantage": "ज़ोपिंग लाभ",
  "Zopping.Advantage.description1":
    "प्रतिस्पर्धा में आगे रहने के लिए नियमित अपडेट और चल रहे सुधारों का लाभ उठाएं।",
  "Zopping.Advantage.description2":
    "अपने कस्टम-ब्रांडेड देशी iOS और Android ऐप को एक दिन से भी कम समय में चालू करें और एक ही बार में आसानी से सामग्री प्रबंधित करें।",
  "Zopping.Advantage.description3":
    "रखें और यह स्वचालित रूप से आपकी वेबसाइट और मोबाइल ऐप पर अपडेट हो जाता है।",
  "Engage.your.customer.with.Push":
    "पुश सूचनाओं के साथ अपने ग्राहक को संलग्न करें",
  "Engage.your.customer.with.Push.description1":
    "वैयक्तिकृत संदेशों, विशेष प्रस्तावों और नए आगमन की सूचनाओं के साथ वास्तविक समय में बिक्री बढ़ाएं और ग्राहकों को शामिल करें।",
};

export default messages;
