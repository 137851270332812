const messages = {
  "header.home": "வீடு",
  "header.products": "தயாரிப்புகள்",
  "header.technology": "தொழில்நுட்பம்",
  "header.about.us": "எங்களை பற்றி",
  "header.careers": "தொழில்",
  "header.contact.us": "எங்களை தொடர்பு கொள்ள",
  "header.language": "மொழி",
  english: "ஆங்கிலம்",
  hindi: "ஹிந்தி",
  signin: "உள்நுழையவும்",
  "signing.in": "உள்நுழைகிறது",
  signup: "பதிவு செய்யவும்",
  home: "முகப்பு",
  features: "அம்சங்கள்",
  pricing: "விலை நிர்ணயம்",
  contact: "எங்களை தொடர்பு கொள்ள",
  solutions: "தீர்வுகள்",
  "contact.us": "எங்களை தொடர்பு கொள்ள",
  "products.smartstore.eazy": "ஸ்மார்ட் ஸ்டோர் ஈஸி",
  "products.smartstore.enterprise": "ஸ்மார்ட் ஸ்டோர் எண்டர்பிரைஸ்",
  "products.smartproduct.locator": "ஸ்மார்ட் தயாரிப்பு லொக்கேட்டர்",
  "products.smartpayment.gateway": "ஸ்மார்ட் பேமென்ட் கேட்வே",
  "products.smartpoint.sale": "விற்பனையின் ஸ்மார்ட் புள்ளி",
  "products.grocery.express": "மளிகை எக்ஸ்பிரஸ்",
  "smartstore.eazy.heading": "முக்கிய அம்சங்கள்",
  "smartstore.eazy.selling.online":
    "நீங்கள் ஆன்லைனில் விற்கத் தொடங்க வேண்டிய அனைத்தும்",
  "smartstore.eazy.unique.store": "உங்கள் தனிப்பட்ட ஆன்லைன் ஸ்டோரைப் பெறுங்கள்",
  "smartstore.eazy.feature.standout":
    "உங்களின் தனித்துவமான இணையதளத்தை உருவாக்குவதன் மூலம் கூட்டத்தில் தனித்து நிற்கவும்",
  "smartstore.eazy.feature.domain":
    "உங்கள் சொந்த டொமைன் பெயரைப் பயன்படுத்தி உங்கள் பிராண்ட் மதிப்பை அதிகரிக்கவும்",
  "smartstore.eazy.feature.colors":
    "உங்கள் பிராண்டை பிரதிபலிக்கும் வண்ணங்களையும் தீம்களையும் தேர்வு செய்யவும்",
  "smartstore.eazy.feature.banners":
    "கவர்ச்சிகரமான பேனர்களைப் பதிவேற்றுவதன் மூலம் உங்கள் இணையதளத்தை மேம்படுத்தவும்",
  "smartstore.eazy.setup.shop": "உங்கள் கடையை எளிதாக அமைக்கவும்",
  "smartstore.eazy.create.own":
    "உங்கள் சொந்த கடையை உருவாக்கவும் - தொழில்நுட்ப திறன்கள் தேவையில்லை",
  "smartstore.eazy.feature.setup.online":
    "உங்கள் ஆன்லைன் கடையை எளிதாக அமைக்க, எங்கள் படிப்படியான வழிகாட்டியைப் பயன்படுத்தவும்",
  "smartstore.eazy.feature.text.boxes":
    "எளிய உரைப் பெட்டிகள், கீழ்தோன்றும் மற்றும் இழுத்து விடுதல் மெனுக்களைப் பயன்படுத்தி அனைத்து மாற்றங்களையும் செய்யவும்",
  "smartstore.eazy.feature.catalogue":
    "உங்கள் பட்டியலை உருவாக்கவும், விலைகளை அமைக்கவும், ஷிப்பிங் விதிகளை அமைத்து ஆன்லைனில் விற்பனை செய்யவும்",
  "smartstore.eazy.feature.mobile": "உடனடியாக மொபைலுக்குச் செல்லவும்",
  "smartstore.eazy.additional.sales":
    "மொபைல் சாதனங்களில் விற்பனை செய்வதன் மூலம் கூடுதல் விற்பனையைப் பெறுங்கள்",
  "smartstore.eazy.selling.mobile":
    "மொபைல் சாதனங்களில் உங்கள் இணையதளம் இன்னும் பிரமிக்க வைக்கும் என்பதால் உடனடியாக மொபைலில் விற்கத் தொடங்குங்கள்",
  "smartstore.eazy.shopping.convenience":
    "ஷாப்பிங் கார்ட் சாதனங்கள் முழுவதும் பொதுவானதாக இருப்பதால் ஷாப்பிங் வசதியை இயக்கவும்",
  "smartstore.eazy.international.app":
    "உங்கள் சொந்த சர்வதேச தரமான Android மற்றும் iOS பயன்பாட்டைப் பெறுங்கள்",
  "smartstore.eazy.manage.go": "பயணத்தின்போது அதை நிர்வகிக்கவும்",
  "smartstore.eazy.easy.access":
    "உங்கள் Android மொபைலில் உங்கள் கடை நிர்வாகியை எளிதாக அணுகலாம்",
  "smartstore.eazy.update.prices":
    "விலைகள், பங்குகள் மற்றும் படங்களைப் புதுப்பிக்கவும்",
  "smartstore.eazy.important.alerts":
    "ஆர்டர் செய்தல், பணம் செலுத்துதல் மற்றும் ரத்து செய்தல் போன்ற முக்கியமான நிகழ்வுகளில் விழிப்பூட்டல்களைப் பெறுங்கள்",
  "smartstore.eazy.customer.reply":
    "வாடிக்கையாளர் மின்னஞ்சல்கள் மற்றும் அரட்டைகளுக்கு எந்த தாமதமும் இல்லாமல் பதிலளிக்கவும்",
  "smartstore.eazy.customer.reachout": "உங்கள் வாடிக்கையாளர்களை அணுகவும்",
  "smartstore.eazy.powerful.seo":
    "எங்கள் சக்திவாய்ந்த எஸ்சிஓ மூலம் புதிய வாடிக்கையாளர்களைப் பெறுங்கள்",
  "smartstore.eazy.offers.coupons":
    "உங்கள் வாடிக்கையாளர்களின் ஒரு பிரிவை இலக்காகக் கொண்டு சலுகைகள் மற்றும் கூப்பன்களை உருவாக்கவும்",
  "smartstore.eazy.marketing.campaigns":
    "குறிப்பிட்ட வாடிக்கையாளர்களுக்கு எஸ்எம்எஸ், மின்னஞ்சல்கள் மற்றும் ஆப்-அறிவிப்புகள் வழியாக இலக்கு சந்தைப்படுத்தல் பிரச்சாரங்களை இயக்கவும்",
  "smartstore.eazy.manage.customers": "உங்கள் வாடிக்கையாளர்களை நிர்வகிக்கவும்",
  "smartstore.eazy.build.loyalty":
    "விசுவாசத்தை உருவாக்க எங்கள் சக்திவாய்ந்த CRM ஐப் பயன்படுத்தவும்",
  "smartstore.eazy.view.customers":
    "உங்கள் வாடிக்கையாளர்களின் விரிவான கொள்முதல் வரலாற்றுடன் ஒரே இடத்தில் பார்க்கவும்",
  "smartstore.eazy.upload.details":
    "உங்கள் ஆஃப்லைன் வாடிக்கையாளர்களுக்கு ஒரே அளவிலான சேவையை வழங்க அவர்களின் விவரங்களைப் பதிவேற்றவும்",
  "smartstore.eazy.add.edit.details":
    "உங்கள் வாடிக்கையாளர்களின் முகவரி, மின்னஞ்சல் மற்றும் தொலைபேசி எண் போன்ற விவரங்களைச் சேர்க்கவும் அல்லது திருத்தவும்",
  message404:
    "நீங்கள் இணையத்தை உடைக்கவில்லை, ஆனால் நீங்கள் தேடுவதை எங்களால் கண்டுபிடிக்க முடியவில்லை.",
  "go.home": "முகப்பு பக்கத்திற்கு செல்லவும்",
  "zopping.pricing": "விலை திட்டம்",
  "pay.for.use":
    "எங்கள் மூலம் ஆன்லைனில் நீங்கள் விற்கும் பொருட்களுக்கு மட்டும் பணம் செலுத்துங்கள்",
  "currency.selector": "நாணயத் தேர்வாளர்",
  "everything.free": "டெஸ்க்டாப் & மொபைல் வெப் உட்பட அனைத்தும் இலவசம்",
  "android.app.customers": "வாடிக்கையாளர்களுக்கான Android பயன்பாடு",
  "ios.app.customers": "வாடிக்கையாளர்களுக்கான iOS பயன்பாடு",
  "picker.app.employees": "பணியாளருக்கான பிக்கர் ஆப்",
  "logistics.app.employees": "பணியாளருக்கான லாஜிஸ்டிக் ஆப்",
  "buy.now": "இப்போது வாங்கவும்",
  "contact.us.support": "ஆதரவிற்கு எங்களைத் தொடர்பு கொள்ளவும்",
  "customization.integration":
    "தனிப்பயனாக்கம் மற்றும் ஒருங்கிணைப்புக்கு எங்களை அணுகவும்",
  "cant.find.answer": "பதில் கிடைக்கவில்லையா?",
  "pricing.faq": "விலை - அடிக்கடி கேட்கப்படும் கேள்விகள்",
  "view.more": "மேலும் பார்க்க",
  "view.less": "குறைவாக பார்க்கவும்",
  "more.than": "விட அதிகம்",
  "month.sales": "மாத விற்பனை",
  "upto.text": "அது வரை",
  heroHeadingText: "உங்கள் வணிகத்தை ஆன்லைனில் வளர்க்கவும்",
  heroParagraphText: "சில்லறை விற்பனையாளர்களுக்கான இறுதி ஈ-காமர்ஸ் தளம்.",
  heroFormButtonText: "ஆன்லைனில் இலவசமாக விற்பனை செய்யத் தொடங்குங்கள்",
  heroFormInputPlaceholder: "மின்னஞ்சல் முகவரி அல்லது தொலைபேசி எண்",
  "email.two.days": "இரண்டு வேலை நாட்களுக்குள் மின்னஞ்சல் அனுப்பவும்",
  "email.instant.chat": "கூடிய விரைவில் மின்னஞ்சல் & உடனடி அரட்டை",
  "email.and.telephonic":
    "மின்னஞ்சல், உடனடி அரட்டை மற்றும் தொலைபேசி மூலம் கூடிய விரைவில்",
  "email.account.manager":
    "மின்னஞ்சல், உடனடி அரட்டை, தொலைபேசி & கணக்கு மேலாளர் ஒதுக்கப்படும்",
  testimonialsHeading:
    "நாங்கள் செய்வதை எங்கள் வாடிக்கையாளர்கள் விரும்புகிறார்கள்",
  testimonialsSubHeading:
    "எங்கள் சேவைகள் மூலம் எங்கள் வாடிக்கையாளர்கள் எங்களுக்கு புன்னகையை அனுப்புகிறார்கள், நாங்கள் அவர்களை விரும்புகிறோம்",
  "select.language.heading": "மொழியை தேர்ந்தெடுங்கள்",
  "my.account": "என் கணக்கு",
  "logout.text": "வெளியேறு",
  "privacy.policy": "தனியுரிமைக் கொள்கை",
  "pricing.comparison.heading": "எங்களை ஏன் தேர்வு செய்ய வேண்டும்?",
  "pricing.comparison.subheading":
    "எங்கள் அம்சத்தை மற்றவர்களுடன் ஒப்பிடுவதைப் பார்க்கவும்",
  "compare.with.others": "மற்றவர்களுடன் ஒப்பிடு",
  customDomain: "தனிப்பயன் டொமைன்",
  onlineStore: "இணையதள அங்காடி",
  catalogue: "அட்டவணை",
  payments: "கொடுப்பனவுகள்",
  marketingOffers: "சந்தைப்படுத்தல் & சலுகைகள்",
  orderManagement: "ஒழுங்கு மேலாண்மை",
  ratingsReviews: "மதிப்பீடுகள் & மதிப்புரைகள்",
  support: "மின்னஞ்சல்கள், அரட்டைகள் மற்றும் தொலைபேசி அழைப்புகள் மூலம் ஆதரவு",
  salesChannels: "விற்பனை சேனல்கள்",
  orderCreation: "கைமுறையாக ஆர்டர் உருவாக்கம்",
  discounts: "தயாரிப்பு தள்ளுபடி & கூப்பன் தள்ளுபடிகள்",
  ssl: "SSL சான்றிதழ்",
  cart: "கைவிடப்பட்ட வண்டி",
  giftCards: "கிஃப்ட் கார்டுகள்",
  reportsAnalytics: "அறிக்கைகள் & பகுப்பாய்வு",
  reportAnalytics: "அறிக்கைகள் & பகுப்பாய்வு",
  yes: "ஆம்",
  no: "இல்லை",
  limited: "வரையறுக்கப்பட்டவை",
  unlimited: "வரம்பற்ற",
  hostingSsl: "ஹோஸ்டிங் மற்றும் SSL சான்றிதழ்",
  themes: "தீம்கள்",
  customerSignup: "வாடிக்கையாளர் பதிவு பக்கம்",
  multiStore: "பல கடைகள்",
  multiSales: "பல விற்பனை சேனல்",
  products: "தயாரிப்புகள்",
  collectionTags: "தொகுப்புகள் & குறிச்சொற்கள்",
  weightBasedPrices: "எடை அடிப்படையிலான விலைகள்",
  productSubstitution: "தயாரிப்பு மாற்றீடுகள்",
  stockManagement: "ஸ்டாக் மேனேஜ்மென்ட்",
  rackManagement: "ரேக் மேலாண்மை",
  gatewayInteg: "நுழைவாயில் ஒருங்கிணைப்பு",
  walletCashback: "பணப்பைகள் மற்றும் கேஷ்பேக்குகள்",
  cashOnDelivery: "டெலிவரி போது பணம்",
  voucherGifts: "வவுச்சர்கள்/ பரிசு அட்டைகள்",
  campaigns: "பிரச்சாரங்கள் (மின்னஞ்சல்/ SMS)",
  notifications: "பயன்பாட்டின் அறிவிப்பு",
  customSeo: "தனிப்பயன் எஸ்சிஓ",
  coupons: "கூப்பன்கள்",
  offers: "தயாரிப்புகள்/ சேகரிப்புகள்/ குறிச்சொற்கள் மீதான சலுகைகள்",
  manualOrder: "கைமுறை ஆர்டர் உருவாக்கம்",
  pickerApp: "பிக்கர் மொபைல் ஆப்",
  intelligentPicking: "அறிவார்ந்த தேர்வு வரிசை",
  deliveryManagement: "விநியோக மேலாண்மை",
  surcharge: "கூடுதல் கட்டணம்",
  slotCharges: "ஸ்லாட் அடிப்படையிலான கட்டணங்கள்",
  vehiclePlanning: "வாகனத் திட்டமிடல்",
  routePlanning: "பாதை திட்டமிடல்",
  deliveryPersonTracking: "டெலிவரி நபர் கண்காணிப்பு",
  thirdPartyLogistics: "மூன்றாம் தரப்பு லாஜிஸ்டிக்ஸ் ஒருங்கிணைப்பு",
  staffManagement: "பணியாளர் மேலாண்மை",
  multiUsers: "பல பயனர்கள்",
  employeeMobileApp: "பணியாளர் மொபைல் ஆப்",
  attendanceSummary: "வருகை சுருக்கம்",
  roleBasedPermissions: "பங்கு அடிப்படையிலான அனுமதிகள்",
  reports: "அறிக்கைகள்",
  analytics: "பகுப்பாய்வு",
  monthlyFixedCharges: "மாதாந்திர நிலையான கட்டணங்கள்",
  commissions: "கமிஷன்கள்",
  freePlugins: "இலவச செருகுநிரல்கள்",
  customerMobileApp: "வாடிக்கையாளர் மொபைல் பயன்பாடு (iOS மற்றும் Android)",
  "contact.help.banner.question": "உங்களுக்கு எவ்வாறு உதவலாம்?",
  "contact.help.banner.tagline":
    "உங்கள் தொடர்பு விவரங்களை கீழே விடுங்கள், எங்களின் இ-காமர்ஸ் நிபுணர் விரைவில் உங்களைத் தொடர்புகொள்வார்",
  "contact.placeholder.fullname": "முழு பெயர்",
  "contact.placeholder.phone": "தொலைபேசி எண்",
  "contact.placeholder.email": "மின்னஞ்சல் முகவரி",
  "contact.placeholder.message": "செய்தி",
  "contact.reachout": "விரைவான பதிலுக்கு எங்களை அணுகவும்",
  "contact.reachout.question":
    "ஒரு கேள்வி இருக்கிறதா? எங்களுடன் தொடர்பில் இரு",
  "contact.chat": "எங்களுடன் அரட்டையடிக்கவும்",
  "map.message": "நாங்கள் இங்கேயே இருக்கிறோம்",
  "map.viewmap": "வரைபடத்தில் பார்க்கவும்",
  "zopping.welcome": "Zoppingக்கு வரவேற்கிறோம்",
  "zopping.loginForm.subheading": "உங்கள் விவரங்களை கீழே உள்ளிடவும்",
  "loginForm.username.placeholder":
    "உங்கள் மின்னஞ்சல் அல்லது தொலைபேசி எண்ணை உள்ளிடவும்",
  "feature.one.description":
    "இலவசமாகத் தொடங்குங்கள், நீங்கள் பயன்படுத்துவதற்கு மட்டும் பணம் செலுத்துங்கள்",
  "feature.two.description":
    "அனைத்து அம்சங்களும் பயன்பாடுகளும் கூடுதல் கட்டணமின்றி முழுமையாக திறக்கப்படும்",
  "feature.three.description":
    "உங்கள் மாதாந்திர ஆன்லைன் விற்பனையைத் தொடர்ந்து மாறுபடும் மாதாந்திர கட்டணம்",
  "highlight.heading": "என்ன கிடைத்தது",
  "monthly.store.sales": "மாதாந்திர ஆன்லைன் ஸ்டோர் விற்பனை",
  "charges.per.month": "மாதத்திற்கான கட்டணம்",
  "setup.store.free": "உங்கள் கடையை இலவசமாக அமைக்கவும்!",
  password: "கடவுச்சொல்",
  "loginForm.password.placeholder": "உங்கள் கடவுச்சொல்லை உள்ளிடவும்",
  "remember.me": "என்னை நினைவில் வையுங்கள்",
  "dont.have.account": "கணக்கு இல்லை",
  "create.account": "ஒரு கணக்கை உருவாக்க",
  name: "பெயர்",
  "enter.name": "உங்கள் பெயரை உள்ளிடவும்",
  "create.your.account": "உங்கள் கணக்கை உருவாக்கவும்",
  "creating.account": "உங்கள் கணக்கை உருவாக்குதல்",
  "already.have.account": "ஏற்கனவே ஒரு கணக்கு உள்ளதா",
  "forgot.password": "கடவுச்சொல்லை மறந்துவிட்டீர்களா?",
  "submit.text": "சமர்ப்பிக்கவும்",
  submitting: "சமர்ப்பிக்கிறது",
  "resetPassword.successMessage":
    "உங்கள் கடவுச்சொல் வெற்றிகரமாக மீட்டமைக்கப்பட்டது",
  "resetPassword.loginLinkText": "உள்நுழைவுக்குச் செல்லவும்",
  "can.try.zopping": "நான் பணம் செலுத்தாமல் Zopping முயற்சிக்கலாமா?",
  "sign.up.free.trial":
    "ஆம். பதிவு செய்து, பிளாட்ஃபார்மில் 15 நாட்கள் இலவச சோதனையைப் பெறுங்கள்.",
  "enter.credit.card":
    "சோதனையை மேற்கொள்ள, எனது கிரெடிட் கார்டு எண் அல்லது வேறு ஏதேனும் கட்டண விவரங்களை உள்ளிட வேண்டுமா?",
  "no.requirement.payment.details":
    "இல்லை. சோதனையை எடுக்க எந்த கட்டண விவரங்களையும் உள்ளிட வேண்டிய அவசியமில்லை. நீங்கள் பதிவுசெய்து தளத்தைப் பயன்படுத்தத் தொடங்கலாம்",
  "trial.over": "என் விசாரணை முடிந்ததும் என்ன நடக்கும்?",
  "continue.using.platform":
    "நீங்கள் பிளாட்ஃபார்மைத் தொடர்ந்து பயன்படுத்த விரும்பினால், குறைந்தபட்சம் ரூ.2,000-க்கு உங்கள் உள்ளமைந்த வாலட்டை ரீசார்ஜ் செய்து பணம் செலுத்த வேண்டும். சோதனை காலாவதியான 15 நாட்களுக்குள் உங்கள் வாலட்டை ரீசார்ஜ் செய்யாவிட்டால், உங்கள் கணக்கு நிரந்தரமாக நீக்கப்படும்.",
  "how.do.i.pay": "நான் எப்படி செலுத்துவது?",
  "account.wallet": "கணக்கு வாலட்டில்",
  "charge.admin.panel":
    "பணத்தைச் சேமித்து ரீசார்ஜ் செய்யலாம் . எங்கள் கட்டண நுழைவாயில் அனைத்து நிலையான கிரெடிட் கார்டுகள், டெபிட் கார்டுகள், வங்கி பரிமாற்றங்கள், UPI மற்றும் இ-வாலட்களை ஏற்றுக்கொள்கிறது.",
  "features.during.trial": "சோதனையின் போது நான் என்ன அம்சங்களைப் பெறுவேன்?",
  "features.page": "அம்சங்கள் பக்கம்",
  "addon.features": "கூடுதல் அம்சங்கள் என்ன?",
  "addon.features.answer":
    "ஆட்-ஆன் அம்சங்கள் என்பது தனிப்பட்ட வணிகங்களுக்கான செயல்பாடுகள் மற்றும் அனைவருக்கும் தேவைப்படாமல் இருக்கலாம். இந்த செயல்பாடுகளை நீங்கள் பார்க்கலாம் மற்றும் உங்கள் தேவைக்கேற்ப இயக்கலாம்.",
  "addon.features.charge": "கூடுதல் அம்சங்கள் கட்டணம் விதிக்கப்படுமா?",
  "addon.features.charge.answer":
    "பெரும்பாலான கூடுதல் அம்சங்கள் இலவசம் மற்றும் அவற்றைப் பயன்படுத்தத் தொடங்க நீங்கள் அவற்றை இயக்க வேண்டும். கட்டண ஆட்-ஆன் அம்சங்களுக்கு, தனிப்பட்ட அம்சத்தின் விலை நிர்ணயத்தின்படி கட்டணம் உங்கள் கணக்கு வாலட்டில் இருந்து கழிக்கப்படும்.",
  "turnoff.addon.features":
    "ஆட்-ஆன் அம்சங்களை எப்போது வேண்டுமானாலும் முடக்க முடியுமா?",
  "turnoff.addon.features.answer":
    "ஆம். ஆனால் இந்த அம்சங்களை அணைக்கும்போது நீங்கள் மிகவும் கவனமாக இருக்க வேண்டும், ஏனெனில் அவற்றின் தொடர்புடைய தரவை நிரந்தரமாக இழக்க நேரிடும்.",
  "remind.balance.low":
    "எனது கணக்கு வாலட் இருப்பு குறைவாக இருந்தால் எனக்கு நினைவூட்டுவீர்களா?",
  "remind.balance.low.answer":
    "ஆம். உங்கள் கணக்கு இருப்பு ரூ.2000க்கு குறைவாக இருந்தால், நாங்கள் உங்களுக்கு எஸ்எம்எஸ் மற்றும் மின்னஞ்சல் மூலம் பல நினைவூட்டல்களை அனுப்புவோம்.",
  "negative.balance.wallet":
    "எனது கணக்கு வாலட் இருப்பு மாதாந்திர கட்டணத்தைச் செலுத்த போதுமானதாக இல்லாவிட்டால் என்ன நடக்கும்?",
  "negative.balance.reminder":
    "மின்னஞ்சல், புஷ் அறிவிப்புகள் மற்றும் எஸ்எம்எஸ் போன்ற பல சேனல்கள் மூலம் உங்கள் வாலட்டில் போதுமான பணம் இல்லை என எச்சரித்து, உங்கள் பணப்பையை ரீசார்ஜ் செய்யும்படி கேட்டுக்கொள்கிறோம்.",
  "negative.balance.case.one":
    "வழக்கு 1: தொடர்ந்து 15 நாட்களுக்கு மேல் வாலட் பேலன்ஸ் எதிர்மறையாக இருந்தால், உங்கள் கணக்கு தானாக முடக்கப்படும் அதாவது உங்கள் இணையதளம் செயலிழக்கும், இருப்பினும் உங்கள் கணக்கு நிர்வாகியை அணுக முடியும். உங்கள் பணப்பையை ரீசார்ஜ் செய்து பேலன்ஸ் பாசிட்டிவ் ஆனவுடன், உங்கள் இணையதளம் மீண்டும் நேரலையில் இருக்கும்.",
  "negative.balance.case.two":
    "வழக்கு 2: தொடர்ந்து 60 நாட்களுக்கு மேல் வாலட் பேலன்ஸ் எதிர்மறையாக இருந்தால் கணக்கு தானாக நீக்கப்படும் அதாவது உங்கள் இணையதளம் மற்றும் நிர்வாகி கணக்கு இரண்டும் நீக்கப்படும். உங்கள் தரவு அனைத்தும் நிரந்தரமாக இழக்கப்படும்.",
  "stop.using.platform":
    "உங்கள் பிளாட்ஃபார்மைப் பயன்படுத்துவதை நான் நிறுத்த விரும்பினால், எனது வாலட்-பணத்தை நான் திரும்பப் பெற வேண்டுமா?",
  "stop.using.platform.answer":
    "ஆம். எந்த நேரத்திலும் நீங்கள் எங்கள் தளத்தைப் பயன்படுத்துவதை நிறுத்த வேண்டும் என்று எங்களுடன் தொடர்பு கொள்ளலாம். இயங்கும் மாதத்தின் வாடகைக் கட்டணம் மற்றும் இன்றுவரை நிலுவைத் தொகையைக் கழித்த பிறகு, உங்கள் வாலட் தொகையைத் திருப்பித் தருவோம்.",
  install: "நிறுவு",
  installing: "நிறுவுதல்",
  uninstall: "நிறுவல் நீக்கவும்",
  uninstalling: "நிறுவல் நீக்குகிறது",
  installed: "நிறுவப்பட்ட",
  "registered.user.text": "நீங்கள் ஏற்கனவே பதிவு செய்துள்ளீர்கள்",
  "zopping.features": "Zopping அம்சங்கள்",
  "feature.description":
    "Zopping செய்யக்கூடிய அனைத்து அற்புதமான விஷயங்களைப் பற்றியும் நன்றாகப் புரிந்து கொள்ளுங்கள்.",
  "login.or.text": "அல்லது",
  "login.with.google": "Google மூலம் உள்நுழைக",
  "signup.with.google": "Google உடன் பதிவு செய்யவும்",
  free: "இலவசம்",
  customizable: "தனிப்பயனாக்கக்கூடியது",
  standard: "தரநிலை",
  premium: "பிரீமியம்",
  "stay.in.touch": "தொடர்பில் இரு",
  "about.us": "எங்களை பற்றி",
  "company.text": "நிறுவனம்",
  "blog.text": "வலைப்பதிவு",
  "terms.conditions": "விதிமுறைகள் மற்றும் நிபந்தனைகள்",
  "hidden.costs": "மறைமுக செலவுகள் ஏதேனும் உள்ளதா?",
  "hidden.costs.answer":
    "இல்லை. எதுவும் இல்லை. அனைத்து அம்சங்கள், தீம்கள், நீட்டிப்புகள் மற்றும் பயன்பாடுகள் முற்றிலும் இலவசம். இருப்பினும், உங்கள் சொந்த டொமைன் பெயரை நீங்கள் தேர்வுசெய்தால், உங்கள் டொமைன் வழங்குநருக்கு நீங்கள் பணம் செலுத்த வேண்டியிருக்கும். உங்களுக்கும் சம்பந்தப்பட்ட தரப்பினருக்கும் இடையே ஒப்புக் கொள்ளப்பட்ட டெலிவரி கட்டணங்கள் மற்றும் கட்டண நுழைவாயில் கட்டணங்கள் எங்கள் விலை திட்டத்தில் சேர்க்கப்படவில்லை, எனவே நீங்கள் ஏற்க வேண்டும்.",
  "cost.online.store": "எனது ஆன்லைன் ஸ்டோரை அமைக்க எவ்வளவு செலவாகும்?",
  "cost.online.store.answer":
    "உங்கள் ஆன்லைன் ஸ்டோரை அமைப்பது முற்றிலும் இலவசம். நீங்கள் குறைந்தபட்சம் ரூ. ஐ அடையும் வரை நீங்கள் எங்களுக்கு எதுவும் செலுத்தத் தேவையில்லை. உங்கள் ஆன்லைன் ஸ்டோர் மூலம் மாதத்திற்கு 20000 மதிப்புள்ள ஆர்டர்கள். அங்கிருந்து, மேலே குறிப்பிட்டுள்ள விலைத் திட்டத்தின் அடிப்படையில் கட்டணங்கள் விதிக்கப்படுகின்றன. உங்கள் ஆன்லைன் ஸ்டோர் மூலம் உங்கள் மாதாந்திர விற்பனை ஒவ்வொரு மாதமும் மாறுபடும் என்பதால், உங்கள் மாதாந்திர கட்டணங்களும் மாறுபடும்.",
  "highlight.hosting": "ஹோஸ்டிங் (Zopping subdomain) மற்றும் SSL சான்றிதழ்",
  "highlight.themes": "முழுமையாக தனிப்பயனாக்கக்கூடிய கருப்பொருள்கள்",
  "highlight.product.listing": "வரம்பற்ற தயாரிப்பு பட்டியல்கள்",
  "highlight.unlimited.users": "வரம்பற்ற பயனர்கள்",
  "highlight.unlimited.operations": "வரம்பற்ற பல அங்காடி செயல்பாடுகள்",
  "highlight.apps":
    "நுகர்வோர் ஆப், பிக்கர் ஆப், டெலிவரி ஆப் மற்றும் அட்மின் ஆப்",
  "highlight.extensions": "அனைத்து செருகுநிரல்கள் / நீட்டிப்புகள்",
  "highlight.gateway.integrations": "கட்டண நுழைவாயில் ஒருங்கிணைப்புகள்",
  "highlight.delivery.partner": "டெலிவரி கூட்டாளர் ஒருங்கிணைப்புகள்",
  "highlight.social.media": "சமூக ஊடகங்கள் மற்றும் Google API ஒருங்கிணைப்புகள்",
  "highlight.customer.support": "24x7 வாடிக்கையாளர் ஆதரவு",
  "less.than": "விட குறைவாக",
  "email.error.msg": "சரியான மின்னஞ்சல் முகவரியை உள்ளிடவும். எ.கா-abc@xyz.com",
  "anyone.can.sell.anything":
    "யார் வேண்டுமானாலும் ஆன்லைனில் எதையும் விற்கலாம்!",
  "brands.retailers.resellers":
    "பிராண்டுகள் முதல் சில்லறை விற்பனையாளர்கள், மறுவிற்பனையாளர்கள் முதல் ஆன்லைன் முதல் தொழில்முனைவோர் வரை எவரும் ஒரு நிறுவனத்தை அமைத்து இயக்கலாம்",
  "matter.few.minutes": "வசதியைத் தழுவுங்கள்",
  "setup.free.online.store": "உங்கள் இலவச ஆன்லைன் ஸ்டோரை அமைக்கவும்",
  "schedule.demo": "ஒரு டெமோவைத் திட்டமிடுங்கள்",
  "zero.web.dev.skills": "Zero Web Development Skills தேவை",
  "payments.handle.deliveries":
    "பணம் செலுத்துதல், விநியோகங்களைக் கையாளுதல், உங்கள் பிராண்டை விளம்பரப்படுத்துதல் மற்றும் எங்கள் உள்ளுணர்வு மற்றும் தனிப்பயனாக்கக்கூடிய இடைமுகத்தைப் பயன்படுத்தி எளிதாக ஆன்லைனில் வளரலாம்.",
  "your.store.customers.rules":
    "உங்கள் கடை, உங்கள் வாடிக்கையாளர்கள், உங்கள் விளிம்புகள், உங்கள் விதிகள்",
  "experience.online.marketplaces":
    "Amazon, Flipkart, Udaan, AliExpress, eBay போன்ற ஆன்லைன் சந்தைகளில் விற்பனை செய்து, உங்கள் போட்டியாளர்களிடம் கடுமையாக வென்ற வாடிக்கையாளர்களை இழந்த அனுபவத்தால் விரக்தியடைந்துள்ளீர்களா? உங்களின் சொந்த ஆன்லைன் ஸ்டோர் மற்றும் மொபைல் ஆப் மூலம், உங்கள் பிராண்ட் மற்றும் வாடிக்கையாளர் அனுபவத்தை நீங்கள் கட்டுப்படுத்தலாம், உங்களின் கடின சம்பாதித்த மார்ஜின்கள் மற்றும் வாடிக்கையாளர் தரவை முழுவதுமாக உங்களுடன் வைத்திருக்கலாம்.",
  "explore.our.features": "எங்கள் அம்சங்களை ஆராயுங்கள்",
  "platform.specific.business":
    "உங்கள் வணிகத்தின் குறிப்பிட்ட தன்மைக்காக உருவாக்கப்பட்ட தளம்",
  "handle.thousands.transactions":
    "ஒரு நாளில் ஆயிரக்கணக்கான பரிவர்த்தனைகளை தடையின்றி கையாளுகிறது மற்றும் உங்கள் வணிகத்தை அதிகரிக்கவும்.",
  "explore.prices": "விலைகளை ஆராயுங்கள்",
  "start.online.store":
    "சில கிளிக்குகளில் உங்கள் ஆன்லைன் ஸ்டோரைத் தொடங்குங்கள்",
  "sell.groceries.medicines":
    "மளிகை பொருட்கள், மருந்துகள், தளபாடங்கள், புத்தகங்கள், மின்னணுவியல், அழகு சாதனப் பொருட்கள், நகைகள், உடைகள், கைவினைப் பொருட்கள், பேக்கரி பொருட்கள் அல்லது எதையும் ஆன்லைனில் விற்கவும்.",
  "store.information": "தகவலைச் பதிவு செய்",
  "enter.store.name.logo": "உங்கள் கடையின் பெயர் மற்றும் லோகோவை உள்ளிடவும்",
  "select.domain": "டொமைனைத் தேர்ந்தெடுக்கவும்",
  "choose.store.url": "உங்கள் ஸ்டோர் URL ஐ தேர்வு செய்யவும்",
  "select.industry": "தொழில்துறையைத் தேர்ந்தெடுக்கவும்",
  "pick.business.operate": "நீங்கள் செயல்படும் வணிகத்தைத் தேர்ந்தெடுக்கவும்",
  "select.theme": "தீம் தேர்ந்தெடுக்கவும்",
  "choose.theme.liking":
    "நீங்கள் விரும்பும் தீம் அல்லது வண்ணங்களைத் தேர்ந்தெடுக்கவும்",
  "download.now": "இப்போது பதிவிறக்கவும்",
  "unlock.ecommerce.power": "Zopping ஆப் மூலம் ஈ-காமர்ஸின் ஆற்றலைத் திறக்கவும்",
  "features.banner.one.description":
    "உங்கள் ஆன்லைன் ஸ்டோரை அமைப்பது மற்றும் இயக்குவது மிகவும் எளிதானது",
  "features.banner.two.description":
    "பல 'நிறுவக்கூடிய' நீட்டிப்புகள் மூலம் மேம்பட்ட தனிப்பயனாக்கங்கள் சாத்தியமாயின",
  "features.banner.three.description":
    "Zopping மொபைல் பயன்பாட்டின் மூலம் அனைத்து அம்சங்களையும் அணுகவும்",
  hosting: "ஹோஸ்டிங்",
  "hosting.desc":
    "பதிவு செய்யும் போது இலவச ஹோஸ்டிங்குடன் இலவச @zopping.com டொமைனைப் பெறுங்கள். நீங்கள் உடனடியாக அல்லது பிந்தைய தேதியில் உங்கள் சொந்த டொமைனுக்கு செல்லலாம்.",
  "ssl.desc":
    "உங்கள் வாடிக்கையாளர்களுக்கு பாதுகாப்பான மற்றும் பாதுகாப்பான ஷாப்பிங் அனுபவத்தை செயல்படுத்த உங்கள் டொமைனுக்கான இலவச 256-பிட் SSL சான்றிதழை நாங்கள் வழங்குகிறோம்.",
  "themes.desc":
    "உங்கள் வணிகத்தின் தன்மையுடன் பொருந்தக்கூடிய டெம்ப்ளேட்டுகளின் எங்கள் நூலகத்திலிருந்து தேர்வு செய்யவும். பக்க தளவமைப்புகள், மெனுக்கள் மற்றும் வண்ணங்களைத் தனிப்பயனாக்குங்கள், லோகோக்கள், ஃபேவிகான்கள், நிலையான பக்கங்கள் மற்றும் வலைப்பதிவுகளைச் சேர்த்து உங்கள் ஸ்டோர் உங்கள் பிராண்டைப் பிரதிபலிக்கும். உங்கள் வலைத்தளத்தை உடைக்காமல் தீம்களுக்கு இடையில் எளிதாக மாறவும்.",
  "search.box": "தேடல் பெட்டி",
  "search.box.desc":
    "எங்களின் அறிவார்ந்த மற்றும் தனிப்பயனாக்கப்பட்ட தேடல் செயல்பாட்டைப் பயன்படுத்தி உங்கள் ஸ்டோரிலிருந்து பொருட்களைத் தேட உங்கள் வாடிக்கையாளர்களுக்கு உதவுங்கள். ஒரு குறிப்பிட்ட தயாரிப்பு/ வகை/ பிராண்டை விளம்பரப்படுத்த, குறிப்பிட்ட தேடல் வார்த்தைகளுக்கான தேடல் முடிவுகளை தையல் செய்து சரி செய்யவும்.",
  "multi.language": "பல மொழி",
  "multi.language.desc":
    "உங்கள் eStore இல் நீங்கள் ஆதரிக்க விரும்பும் மொழிகளை அமைத்து, உங்கள் வாடிக்கையாளர் அவர்கள் விரும்பும் மொழியில் உங்கள் eStore இல் உலாவவும் ஷாப்பிங் செய்யவும் அனுமதிக்கவும்.",
  "product.listing": "தயாரிப்பு பட்டியல்",
  "product.listing.desc":
    "உங்கள் தயாரிப்புகளை வகைகளாகவும் பல அடுக்கு துணை வகைகளாகவும் பதிவேற்றி எளிதாக குழுவாக்கவும். உள்ளமைக்கப்பட்ட தனிப்பயன் புலங்களைப் பயன்படுத்தி படங்கள், விளக்கங்கள், குறிச்சொற்கள், பங்கு, விலைகள், தள்ளுபடிகள், வரி விகிதங்கள், செஸ் மற்றும் பலவற்றைச் சேர்க்கவும். தனித்தனியாக அல்லது மொத்தமாக உங்கள் தயாரிப்புகளை வசதியாக பதிவேற்றவும் அல்லது திருத்தவும்.",
  "sell.by.brand": "பிராண்ட் மூலம் விற்கவும்",
  "sell.by.brand.desc":
    "உங்கள் வாடிக்கையாளர்களுக்கு பிராண்ட் பெயரின் அடிப்படையில் தயாரிப்புகளைத் தேடவும் வடிகட்டவும் உதவ, பட்டியலில் உங்கள் தயாரிப்புகளுக்கு 'பிராண்ட்' புலத்தைச் சேர்க்கவும்.",
  "items.sold.by.weight": "எடைக்கு விற்கப்படும் பொருட்கள்",
  "items.sold.by-weight.desc":
    "பழங்கள், காய்கறிகள், தானியங்கள், இறைச்சி, உலோகங்கள் போன்ற தளர்வான பொருட்களையும், எடை வாரியாக விற்கும் பொருட்களையும் விற்கத் தொடங்குங்கள்.",
  "product.variants": "தயாரிப்பு மாறுபாடுகள்",
  "product.variants.desc":
    "நிறம், அளவு, எடை போன்றவற்றின் அடிப்படையில் மாறுபாடுகளைக் கொண்ட தயாரிப்புகளைத் தடையின்றி கையாளவும். ஒவ்வொரு மாறுபாட்டிற்கும் புகைப்படங்கள், விலைகள் மற்றும் தள்ளுபடிகளைப் புதுப்பிக்கவும்.",
  "product.subsitution": "தயாரிப்பு மாற்றீடு",
  "product.subsitution.desc":
    "உங்கள் தயாரிப்புகளுக்கு மாற்றுகளை அமைத்து, முதலில் ஆர்டர் செய்யப்பட்ட தயாரிப்பு கிடைக்கவில்லை என்றால், மாற்று தயாரிப்புகளை அனுப்புவதன் மூலம் உங்கள் நிரப்பு விகிதத்தை மேம்படுத்தவும்.",
  "stock.management.desc":
    "ஸ்டாக் டேட்டாவைப் பதிவேற்றவும் அல்லது பதிவிறக்கவும், பஃபர் ஸ்டாக்கை அமைக்கவும் மற்றும் எந்தப் பொருளும் கையிருப்பில் இல்லாமல் போகும் போது எச்சரிக்கைகளைப் பெறவும்.",
  "payment.gateway.3rd.party": "3 வது தரப்பு கட்டண நுழைவாயில் ஒருங்கிணைப்பு",
  "payment.gateway.3rd.party.desc":
    "உங்கள் கட்டண முறையை விரைவாக அமைக்க , முன் கட்டமைக்கப்பட்ட 3 வது தரப்பு கட்டண நுழைவாயில்களின் முழு ஹோஸ்டையும் பயன்படுத்திக் கொள்ளுங்கள் . உங்கள் வணிகப் பரிவர்த்தனை கட்டணங்களை மேம்படுத்த, பல நுழைவாயில்கள் மூலம் உங்கள் பேமெண்ட் ரூட்டிங்கை புத்திசாலித்தனமாக தானியங்குபடுத்துங்கள்.",
  "paypal.integration": "பேபால் ஒருங்கிணைப்பு",
  "paypal.integration.desc":
    "எங்கள் உள்ளமைக்கப்பட்ட பேபால் ஒருங்கிணைப்புடன் சர்வதேச ஆர்டர்கள் மற்றும் கட்டணங்களை ஏற்கவும்.",
  "your.ewallet": "உங்கள் eWallet",
  "your.ewallet.desc":
    "உங்கள் வாடிக்கையாளர் விசுவாசத்தை மேம்படுத்துங்கள் மற்றும் வாடிக்கையாளர்களுக்கு உங்கள் பிராண்டட் eWallet ஐ வழங்குவதன் மூலம் அவர்களுக்கு வெகுமதி அளிக்கவும். உங்கள் வாடிக்கையாளர்கள் தங்கள் பணப்பையில் பணத்தைச் சேர்த்து, எதிர்காலத்தில் வாங்கும் போது அதைப் பயன்படுத்தலாம்.",
  "gift.cards.desc":
    "உங்கள் ஸ்டோரில் மட்டுமே ரிடீம் செய்யக்கூடிய தனிப்பயனாக்கக்கூடிய மின்-பரிசு அட்டைகளை விற்பதன் மூலம் உங்கள் வாடிக்கையாளர்கள் தங்கள் அன்புக்குரியவர்களுக்கு பரிசளிக்க உதவுங்கள்.",
  cod: "கேஷ் ஆன் டெலிவரி (சிஓடி)",
  "cod.desc": "உங்கள் வாடிக்கையாளர்களுக்கு COD செயல்பாட்டை வழங்குங்கள்.",
  "webpages.and.banners": "வலைப்பக்கங்கள் மற்றும் பதாகைகள்",
  "webpages.and.banners.desc":
    "புதிய தயாரிப்பு வெளியீடுகள், சலுகைகள், பருவகால மற்றும் பண்டிகை விற்பனை போன்றவற்றை மேம்படுத்த உங்கள் வலைத்தளத்தின் வெவ்வேறு பக்கங்களுக்கான தனிப்பயன் இணையப் பக்கங்கள் மற்றும் வலைப் பதாகைகளை உருவாக்கி திட்டமிடவும்.",
  "offers.management": "சலுகைகள் மேலாண்மை",
  "offers.management.desc":
    "தயாரிப்புகள்/ பிரிவுகள்/ பிராண்டுகள்/ வாடிக்கையாளர் மீது 10+ ஆஃபர்களை உருவாக்கவும், தனிப்பயனாக்கவும், இயக்கவும் மற்றும் கண்காணிக்கவும் (பிளாட் ஆஃப்/ % ஆஃப்/ குறைந்தபட்சம். கொள்முதல்/ காம்போஸ்/ வாங்க-ஒன்-கெட்-ஒன்/ % கூடுதல்) பிரிவுகள். சலுகையின் பொருந்தக்கூடிய வரம்புகளையும் விதிகளையும் அமைக்கவும்.",
  "campaign.management": "பிரச்சார மேலாண்மை",
  "campaign.management.desc":
    "உங்கள் வாடிக்கையாளர்களுக்கு பயன்பாட்டு அறிவிப்புகள், மின்னஞ்சல்கள் மற்றும் SMSகள் மூலம் விளம்பர பிரச்சாரங்களை உருவாக்கவும், திட்டமிடவும், இயக்கவும் மற்றும் கண்காணிக்கவும். கூப்பன்களை விநியோகிக்கவும், தயாரிப்பு வெளியீடுகளை அறிவிக்கவும், விலை குறைப்புகளை எச்சரிக்கவும்.",
  "coupons.desc":
    "உங்கள் வாடிக்கையாளர்களுக்கு தயாரிப்பு அல்லது ஷிப்பிங் தள்ளுபடிகளை வழங்க தனித்துவமான அல்லது நிலையான கூப்பன்களை உருவாக்கி விநியோகிக்கவும். தள்ளுபடி வரம்புகளை அமைத்து, ஆர்டர் மதிப்பு/ கட்டண விருப்பங்கள்/ ஆர்டர் நாள்/ வாடிக்கையாளர் பிரிவு/ ஸ்டோர் ஆகியவற்றின் அடிப்படையில் கூப்பனின் பொருந்தக்கூடிய தன்மையைக் கட்டுப்படுத்துங்கள். கூப்பன்களின் விநியோகம் மற்றும் அதன் விளைவாக விற்பனையின் வெற்றியை அளந்து கண்காணிக்கவும்.",
  seo: "தேடுபொறி உகப்பாக்கம் (SEO)",
  "seo.desc":
    "உங்கள் வாடிக்கையாளர்கள் உங்களை அல்லது உங்கள் தயாரிப்புகளை இணையத்தில் தேடும் போது அவர்களால் கண்டறியப்படும். உங்கள் பக்கத்தின் தலைப்புகள், விளக்கங்கள் மற்றும் முக்கிய வார்த்தைகளை Google தேடல்களில் அதிகமாகத் தோன்றும்படி அமைக்கவும்.",
  "orders.dashboard": "ஆர்டர்கள் டேஷ்போர்டு",
  "orders.dashboard.desc":
    "ஆர்டர் எண், வாடிக்கையாளர் பெயர், தொடர்பு விவரங்கள், ஆர்டர் நேரம், ஆர்டர் நிலை, கட்டண நிலை, ஆர்டர் மதிப்பு போன்ற முழுமையான விவரங்களுடன் உங்கள் ஆர்டர்கள் அனைத்தையும் ஒரே இடத்தில் பார்ப்பதற்கு உள்ளுணர்வு மற்றும் எளிதாக செல்லக்கூடிய டாஷ்போர்டு.",
  "orders.alerts": "ஆர்டர் விழிப்பூட்டல்கள்",
  "orders.alerts.desc":
    "ஆர்டர் வழங்குதல், ரத்து செய்தல், டெலிவரி போன்ற முக்கியமான நிகழ்வுகளில் உடனடி SMS/ புஷ் அறிவிப்புகளைப் பெறுவதன் மூலம், உங்கள் வாடிக்கையாளர்களின் ஆர்டர்களில் தொடர்ந்து இருக்கவும்.",
  "delivery.area.management": "டெலிவரி ஏரியா மேனேஜ்மென்ட்",
  "delivery.area.management.desc":
    "பின்கோடுகள் அல்லது கடையிலிருந்து தூரம் அல்லது வரைபடத்தில் வரைவதன் மூலம் நீங்கள் வழங்கக்கூடிய பகுதிகளைக் கட்டுப்படுத்தவும்.",
  "delivery.partner.integration": "டெலிவரி பார்ட்னர் ஒருங்கிணைப்பு",
  "delivery.partner.integration.desc":
    "உங்கள் வாடிக்கையாளர் ஆர்டர்களை நீங்களே வழங்குங்கள் அல்லது உங்கள் நகரத்திலோ அல்லது இந்தியாவில் எங்கிருந்தோ அனுப்புவதற்கு எங்கள் டெலிவரி பார்ட்னர்களின் பட்டியலிலிருந்து தேர்வு செய்யவும்.",
  "customer.details": "வாடிக்கையாளர் விவரங்கள்",
  "customer.details.desc":
    "நீங்கள் திருத்த, பதிவிறக்க, தேட அல்லது குழுவிற்காக, உங்கள் வாடிக்கையாளர் தரவு மற்றும் அவர்களின் கொள்முதல் வரலாறு அனைத்தும் ஒரே இடத்தில் கிடைக்கும். உங்கள் தற்போதைய வாடிக்கையாளர் தரவை இறக்குமதி செய்து அனைத்தையும் ஒரே இடத்தில் வைக்கவும்.",
  "live.chat": "நேரலை அரட்டை",
  "live.chat.desc":
    "உங்கள் வாடிக்கையாளர்கள் உங்கள் கடையில் ஷாப்பிங் செய்யும்போது, ​​நேரடி அரட்டை விட்ஜெட் மூலம் அவர்களின் கேள்விகளைத் தீர்க்கவும்.",
  "order.returns": "ஆர்டர் ரிட்டர்ன்ஸ்",
  "order.returns.desc":
    "ஆர்டர் ரிட்டர்ன்களைத் தடையின்றி நிர்வகிக்கவும், உங்கள் சரக்குகளைத் தானாகச் சரிசெய்து, உங்கள் வாடிக்கையாளர்களுக்குப் பணத்தைத் திரும்பப் பெறவும்.",
  "multi.user.access": "பல பயனர் அணுகல்",
  "multi.user.access.desc":
    "உங்கள் கடையை நிர்வகிக்க உங்கள் பணியாளர்களை அனுமதிக்கவும். பாத்திரங்கள் மற்றும் அனுமதிகளை அமைக்கவும். மாற்றங்கள் மற்றும் வருகையை நிர்வகிக்கவும்.",
  "multi.store": "பல அங்காடி",
  "multi.store.desc":
    "ஒன்றுக்கு மேற்பட்ட உடல் அங்காடிகள் உள்ளதா? விலைகள், சலுகைகள், டெலிவரி கட்டணங்கள் ஆகியவற்றின் அடிப்படையில் நிர்வகிக்க உங்களை அனுமதிக்கிறோம்",
  "reports.desc":
    "நிலையான விற்பனை, சந்தைப்படுத்தல், செயல்பாடுகள், வாடிக்கையாளர், பங்கு அறிக்கைகளைப் பதிவிறக்கவும் அல்லது உங்கள் தனிப்பயன் அறிக்கையை உருவாக்கவும்.",
  "analytics.desc":
    "உங்கள் வணிகத்தின் ஆரோக்கியத்தைக் கண்காணிக்க எங்கள் டாஷ்போர்டைப் பயன்படுத்தவும். உங்கள் விற்பனை, சந்தைப்படுத்தல் பிரச்சாரங்கள், செயல்பாடுகள், ஆர்டர்கள், வாடிக்கையாளர் வளர்ச்சி, பங்கு ஆகியவற்றைக் கண்காணித்து, அர்த்தமுள்ள நுண்ணறிவு மற்றும் வணிக நுண்ணறிவைப் பெறுங்கள்.",
  "fb.analytics": "Facebook Analytics",
  "fb.analytics.desc":
    "உங்கள் Facebook பிக்சல் ஐடியைப் பயன்படுத்தி உங்கள் Facebook விளம்பரங்களின் செயல்திறனை எளிதாக ஒருங்கிணைத்து கண்காணிக்கலாம்.",
  "google.analytics": "Google Analytics",
  "google.analytics.desc":
    "உங்கள் வாடிக்கையாளர் கையகப்படுத்தும் சேனல்கள், புள்ளிவிவரங்கள், வருவாய் மற்றும் பிற சிறந்த நுண்ணறிவுகளைக் கண்காணிக்க உங்கள் eStore உடன் உங்கள் Google Analytics ஐ எளிதாக ஒருங்கிணைக்கவும்.",
  "customer.app": "வாடிக்கையாளர் பயன்பாடு",
  "customer.app.desc":
    "உங்கள் கடைக்கான இலவச தனிப்பயனாக்கப்பட்ட மற்றும் பிராண்டட் iOS மற்றும் Android வாடிக்கையாளர் பயன்பாடு. உங்கள் பிராண்டைப் பிரதிபலிக்க உங்கள் பயன்பாட்டின் பெயரை அமைக்கவும், ஐகான் மற்றும் ஸ்பிளாஸ் திரைகளை அமைக்கவும்.",
  "delivery.app": "டெலிவரி ஆப்",
  "delivery.app.desc":
    "உங்கள் டெலிவரி பணியாளர்கள் ஆர்டர்களைத் தேர்ந்தெடுத்து, முன்னுரிமை அளித்து வழங்குவதற்கான இலவச ஆண்ட்ராய்டு ஆப்ஸ்.",
  "picker.app": "பிக்கர் ஆப்",
  "picker.app.desc":
    "ஆர்டர்களை எடுக்க, பேக் மற்றும் சரிபார்க்க மற்றும் லேபிள்களை அச்சிட உங்கள் பணியாளர்களுக்கான இலவச Android ஆப்ஸ்.",
  "admin.app": "நிர்வாகி ஆப்",
  "admin.app.desc":
    "உங்கள் மொபைலில் உங்கள் ஆன்லைன் ஸ்டோரை நிர்வகிப்பதற்கான இலவச iOS மற்றும் Android பயன்பாடு. உங்கள் விற்பனையைக் கண்காணிக்கவும், ஆர்டர்களை மதிப்பாய்வு செய்யவும், உங்கள் தயாரிப்புகளை மாற்றவும், மார்க்கெட்டிங் பிரச்சாரங்களை இயக்கவும் மற்றும் பயணத்தின்போது உங்கள் கடை அமைப்புகளை மாற்றவும்.",
  "custom.password.format": "வாடிக்கையாளர் கடவுச்சொல் வடிவமைப்பு அமைப்பு",
  "custom.password.format.desc":
    "கடவுச்சொல்லை உருவாக்கும் போது உங்கள் வாடிக்கையாளர்கள் பின்பற்ற வேண்டிய தனிப்பயன் விதிகளை அமைக்கவும், அதன் மூலம் பாதுகாப்பை மேம்படுத்தவும்.",
  "data.security": "தரவு பாதுகாப்பு",
  "data.security.desc": "உங்கள் தரவை நீங்கள் மட்டுமே அணுக முடியும்.",
  "data.and.security": "தரவு மற்றும் பாதுகாப்பு",
  apps: "பயன்பாடுகள்",
  channels: "சேனல்கள்",
  "customer.management": "வாடிக்கையாளர் மேலாண்மை",
  delivery: "டெலிவரி",
  marketing: "சந்தைப்படுத்தல்",
  cataloguing: "பட்டியல்",
  "testimonials.ambros":
    "I was completely new to e-commerce and scared about the last-mile delivery operations. I couldn’t have asked for a better or easier solution than the Zopping e-commerce platform. The entire process from order placement on web/mobiles apps through last-mile delivery is easy and extremely efficient. A fantastic experience overall! Amazing solution for retailers at an affordable price from the Zopping team!",
  "testimonials.medinmin":
    "இணையம் மற்றும் ஆப்ஸ் மேம்பாட்டை எதிர்பார்க்கும் அனைவருக்கும் இ-காம் தளத்தை Zopping செய்ய பரிந்துரைக்கிறேன். உங்கள் தேவைகளை உடனடியாகப் பூர்த்தி செய்யும் பிளக் அண்ட் ப்ளே செயல்பாடுகள் உள்ளன. அவை எனது எதிர்பார்ப்புகளை நிறைவேற்றியது மட்டுமல்லாமல் அவற்றையும் தாண்டியது. இணையம் மற்றும் மொபைல் பயன்பாடுகள் மிகவும் கவர்ச்சிகரமானவை மற்றும் பயனர்களுடன் சிறந்த ஈடுபாட்டை வழங்குகின்றன. அவர்களின் சேவை, மென்பொருள் மற்றும் தொழில்முறை ஆகியவற்றால் நான் மிகவும் ஈர்க்கப்பட்டேன்.",
  "testimonials.blossom":
    "எங்கள் புத்தக வணிகத்தை ஆன்லைனில் எடுத்துச் செல்ல ஒரு விண்ணப்பம் தேவைப்படும்போது Zopping எங்களுக்கு சரியான வழிகாட்டுதலை வழங்கியது. நான் முடிவெடுப்பதற்கு முன், நாங்கள் அவர்களின் விற்பனைக் குழுவுடன் கலந்துரையாடினோம், எங்கள் யோசனையை ஆன்லைன் புத்தகக் கடையாக மாற்றுவது எப்படி என்று எங்களுக்கு வழிகாட்டியது. பின்னர், ஆன்போர்டிங் குழு அமைப்பு செயல்முறை முழுவதும் எங்களுடன் தொடர்பில் இருந்தது மற்றும் எங்கள் தேவைகளுக்கு ஏற்ப இணையதளத்தை அவர்கள் தனிப்பயனாக்குவதை உறுதி செய்தனர். ஒரு டன் நன்றி, Zopping குழு!",
  "testimonials.name.ambros": "ரோஹித் குமார்",
  "testimonials.designation.ambros": "இயக்குனர்",
  "testimonials.company.ambros": "ஆம்ப்ரோஸ் ரீடெய்ல்",
  "testimonials.name.medinmin": "இர்பான் பி",
  "testimonials.designation.medinmin": "ஓப்ஸ் மேலாளர்",
  "testimonials.company.medinmin": "மெடின்மினிட்ஸ்",
  "testimonials.name.blossom": "மயிகவுடா",
  "testimonials.designation.blossom": "நிர்வாக இயக்குனர்",
  "testimonials.company.blossom": "ப்ளாசம் புக் ஹவுஸ்",
  Hyperlocal: "ஹைப்பர்லோகல்",
  "Hyperlocal.descroption":
    "உங்கள் அருகில் அல்லது வட்டாரத்தில் விற்கவும் டிஜிட்டல் மார்க்கெட்டில் வெற்றிபெறவும் சரியான இணையவழி தளத்தை Zopping வழங்குகிறது. சோப்பிங் ஏன் மற்றவற்றிலிருந்து தனித்து நிற்கிறது என்பது இங்கே.",
  "Create.Your.Online.Store": "உங்கள் ஆன்லைன் ஸ்டோரை உருவாக்கவும்",
  "Best.suited.for.selling": "விற்பனைக்கு மிகவும் பொருத்தமானது",
  Grocery: "மளிகை",
  "Food.and.Beverage": "உணவு மற்றும் குளிர்பானங்கள்",
  Medicine: "மருந்து",
  Bakery: "பேக்கரி",
  Diary: "நாட்குறிப்பு",
  Fish: "மீன்",
  "Embrace.Convenience": "வசதியைத் தழுவுங்கள்",
  "Embrace.Convenience.discription1":
    "Zopping உங்கள் வாடிக்கையாளர்களின் விருப்பங்களுக்கு ஏற்ப ஸ்டோர் பிக்அப் மற்றும் டெலிவரி விருப்பங்களை வழங்குகிறது..",
  "Embrace.Convenience.discription2":
    " உங்கள் பட்டியல், சரக்கு, ஆர்டர்கள் மற்றும் வாடிக்கையாளர் தரவை Zopping இன் ஓம்னிச்சனல் பாயின்ட் ஆஃப் சேல் (POS) அமைப்புடன் ஒத்திசைக்கவும்.",
  "Flexible.Order.Management": "நெகிழ்வான ஒழுங்கு மேலாண்மை",
  "Flexible.Order.Management.description1":
    "பின்கோடுகள்/ பகுதி/ அடுக்குமாடி குடியிருப்புகள் மூலம் உங்கள் சேவைப் பகுதிகளை வரையறுத்து, உங்கள் உள்ளூர் வாடிக்கையாளர் தளத்திற்கு திறமையாக சேவை செய்யுங்கள்.",
  "Flexible.Order.Management.description2":
    "பல கிளைகள் அல்லது கடைகளைக் கொண்ட வணிகங்களை Zopping ஆதரிக்கிறது, செயல்பாடுகளை ஒழுங்குபடுத்துகிறது மற்றும் பரந்த வாடிக்கையாளர் தளத்தை அடைகிறது.",
  "Flexible.Order.Management.description3":
    "கூடுதல் கட்டணம் அல்லது தள்ளுபடியுடன் குறிப்பிட்ட பிக்அப் அல்லது டெலிவரி ஸ்லாட்டுகளை ஊக்குவிக்கவும்.",
  "Flexible.Order.Management.description4":
    "ஆர்டர்களை திறம்பட நிர்வகிப்பதற்கு வரம்புகளை அமைக்கவும் மற்றும் உச்ச காலங்களில் அதிக தேவைகளை தடுக்கவும்.",
  "Simplified.Delivery.Management": "எளிமைப்படுத்தப்பட்ட விநியோக மேலாண்மை",
  "Simplified.Delivery.Management.description1":
    " ஜோப்பிங் டெலிவரி பயன்பாடு ஆர்டர் கையாளுதலில் புரட்சியை ஏற்படுத்துகிறது, உடனடி மற்றும் நம்பகமான சேவையை உறுதி செய்கிறது.",
  "Simplified.Delivery.Management.description2":
    "பயணித்த தூரத்தின் அடிப்படையில் டெலிவரி கட்டணம் மாறுபடுவது, செலவுகளை ஈடுகட்டும்போது நியாயமான விலையை உறுதி செய்கிறது.",
  "Delight.your.customers": "உங்கள் வாடிக்கையாளர்களை மகிழ்விக்கவும்",
  "Delight.your.customers.description1":
    "உங்கள் இணையதளம் மற்றும் மொபைல் பயன்பாடு முழுவதும் தடையற்ற மற்றும் நிலையான ஷாப்பிங் அனுபவத்தை வழங்கவும்.",
  "Delight.your.customers.description2":
    "எங்கிருந்தும், எந்த நேரத்திலும் ஆர்டர் செய்யும் வசதியை வழங்குகிறது.",
  "Zopping.Advantage": "ஜோப்பிங் நன்மை",
  "Zopping.Advantage.description1":
    "வழக்கமான புதுப்பிப்புகள் மற்றும் போட்டியை விட முன்னேறிச் செல்லும் மேம்பாடுகள் மூலம் பயனடையுங்கள்.",
  "Zopping.Advantage.description2":
    "உங்கள் தனிப்பயன் பிராண்டட் நேட்டிவ் iOS மற்றும் ஆண்ட்ராய்டு ஆப்ஸை ஒரு நாளுக்குள் இயக்கவும்.",
  "Zopping.Advantage.description3":
    "இடம் மற்றும் அது தானாகவே உங்கள் இணையதளம் மற்றும் மொபைல் பயன்பாட்டில் புதுப்பிக்கப்படும்.",
  "Engage.your.customer.with.Push":
    "புஷ் அறிவிப்புகளுடன் உங்கள் வாடிக்கையாளரை ஈடுபடுத்துங்கள்",
  "Engage.your.customer.with.Push.description1":
    "தனிப்பயனாக்கப்பட்ட செய்திகள், சிறப்பு சலுகைகள் மற்றும் புதிய வருகை அறிவிப்புகள் மூலம் விற்பனையை அதிகரிக்கவும் மற்றும் வாடிக்கையாளர்களை நிகழ்நேரத்தில் ஈடுபடுத்தவும்.",
};

export default messages;
