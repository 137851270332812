import React, { useEffect, useState } from "react";
import supportedLang from "../../lib/supportedLanguages";

const RtlLanguages = ["ar"]; // right to left languages

const LanguageContext = React.createContext({
  updateLanguage: () => {},
  language: "en",
  direction: "ltr",
  dictionary: {},
});

const LanguageProvider = (props) => {
  const [language, setLanguage] = useState(props.language || "");
  const [direction] = useState(RtlLanguages.includes(language) ? "rtl" : "ltr");

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const updateLanguage = (lang) => {
    setLanguage(lang);
    const path = window.location.pathname;
    const searchParams = window.location.search;

    let pathComponents = path.split("/");

    if (supportedLang.indexOf(pathComponents[1]) > -1) {
      // replace the old lang with newly selected lang
      pathComponents.splice(1, 1, lang);
    } else {
      // prefix the lang to the url
      pathComponents.splice(1, 0, lang);
    }

    const updatedPath = pathComponents.join("/");
    // updated url with selected language
    window.location.href = updatedPath + searchParams;
  };
  const dictionaryKeys = require(`../../languages/${language || "en"}`);

  return (
    <LanguageContext.Provider
      value={{
        updateLanguage,
        language,
        direction,
        dictionary: dictionaryKeys.default,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;

export { LanguageContext };
