const messages = {
  "header.home": "വീട്",
  "header.products": "ഉൽപ്പന്നങ്ങൾ",
  "header.technology": "ടെക്നോളജി",
  "header.about.us": "ഞങ്ങളേക്കുറിച്ച്",
  "header.careers": "തൊഴിലവസരങ്ങൾ",
  "header.contact.us": "ഞങ്ങളെ സമീപിക്കുക",
  "header.language": "ഭാഷ",
  english: "ഇംഗ്ലീഷ്",
  hindi: "ഹിന്ദി",
  signin: "സൈൻ ഇൻ",
  "signing.in": "സൈൻ ഇൻ ചെയ്യുന്നു",
  signup: "സൈൻ അപ്പ് ചെയ്യുക",
  home: "വീട്",
  features: "സവിശേഷതകൾ",
  pricing: "വിലനിർണ്ണയം",
  contact: "ഞങ്ങളെ സമീപിക്കുക",
  solutions: "പരിഹാരങ്ങൾ",
  "contact.us": "ഞങ്ങളെ സമീപിക്കുക",
  "products.smartstore.eazy": "സ്‌മാർട്ട്‌സ്റ്റോർ ഈസി",
  "products.smartstore.enterprise": "സ്മാർട്ട്സ്റ്റോർ എന്റർപ്രൈസ്",
  "products.smartproduct.locator": "സ്മാർട്ട് ഉൽപ്പന്ന ലൊക്കേറ്റർ",
  "products.smartpayment.gateway": "സ്മാർട്ട് പേയ്‌മെന്റ് ഗേറ്റ്‌വേ",
  "products.smartpoint.sale": "സ്മാർട്ട് പോയിന്റ് ഓഫ് സെയിൽ",
  "products.grocery.express": "ഗ്രോസറി എക്സ്പ്രസ്",
  "smartstore.eazy.heading": "പ്രധാന സവിശേഷതകൾ",
  "smartstore.eazy.selling.online": "ഓൺലൈനിൽ വിൽക്കാൻ തുടങ്ങേണ്ടതെല്ലാം",
  "smartstore.eazy.unique.store": "നിങ്ങളുടെ അദ്വിതീയ ഓൺലൈൻ സ്റ്റോർ നേടുക",
  "smartstore.eazy.feature.standout":
    "നിങ്ങളുടെ അദ്വിതീയ വെബ്‌സൈറ്റ് സൃഷ്‌ടിച്ച് ആൾക്കൂട്ടത്തിൽ വേറിട്ടുനിൽക്കുക",
  "smartstore.eazy.feature.domain":
    "നിങ്ങളുടെ സ്വന്തം ഡൊമെയ്ൻ നാമം ഉപയോഗിക്കുകയും നിങ്ങളുടെ ബ്രാൻഡ് മൂല്യം വർദ്ധിപ്പിക്കുകയും ചെയ്യുക",
  "smartstore.eazy.feature.colors":
    "നിങ്ങളുടെ ബ്രാൻഡിനെ പ്രതിഫലിപ്പിക്കുന്ന നിറങ്ങളും തീമുകളും തിരഞ്ഞെടുക്കുക",
  "smartstore.eazy.feature.banners":
    "ആകർഷകമായ ബാനറുകൾ അപ്‌ലോഡ് ചെയ്‌ത് നിങ്ങളുടെ വെബ്‌സൈറ്റ് മെച്ചപ്പെടുത്തുക",
  "smartstore.eazy.setup.shop": "നിങ്ങളുടെ ഷോപ്പ് എളുപ്പത്തിൽ സജ്ജീകരിക്കുക",
  "smartstore.eazy.create.own":
    "നിങ്ങളുടെ സ്വന്തം ഷോപ്പ് സൃഷ്ടിക്കുക - സാങ്കേതിക വൈദഗ്ധ്യം ആവശ്യമില്ല",
  "smartstore.eazy.feature.setup.online":
    "നിങ്ങളുടെ ഓൺലൈൻ ഷോപ്പ് എളുപ്പത്തിൽ സജ്ജീകരിക്കുന്നതിന് ഞങ്ങളുടെ ഘട്ടം ഘട്ടമായുള്ള ഗൈഡ് ഉപയോഗിക്കുക",
  "smartstore.eazy.feature.text.boxes":
    "ലളിതമായ ടെക്സ്റ്റ് ബോക്സുകൾ, ഡ്രോപ്പ്-ഡൌണുകൾ, ഡ്രാഗ് & ഡ്രോപ്പ് മെനുകൾ എന്നിവ ഉപയോഗിച്ച് എല്ലാ മാറ്റങ്ങളും വരുത്തുക",
  "smartstore.eazy.feature.catalogue":
    "നിങ്ങളുടെ കാറ്റലോഗ് സൃഷ്‌ടിക്കുക, വിലകൾ നിശ്ചയിക്കുക, ഷിപ്പിംഗ് നിയമങ്ങൾ സജ്ജമാക്കുക, ഓൺലൈനിൽ വിൽക്കാൻ തുടങ്ങുക",
  "smartstore.eazy.feature.mobile": "തൽക്ഷണം മൊബൈലിലേക്ക് പോകുക",
  "smartstore.eazy.additional.sales":
    "മൊബൈൽ ഉപകരണങ്ങളിൽ വിൽക്കുന്നതിലൂടെ അധിക വിൽപ്പന നേടുക",
  "smartstore.eazy.selling.mobile":
    "മൊബൈൽ ഉപകരണങ്ങളിൽ നിങ്ങളുടെ വെബ്‌സൈറ്റ് കൂടുതൽ ആകർഷകമായി കാണപ്പെടുമെന്നതിനാൽ തൽക്ഷണം മൊബൈലിൽ വിൽക്കാൻ ആരംഭിക്കുക",
  "smartstore.eazy.shopping.convenience":
    "ഉപകരണങ്ങളിലുടനീളം ഷോപ്പിംഗ് കാർട്ട് സാധാരണമായതിനാൽ ഷോപ്പിംഗ് സൗകര്യം പ്രവർത്തനക്ഷമമാക്കുക",
  "smartstore.eazy.international.app":
    "നിങ്ങളുടെ സ്വന്തം അന്താരാഷ്ട്ര നിലവാരമുള്ള Android, iOS ആപ്പ് നേടുക",
  "smartstore.eazy.manage.go": "എവിടെയായിരുന്നാലും അത് നിയന്ത്രിക്കുക",
  "smartstore.eazy.easy.access":
    "നിങ്ങളുടെ ആൻഡ്രോയിഡ് മൊബൈലിൽ നിങ്ങളുടെ ഷോപ്പ്-അഡ്മിനെ എളുപ്പത്തിൽ ആക്സസ് ചെയ്യുക",
  "smartstore.eazy.update.prices":
    "വിലകൾ, സ്റ്റോക്ക്, ചിത്രങ്ങൾ എന്നിവ അപ്ഡേറ്റ് ചെയ്യുക",
  "smartstore.eazy.important.alerts":
    "ഓർഡർ പ്ലേസ്‌മെന്റ്, പേയ്‌മെന്റ്, റദ്ദാക്കൽ എന്നിവ പോലുള്ള പ്രധാനപ്പെട്ട ഇവന്റുകളെക്കുറിച്ചുള്ള അലേർട്ടുകൾ നേടുക",
  "smartstore.eazy.customer.reply":
    "ഉപഭോക്തൃ ഇമെയിലുകൾക്കും ചാറ്റുകൾക്കും കാലതാമസമില്ലാതെ മറുപടി നൽകുക",
  "smartstore.eazy.customer.reachout":
    "നിങ്ങളുടെ ഉപഭോക്താക്കളിലേക്ക് എത്തിച്ചേരുക",
  "smartstore.eazy.powerful.seo":
    "ഞങ്ങളുടെ ശക്തമായ SEO ഉപയോഗിച്ച് പുതിയ ഉപഭോക്താക്കളെ നേടുക",
  "smartstore.eazy.offers.coupons":
    "നിങ്ങളുടെ ഉപഭോക്താക്കളുടെ ഒരു വിഭാഗത്തെ ലക്ഷ്യമാക്കി ഓഫറുകളും കൂപ്പണുകളും സൃഷ്ടിക്കുക",
  "smartstore.eazy.marketing.campaigns":
    "നിർദ്ദിഷ്ട ഉപഭോക്താക്കൾക്ക് SMS, ഇമെയിലുകൾ, ആപ്പ് അറിയിപ്പുകൾ എന്നിവ വഴി ടാർഗെറ്റുചെയ്‌ത മാർക്കറ്റിംഗ് കാമ്പെയ്‌നുകൾ പ്രവർത്തിപ്പിക്കുക",
  "smartstore.eazy.manage.customers": "നിങ്ങളുടെ ഉപഭോക്താക്കളെ നിയന്ത്രിക്കുക",
  "smartstore.eazy.build.loyalty":
    "വിശ്വസ്തത വളർത്തിയെടുക്കാൻ ഞങ്ങളുടെ ശക്തമായ CRM ഉപയോഗിക്കുക",
  "smartstore.eazy.view.customers":
    "നിങ്ങളുടെ എല്ലാ ഉപഭോക്താക്കളെയും അവരുടെ വിശദമായ വാങ്ങൽ ചരിത്രം ഉപയോഗിച്ച് ഒരിടത്ത് കാണുക",
  "smartstore.eazy.upload.details":
    "നിങ്ങളുടെ ഓഫ്‌ലൈൻ-ഉപഭോക്താക്കൾക്ക് ഒരേ നിലവാരത്തിലുള്ള സേവനം നൽകുന്നതിന് അവരുടെ വിശദാംശങ്ങൾ അപ്‌ലോഡ് ചെയ്യുക",
  "smartstore.eazy.add.edit.details":
    "നിങ്ങളുടെ ഉപഭോക്താക്കളുടെ വിലാസം, ഇമെയിൽ, ഫോൺ നമ്പർ എന്നിവ പോലുള്ള വിശദാംശങ്ങൾ ചേർക്കുക അല്ലെങ്കിൽ എഡിറ്റ് ചെയ്യുക",
  message404:
    "നിങ്ങൾ ഇന്റർനെറ്റ് തകർത്തില്ല, എന്നാൽ നിങ്ങൾ തിരയുന്നത് ഞങ്ങൾക്ക് കണ്ടെത്താൻ കഴിയുന്നില്ല.",
  "go.home": "ഹോംപേജിലേക്ക് പോകുക",
  "zopping.pricing": "വിലനിർണ്ണയ പദ്ധതി",
  "pay.for.use":
    "നിങ്ങൾ ഓൺലൈനിൽ വിൽക്കുന്ന കാര്യങ്ങൾക്ക് മാത്രം ഞങ്ങൾക്ക് പണം നൽകുക",
  "currency.selector": "കറൻസി സെലക്ടർ",
  "everything.free": "ഡെസ്‌ക്‌ടോപ്പും മൊബൈൽ വെബും ഉൾപ്പെടെ എല്ലാം സൗജന്യമാണ്",
  "android.app.customers": "ഉപഭോക്താക്കൾക്കുള്ള ആൻഡ്രോയിഡ് ആപ്പ്",
  "ios.app.customers": "ഉപഭോക്താക്കൾക്കുള്ള iOS ആപ്പ്",
  "picker.app.employees": "തൊഴിലവസരങ്ങൾക്കുള്ള പിക്കർ ആപ്പ്",
  "logistics.app.employees": "ജോലിക്കുള്ള ലോജിസ്റ്റിക് ആപ്പ്",
  "buy.now": "ഇപ്പോൾ വാങ്ങുക",
  "contact.us.support": "പിന്തുണയ്‌ക്കായി ഞങ്ങളെ ബന്ധപ്പെടുക",
  "customization.integration":
    "ഇഷ്‌ടാനുസൃതമാക്കലിനും സംയോജനത്തിനും ഞങ്ങളെ സമീപിക്കുക",
  "cant.find.answer": "ഉത്തരം കണ്ടെത്താൻ കഴിയുന്നില്ലേ?",
  "pricing.faq": "വിലനിർണ്ണയം - പതിവായി ചോദിക്കുന്ന ചോദ്യങ്ങൾ",
  "view.more": "കൂടുതൽ കാണു",
  "view.less": "കുറവ് കാണുക",
  "more.than": "അതിലും കൂടുതൽ",
  "month.sales": "മാസ വിൽപ്പന",
  "upto.text": "വരെ",
  heroHeadingText: "നിങ്ങളുടെ ബിസിനസ്സ് ഓൺലൈനിൽ വളർത്തുക",
  heroParagraphText:
    "ചില്ലറ വ്യാപാരികൾക്കായുള്ള ആത്യന്തിക ഇ-കൊമേഴ്‌സ് പ്ലാറ്റ്‌ഫോം.",
  heroFormButtonText: "സൗജന്യമായി ഓൺലൈനിൽ വിൽപ്പന ആരംഭിക്കുക",
  heroFormInputPlaceholder: "ഇമെയിൽ വിലാസം അല്ലെങ്കിൽ ഫോൺ നമ്പർ",
  "email.two.days": "രണ്ട് പ്രവൃത്തി ദിവസത്തിനുള്ളിൽ ഇമെയിൽ ചെയ്യുക",
  "email.instant.chat": "കഴിയുന്നതും വേഗം ഇമെയിൽ & തൽക്ഷണ ചാറ്റ്",
  "email.and.telephonic":
    "കഴിയുന്നതും വേഗം ഇമെയിൽ, തൽക്ഷണ ചാറ്റ്, ടെലിഫോണിക്ക്",
  "email.account.manager":
    "ഇമെയിൽ, തൽക്ഷണ ചാറ്റ്, ടെലിഫോണിക് & അക്കൗണ്ട് മാനേജർ എന്നിവയെ നിയോഗിക്കും",
  testimonialsHeading: "ഞങ്ങളുടെ ഉപഭോക്താക്കൾ ഞങ്ങൾ ചെയ്യുന്നത് ഇഷ്ടപ്പെടുന്നു",
  testimonialsSubHeading:
    "ഞങ്ങളുടെ സേവനങ്ങൾക്കൊപ്പം ഞങ്ങളുടെ ഉപഭോക്താക്കൾ ഞങ്ങൾക്ക് ഒരു കൂട്ടം പുഞ്ചിരികൾ അയയ്ക്കുകയും ഞങ്ങൾ അവരെ സ്നേഹിക്കുകയും ചെയ്യുന്നു",
  "select.language.heading": "ഭാഷ തിരഞ്ഞെടുക്കുക",
  "my.account": "എന്റെ അക്കൗണ്ട്",
  "logout.text": "പുറത്തുകടക്കുക",
  "privacy.policy": "സ്വകാര്യതാനയം",
  "pricing.comparison.heading": "എന്തിന് ഞങ്ങളെ തിരഞ്ഞെടുക്കണം?",
  "pricing.comparison.subheading":
    "മറ്റുള്ളവരുമായുള്ള ഞങ്ങളുടെ ഫീച്ചർ താരതമ്യം കാണുക",
  "compare.with.others": "മറ്റുള്ളവരുമായി താരതമ്യം ചെയ്യുക",
  customDomain: "ഇഷ്ടാനുസൃത ഡൊമെയ്ൻ",
  onlineStore: "ഓൺലൈൻ സ്റ്റോർ",
  catalogue: "കാറ്റലോഗ്",
  payments: "പേയ്‌മെന്റുകൾ",
  marketingOffers: "മാർക്കറ്റിംഗും ഓഫറുകളും",
  orderManagement: "ഓർഡർ മാനേജ്മെന്റ്",
  ratingsReviews: "റേറ്റിംഗുകളും അവലോകനങ്ങളും",
  support: "ഇമെയിലുകൾ, ചാറ്റുകൾ, ഫോൺ കോളുകൾ എന്നിവ വഴിയുള്ള പിന്തുണ",
  salesChannels: "വിൽപ്പന ചാനലുകൾ",
  orderCreation: "മാനുവൽ ഓർഡർ സൃഷ്ടിക്കൽ",
  discounts: "ഉൽപ്പന്ന കിഴിവും കൂപ്പൺ കിഴിവുകളും",
  ssl: "SSL സർട്ടിഫിക്കറ്റ്",
  cart: "ഉപേക്ഷിച്ച വണ്ടി",
  giftCards: "ഗിഫ്റ്റ് കാർഡുകൾ",
  reportsAnalytics: "റിപ്പോർട്ടുകളും അനലിറ്റിക്‌സും",
  reportAnalytics: "റിപ്പോർട്ടുകളും അനലിറ്റിക്‌സും",
  yes: "അതെ",
  no: "ഇല്ല",
  limited: "ലിമിറ്റഡ്",
  unlimited: "അൺലിമിറ്റഡ്",
  hostingSsl: "ഹോസ്റ്റിംഗും SSL സർട്ടിഫിക്കറ്റും",
  themes: "തീമുകൾ",
  customerSignup: "ഉപഭോക്തൃ സൈൻഅപ്പ് പേജ്",
  multiStore: "മൾട്ടി സ്റ്റോറുകൾ",
  multiSales: "മൾട്ടി സെയിൽസ് ചാനൽ",
  products: "ഉൽപ്പന്നങ്ങൾ",
  collectionTags: "ശേഖരങ്ങളും ടാഗുകളും",
  weightBasedPrices: "ഭാരം അടിസ്ഥാനമാക്കിയുള്ള വിലകൾ",
  productSubstitution: "ഉൽപ്പന്ന പകരക്കാർ",
  stockManagement: "സ്റ്റോക്ക് മാനേജ്‌മെന്റ്",
  rackManagement: "റാക്ക് മാനേജ്മെന്റ്",
  gatewayInteg: "ഗേറ്റ്‌വേ ഇന്റഗ്രേഷൻ",
  walletCashback: "വാലറ്റുകളും ക്യാഷ്ബാക്കുകളും",
  cashOnDelivery: "ക്യാഷ് ഓൺ ഡെലിവറി",
  voucherGifts: "വൗച്ചറുകൾ/ സമ്മാന കാർഡുകൾ",
  campaigns: "കാമ്പെയ്‌നുകൾ (ഇമെയിൽ/ എസ്എംഎസ്)",
  notifications: "ആപ്പ് അറിയിപ്പുകൾ",
  customSeo: "കസ്റ്റം എസ്.ഇ.ഒ",
  coupons: "കൂപ്പണുകൾ",
  offers: "ഉൽപ്പന്നങ്ങൾ/ ശേഖരങ്ങൾ/ ടാഗുകൾ എന്നിവയിലെ ഓഫറുകൾ",
  manualOrder: "മാനുവൽ ഓർഡർ സൃഷ്ടിക്കൽ",
  pickerApp: "പിക്കർ മൊബൈൽ ആപ്പ്",
  intelligentPicking: "ഇന്റലിജന്റ് പിക്കിംഗ് ക്യൂ",
  deliveryManagement: "ഡെലിവറി മാനേജ്മെന്റ്",
  surcharge: "സർചാർജ്",
  slotCharges: "സ്ലോട്ട് അടിസ്ഥാനമാക്കിയുള്ള നിരക്കുകൾ",
  vehiclePlanning: "വാഹന ആസൂത്രണം",
  routePlanning: "റൂട്ട് പ്ലാനിംഗ്",
  deliveryPersonTracking: "ഡെലിവറി വ്യക്തി ട്രാക്കിംഗ്",
  thirdPartyLogistics: "മൂന്നാം കക്ഷി ലോജിസ്റ്റിക്സ് ഇന്റഗ്രേഷൻ",
  staffManagement: "സ്റ്റാഫ് മാനേജ്മെന്റ്",
  multiUsers: "ഒന്നിലധികം ഉപയോക്താക്കൾ",
  employeeMobileApp: "ജീവനക്കാരുടെ മൊബൈൽ ആപ്പ്",
  attendanceSummary: "ഹാജർ സംഗ്രഹം",
  roleBasedPermissions: "റോൾ അടിസ്ഥാനമാക്കിയുള്ള അനുമതികൾ",
  reports: "റിപ്പോർട്ടുകൾ",
  analytics: "അനലിറ്റിക്സ്",
  monthlyFixedCharges: "പ്രതിമാസ ഫിക്സഡ് ചാർജുകൾ",
  commissions: "കമ്മീഷനുകൾ",
  freePlugins: "സൗജന്യ പ്ലഗിനുകൾ",
  customerMobileApp: "ഉപഭോക്തൃ മൊബൈൽ ആപ്പ് (iOS, Android)",
  "contact.help.banner.question": "എങ്ങനെയാണ് നിന്നെ ഞങ്ങള് സഹായിക്കേണ്ടത്?",
  "contact.help.banner.tagline":
    "നിങ്ങളുടെ കോൺ‌ടാക്റ്റ് വിശദാംശങ്ങൾ ചുവടെ ഇടുക, ഞങ്ങളുടെ ഇ-കൊമേഴ്‌സ് വിദഗ്‌ദ്ധർ നിങ്ങളെ എത്രയും വേഗം ബന്ധപ്പെടും",
  "contact.placeholder.fullname": "പൂർണ്ണമായ പേര്",
  "contact.placeholder.phone": "ഫോൺ നമ്പർ",
  "contact.placeholder.email": "ഇമെയിൽ വിലാസം",
  "contact.placeholder.message": "സന്ദേശം",
  "contact.reachout": "പെട്ടെന്നുള്ള പ്രതികരണത്തിനായി ഞങ്ങളെ സമീപിക്കുക",
  "contact.reachout.question": "ഒരു ചോദ്യം കിട്ടിയോ? ഞങ്ങളുമായി ബന്ധപ്പെടുക",
  "contact.chat": "ഞങ്ങളുമായി ചാറ്റ് ചെയ്യുക",
  "map.message": "ഞങ്ങൾ ഇവിടെ തന്നെയുണ്ട്",
  "map.viewmap": "മാപ്പിൽ കാണുക",
  "zopping.welcome": "സോപ്പിംഗിലേക്ക് സ്വാഗതം",
  "zopping.loginForm.subheading": "നിങ്ങളുടെ വിശദാംശങ്ങൾ ചുവടെ നൽകുക",
  "loginForm.username.placeholder":
    "നിങ്ങളുടെ ഇമെയിൽ അല്ലെങ്കിൽ ഫോൺ നമ്പർ നൽകുക",
  "feature.one.description":
    "സൗജന്യമായി ആരംഭിക്കുക, നിങ്ങൾ ഉപയോഗിക്കുന്നതിന് മാത്രം പണം നൽകുക",
  "feature.two.description":
    "എല്ലാ ഫീച്ചറുകളും ആപ്പുകളും അധിക ചെലവില്ലാതെ പൂർണ്ണമായും അൺലോക്ക് ചെയ്തു",
  "feature.three.description":
    "നിങ്ങളുടെ പ്രതിമാസ ഓൺലൈൻ വിൽപ്പനയെ പിന്തുടരുന്ന വേരിയബിൾ പ്രതിമാസ നിരക്ക്",
  "highlight.heading": "നിങ്ങൾക്ക് ലഭിക്കുന്നത്",
  "monthly.store.sales": "പ്രതിമാസ ഓൺലൈൻ സ്റ്റോർ വിൽപ്പന",
  "charges.per.month": "പ്രതിമാസ നിരക്കുകൾ",
  "setup.store.free": "നിങ്ങളുടെ സ്റ്റോർ സൗജന്യമായി സജ്ജമാക്കുക!",
  password: "Password",
  "loginForm.password.placeholder": "നിങ്ങളുടെ പാസ്‌വേഡ് നൽകുക",
  "remember.me": "എന്നെ ഓർമ്മിക്കുക",
  "dont.have.account": "ഒരു അക്കൗണ്ട് ഇല്ല",
  "create.account": "ഒരു ഇടപാട് തുടങ്ങു",
  name: "പേര്",
  "enter.name": "നിങ്ങളുടെ പേര് നൽകുക",
  "create.your.account": "നിങ്ങളുടെ അക്കൗണ്ട് സൃഷ്ടിക്കുക",
  "creating.account": "നിങ്ങളുടെ അക്കൗണ്ട് സൃഷ്ടിക്കുന്നു",
  "already.have.account": "ഇതിനകം ഒരു അക്കൗണ്ട് ഉണ്ടോ",
  "forgot.password": "പാസ്വേഡ് മറന്നോ?",
  "submit.text": "സമർപ്പിക്കുക",
  submitting: "സമർപ്പിക്കുന്നു",
  "resetPassword.successMessage":
    "നിങ്ങളുടെ പാസ്‌വേഡ് വിജയകരമായി പുനഃസജ്ജമാക്കി",
  "resetPassword.loginLinkText": "ലോഗിൻ ചെയ്യാൻ പോകുക",
  "can.try.zopping": "എനിക്ക് പണം നൽകാതെ സോപ്പിംഗ് പരീക്ഷിക്കാൻ കഴിയുമോ?",
  "sign.up.free.trial":
    "അതെ. സൈൻ അപ്പ് ചെയ്‌ത് പ്ലാറ്റ്‌ഫോമിന്റെ 15 ദിവസത്തെ സൗജന്യ ട്രയൽ പ്രയോജനപ്പെടുത്തുക.",
  "enter.credit.card":
    "ട്രയൽ എടുക്കാൻ ഞാൻ എന്റെ ക്രെഡിറ്റ് കാർഡ് നമ്പറോ മറ്റേതെങ്കിലും പേയ്‌മെന്റ് വിശദാംശങ്ങളോ നൽകേണ്ടതുണ്ടോ?",
  "no.requirement.payment.details":
    "നമ്പർ. ട്രയൽ എടുക്കുന്നതിന് പേയ്‌മെന്റ് വിശദാംശങ്ങളൊന്നും നൽകേണ്ട ആവശ്യമില്ല. നിങ്ങൾക്ക് സൈൻ അപ്പ് ചെയ്ത് പ്ലാറ്റ്ഫോം ഉപയോഗിക്കാൻ തുടങ്ങാം",
  "trial.over": "എന്റെ വിചാരണ കഴിഞ്ഞാൽ എന്ത് സംഭവിക്കും?",
  "continue.using.platform":
    "നിങ്ങൾക്ക് പ്ലാറ്റ്‌ഫോം ഉപയോഗിക്കുന്നത് തുടരണമെങ്കിൽ, കുറഞ്ഞത് 2,000 രൂപ ഉപയോഗിച്ച് നിങ്ങളുടെ ഇൻ-ബിൽറ്റ് വാലറ്റ് റീചാർജ് ചെയ്‌ത് പണം നൽകേണ്ടിവരും. ട്രയൽ കാലഹരണപ്പെട്ട് 15 ദിവസത്തിനുള്ളിൽ നിങ്ങളുടെ വാലറ്റ് റീചാർജ് ചെയ്തില്ലെങ്കിൽ, നിങ്ങളുടെ അക്കൗണ്ട് ശാശ്വതമായി ഇല്ലാതാക്കപ്പെടും.",
  "how.do.i.pay": "ഞാൻ എങ്ങനെ പണമടയ്ക്കും?",
  "account.wallet": "അക്കൗണ്ട് വാലറ്റിൽ",
  "charge.admin.panel":
    "പണം സംഭരിക്കാനും റീചാർജ് ചെയ്യാനും കഴിയും . ഞങ്ങളുടെ പേയ്‌മെന്റ് ഗേറ്റ്‌വേ എല്ലാ സ്റ്റാൻഡേർഡ് ക്രെഡിറ്റ് കാർഡുകൾ, ഡെബിറ്റ് കാർഡുകൾ, ബാങ്ക് കൈമാറ്റങ്ങൾ, UPI, ഇ-വാലറ്റുകൾ എന്നിവ സ്വീകരിക്കുന്നു.",
  "features.during.trial": "ട്രയൽ സമയത്ത് എനിക്ക് എന്ത് ഫീച്ചറുകൾ ലഭിക്കും?",
  "features.page": "സവിശേഷതകൾ പേജ്",
  "addon.features": "ആഡ്-ഓൺ സവിശേഷതകൾ എന്തൊക്കെയാണ്?",
  "addon.features.answer":
    "ആഡ്-ഓൺ ഫീച്ചറുകൾ വ്യക്തിഗത ബിസിനസുകൾക്ക് മാത്രമുള്ള പ്രവർത്തനങ്ങളാണ്, അത് എല്ലാവർക്കും ആവശ്യമായി വരില്ല. നിങ്ങൾക്ക് ഈ പ്രവർത്തനങ്ങളെല്ലാം കാണാനും നിങ്ങളുടെ ആവശ്യാനുസരണം ഓണാക്കാനും കഴിയും.",
  "addon.features.charge": "ആഡ്-ഓൺ ഫീച്ചറുകൾ ഈടാക്കുമോ?",
  "addon.features.charge.answer":
    "മിക്ക ആഡ്-ഓൺ ഫീച്ചറുകളും സൗജന്യമാണ്, അവ ഉപയോഗിക്കാൻ തുടങ്ങുന്നതിന് നിങ്ങൾ അവ പ്രവർത്തനക്ഷമമാക്കേണ്ടതുണ്ട്. പണമടച്ചുള്ള ആഡ്-ഓൺ ഫീച്ചറുകൾക്കായി, വ്യക്തിഗത ഫീച്ചറിന്റെ വിലനിർണ്ണയം അനുസരിച്ച് പേയ്‌മെന്റ് നിങ്ങളുടെ അക്കൗണ്ട് വാലറ്റിൽ നിന്ന് കുറയ്ക്കും.",
  "turnoff.addon.features":
    "എനിക്ക് എപ്പോൾ വേണമെങ്കിലും ആഡ്-ഓൺ ഫീച്ചറുകൾ ഓഫാക്കാൻ കഴിയുമോ?",
  "turnoff.addon.features.answer":
    "അതെ. എന്നാൽ ഈ ഫീച്ചറുകളുടെ അനുബന്ധ ഡാറ്റ നിങ്ങൾക്ക് ശാശ്വതമായി നഷ്‌ടപ്പെടാനിടയുള്ളതിനാൽ ഈ ഫീച്ചറുകൾ ഓഫാക്കുമ്പോൾ നിങ്ങൾ അതീവ ജാഗ്രത പാലിക്കേണ്ടതുണ്ട്.",
  "remind.balance.low":
    "എന്റെ അക്കൗണ്ട് വാലറ്റ് ബാലൻസ് കുറവാണെങ്കിൽ എന്നെ ഓർമ്മിപ്പിക്കുമോ?",
  "remind.balance.low.answer":
    "അതെ. നിങ്ങളുടെ അക്കൗണ്ട് ബാലൻസ് 2000 രൂപയിൽ താഴെയായാൽ ഞങ്ങൾ നിങ്ങൾക്ക് എസ്എംഎസിലൂടെയും ഇമെയിലിലൂടെയും ഒന്നിലധികം ഓർമ്മപ്പെടുത്തലുകൾ അയയ്ക്കും.",
  "negative.balance.wallet":
    "പ്രതിമാസ ചാർജുകൾ അടയ്ക്കാൻ എന്റെ അക്കൗണ്ട് വാലറ്റ് ബാലൻസ് പര്യാപ്തമല്ലെങ്കിൽ എന്ത് സംഭവിക്കും?",
  "negative.balance.reminder":
    "നിങ്ങളുടെ വാലറ്റിലെ അപര്യാപ്തമായ പണത്തെക്കുറിച്ച് ഇമെയിൽ, പുഷ് അറിയിപ്പുകൾ, SMS എന്നിവ പോലുള്ള ഒന്നിലധികം ചാനലുകളിലൂടെ ഞങ്ങൾ നിങ്ങളെ അറിയിക്കുകയും നിങ്ങളുടെ വാലറ്റ് റീചാർജ് ചെയ്യാൻ അഭ്യർത്ഥിക്കുകയും ചെയ്യുന്നു.",
  "negative.balance.case.one":
    "കേസ് 1: തുടർച്ചയായി 15 ദിവസത്തിൽ കൂടുതൽ വാലറ്റ് ബാലൻസ് നെഗറ്റീവ് ആണെങ്കിൽ, നിങ്ങളുടെ അക്കൗണ്ട് സ്വയമേവ പ്രവർത്തനരഹിതമാക്കും, അതായത് നിങ്ങളുടെ വെബ്‌സൈറ്റ് പ്രവർത്തനരഹിതമാകും, എന്നിരുന്നാലും നിങ്ങളുടെ അക്കൗണ്ട്- അഡ്‌മിനെ നിങ്ങൾക്ക് ആക്‌സസ് ചെയ്യാൻ കഴിയും. നിങ്ങളുടെ വാലറ്റ് റീചാർജ് ചെയ്‌ത് ബാലൻസ് പോസിറ്റീവാക്കുന്ന മുറയ്ക്ക്, നിങ്ങളുടെ വെബ്‌സൈറ്റ് വീണ്ടും സജീവമാകും.",
  "negative.balance.case.two":
    "കേസ് 2: തുടർച്ചയായി 60 ദിവസത്തിൽ കൂടുതൽ വാലറ്റ് ബാലൻസ് നെഗറ്റീവ് ആണെങ്കിൽ, അക്കൗണ്ട് സ്വയമേവ ഇല്ലാതാക്കപ്പെടും അതായത് നിങ്ങളുടെ വെബ്‌സൈറ്റും അഡ്മിൻ അക്കൗണ്ടും ഇല്ലാതാക്കപ്പെടും. നിങ്ങളുടെ എല്ലാ ഡാറ്റയും ശാശ്വതമായി നഷ്ടപ്പെടും.",
  "stop.using.platform":
    "എനിക്ക് നിങ്ങളുടെ പ്ലാറ്റ്ഫോം ഉപയോഗിക്കുന്നത് നിർത്തണമെങ്കിൽ, എനിക്ക് എന്റെ വാലറ്റ്-പണം തിരികെ ലഭിക്കുമോ?",
  "stop.using.platform.answer":
    "അതെ. ഏത് സമയത്തും നിങ്ങൾക്ക് ഞങ്ങളുടെ പ്ലാറ്റ്ഫോം ഉപയോഗിക്കുന്നത് നിർത്തണമെന്ന് ഞങ്ങളോട് ആശയവിനിമയം നടത്താം. പ്രവർത്തിക്കുന്ന മാസത്തെ വാടക പേയ്‌മെന്റും നാളിതുവരെയുള്ള കുടിശ്ശികയും കിഴിച്ചതിന് ശേഷം ഞങ്ങൾ നിങ്ങളുടെ വാലറ്റ് തുക റീഫണ്ട് ചെയ്യും.",
  install: "ഇൻസ്റ്റാൾ ചെയ്യുക",
  installing: "ഇൻസ്റ്റാൾ ചെയ്യുന്നു",
  uninstall: "അൺഇൻസ്റ്റാൾ ചെയ്യുക",
  uninstalling: "അൺഇൻസ്റ്റാൾ ചെയ്യുന്നു",
  installed: "ഇൻസ്റ്റാൾ ചെയ്തു",
  "registered.user.text": "നിങ്ങൾ ഇതിനകം രജിസ്റ്റർ ചെയ്തിട്ടുണ്ട്",
  "zopping.features": "സോപ്പിംഗ് സവിശേഷതകൾ",
  "feature.description":
    "സോപ്പിംഗിന് ചെയ്യാൻ കഴിയുന്ന എല്ലാ അത്ഭുതകരമായ കാര്യങ്ങളെയും കുറിച്ച് നന്നായി മനസ്സിലാക്കുക.",
  "login.or.text": "അഥവാ",
  "login.with.google": "Google ഉപയോഗിച്ച് ലോഗിൻ ചെയ്യുക",
  "signup.with.google": "Google ഉപയോഗിച്ച് സൈൻ അപ്പ് ചെയ്യുക",
  free: "സൗ ജന്യം",
  customizable: "ഇഷ്ടാനുസൃതമാക്കാവുന്നത്",
  standard: "സ്റ്റാൻഡേർഡ്",
  premium: "പ്രീമിയം",
  "stay.in.touch": "സമ്പർക്കം പുലർത്തുക",
  "about.us": "ഞങ്ങളേക്കുറിച്ച്",
  "company.text": "കമ്പനി",
  "blog.text": "ബ്ലോഗ്",
  "terms.conditions": "ഉപാധികളും നിബന്ധനകളും",
  "hidden.costs": "എന്തെങ്കിലും മറഞ്ഞിരിക്കുന്ന ചിലവുകൾ ഉണ്ടോ?",
  "hidden.costs.answer":
    "ഇല്ല. ഒന്നുമില്ല. എല്ലാ സവിശേഷതകളും തീമുകളും വിപുലീകരണങ്ങളും ആപ്പുകളും പൂർണ്ണമായും സൗജന്യമാണ്. എന്നിരുന്നാലും, നിങ്ങൾ നിങ്ങളുടെ സ്വന്തം ഡൊമെയ്ൻ നാമം തിരഞ്ഞെടുക്കുകയാണെങ്കിൽ, നിങ്ങളുടെ ഡൊമെയ്ൻ ദാതാവിന് പണം നൽകേണ്ടി വന്നേക്കാം. നിങ്ങളും ബന്ധപ്പെട്ട കക്ഷികളും തമ്മിൽ സമ്മതിച്ചിട്ടുള്ള ഡെലിവറി നിരക്കുകളും പേയ്‌മെന്റ് ഗേറ്റ്‌വേ നിരക്കുകളും ഞങ്ങളുടെ വിലനിർണ്ണയ പദ്ധതിയിൽ ഉൾപ്പെടുത്തിയിട്ടില്ല, അതിനാൽ നിങ്ങൾ വഹിക്കണം.",
  "cost.online.store": "എന്റെ ഓൺലൈൻ സ്റ്റോർ സജ്ജീകരിക്കുന്നതിന് എത്ര ചിലവാകും?",
  "cost.online.store.answer":
    "നിങ്ങളുടെ ഓൺലൈൻ സ്റ്റോർ സജ്ജീകരിക്കുന്നത് തികച്ചും സൗജന്യമാണ്. നിങ്ങൾ ഒരു മിനിമം രൂപയിൽ എത്തുന്നതുവരെ ഞങ്ങൾക്ക് ഒന്നും നൽകേണ്ടതില്ല. നിങ്ങളുടെ ഓൺലൈൻ സ്റ്റോർ വഴി പ്രതിമാസം 20000 രൂപയുടെ ഓർഡറുകൾ. അവിടെ നിന്ന്, മുകളിൽ സൂചിപ്പിച്ച വിലനിർണ്ണയ പ്ലാൻ അടിസ്ഥാനമാക്കിയാണ് ചാർജുകൾ. നിങ്ങളുടെ ഓൺലൈൻ സ്റ്റോർ വഴിയുള്ള നിങ്ങളുടെ പ്രതിമാസ വിൽപ്പന എല്ലാ മാസവും വ്യത്യാസപ്പെടുന്നതിനാൽ, നിങ്ങളുടെ പ്രതിമാസ നിരക്കുകളും.",
  "highlight.hosting":
    "ഹോസ്റ്റിംഗ് (സോപ്പിംഗ് സബ്ഡൊമെയ്ൻ) കൂടാതെ SSL സർട്ടിഫിക്കറ്റും",
  "highlight.themes": "പൂർണ്ണമായും ഇഷ്ടാനുസൃതമാക്കാവുന്ന തീമുകൾ",
  "highlight.product.listing": "പരിധിയില്ലാത്ത ഉൽപ്പന്ന ലിസ്റ്റിംഗുകൾ",
  "highlight.unlimited.users": "പരിധിയില്ലാത്ത ഉപയോക്താക്കൾ",
  "highlight.unlimited.operations":
    "പരിധിയില്ലാത്ത മൾട്ടി-സ്റ്റോർ പ്രവർത്തനങ്ങൾ",
  "highlight.apps": "കൺസ്യൂമർ ആപ്പ്, പിക്കർ ആപ്പ്, ഡെലിവറി ആപ്പ്, അഡ്മിൻ ആപ്പ്",
  "highlight.extensions": "എല്ലാ പ്ലഗിനുകളും/ വിപുലീകരണങ്ങളും",
  "highlight.gateway.integrations": "പേയ്‌മെന്റ് ഗേറ്റ്‌വേ സംയോജനം",
  "highlight.delivery.partner": "ഡെലിവറി പങ്കാളി സംയോജനങ്ങൾ",
  "highlight.social.media": "സോഷ്യൽ മീഡിയയും Google API സംയോജനവും",
  "highlight.customer.support": "24x7 ഉപഭോക്തൃ പിന്തുണ",
  "less.than": "അതിൽ കുറവ്",
  "email.error.msg": "സാധുവായ ഒരു ഇമെയിൽ വിലാസം നൽകുക. ഉദാ-abc@xyz.com",
  "anyone.can.sell.anything": "ഓൺലൈനിൽ ആർക്കും എന്തും വിൽക്കാം!",
  "brands.retailers.resellers":
    "ബ്രാൻഡുകൾ മുതൽ റീട്ടെയിലർമാർ, റീസെല്ലർമാർ, ഓൺലൈൻ-ആദ്യ സംരംഭകർ വരെ ആർക്കും സജ്ജീകരിക്കാനും പ്രവർത്തിപ്പിക്കാനും കഴിയും",
  "matter.few.minutes": "ഏതാനും മിനിറ്റുകൾക്കുള്ളിൽ",
  "setup.free.online.store": "നിങ്ങളുടെ സൗജന്യ ഓൺലൈൻ സ്റ്റോർ സജ്ജീകരിക്കുക",
  "schedule.demo": "ഒരു ഡെമോ ഷെഡ്യൂൾ ചെയ്യുക",
  "zero.web.dev.skills": "സീറോ വെബ് ഡെവലപ്‌മെന്റ് സ്‌കില്ലുകൾ ആവശ്യമാണ്",
  "payments.handle.deliveries":
    "പേയ്‌മെന്റുകൾ, ഡെലിവറികൾ കൈകാര്യം ചെയ്യുക, നിങ്ങളുടെ ബ്രാൻഡ് പ്രോത്സാഹിപ്പിക്കുക, ഞങ്ങളുടെ അവബോധജന്യവും ഇഷ്ടാനുസൃതമാക്കാവുന്നതുമായ ഇന്റർഫേസ് ഉപയോഗിച്ച് എളുപ്പത്തിൽ ഓൺലൈനിൽ വളരുക.",
  "your.store.customers.rules":
    "നിങ്ങളുടെ സ്റ്റോർ, നിങ്ങളുടെ ഉപഭോക്താക്കൾ, നിങ്ങളുടെ മാർജിനുകൾ, നിങ്ങളുടെ നിയമങ്ങൾ",
  "experience.online.marketplaces":
    "Amazon, Flipkart, Udaan, AliExpress, eBay മുതലായ ഓൺലൈൻ മാർക്കറ്റ്‌പ്ലേസുകളിലൂടെ വിൽപ്പന നടത്തുകയും നിങ്ങളുടെ എതിരാളികൾക്ക് നിങ്ങളുടെ കഠിനാധ്വാനം ചെയ്ത ഉപഭോക്താക്കളെ നഷ്‌ടപ്പെടുത്തുകയും ചെയ്‌ത അനുഭവത്തിൽ നിരാശയുണ്ടോ? നിങ്ങളുടെ സ്വന്തം ഓൺലൈൻ സ്റ്റോറും മൊബൈൽ ആപ്പും ഉപയോഗിച്ച്, നിങ്ങൾക്ക് നിങ്ങളുടെ ബ്രാൻഡും ഉപഭോക്തൃ അനുഭവവും നിയന്ത്രിക്കാനും കഠിനമായി സമ്പാദിച്ച മാർജിനുകളും ഉപഭോക്തൃ ഡാറ്റയും പൂർണ്ണമായും നിങ്ങളോടൊപ്പം സൂക്ഷിക്കാനും കഴിയും.",
  "explore.our.features": "ഞങ്ങളുടെ സവിശേഷതകൾ പര്യവേക്ഷണം ചെയ്യുക",
  "platform.specific.business":
    "നിങ്ങളുടെ ബിസിനസ്സിന്റെ പ്രത്യേക സ്വഭാവത്തിനായി നിർമ്മിച്ച ഒരു പ്ലാറ്റ്ഫോം",
  "handle.thousands.transactions":
    "ഒരു ദിവസം ആയിരക്കണക്കിന് ഇടപാടുകൾ തടസ്സമില്ലാതെ കൈകാര്യം ചെയ്യുകയും നിങ്ങളുടെ ബിസിനസ്സ് വർദ്ധിപ്പിക്കുകയും ചെയ്യുന്നു.",
  "explore.prices": "വിലകൾ പര്യവേക്ഷണം ചെയ്യുക",
  "start.online.store":
    "കുറച്ച് ക്ലിക്കുകളിലൂടെ നിങ്ങളുടെ ഓൺലൈൻ സ്റ്റോർ ആരംഭിക്കുക",
  "sell.groceries.medicines":
    "പലചരക്ക് സാധനങ്ങൾ, മരുന്നുകൾ, ഫർണിച്ചറുകൾ, പുസ്തകങ്ങൾ, ഇലക്ട്രോണിക്സ്, സൗന്ദര്യവർദ്ധക ഉൽപ്പന്നങ്ങൾ, ആഭരണങ്ങൾ, വസ്ത്രങ്ങൾ, കരകൗശല വസ്തുക്കൾ, ബേക്കറി ഉൽപ്പന്നങ്ങൾ അല്ലെങ്കിൽ എന്തും ഓൺലൈനിൽ വിൽക്കുക.",
  "store.information": "സ്റ്റോർ വിവരങ്ങൾ",
  "enter.store.name.logo": "നിങ്ങളുടെ സ്റ്റോറിന്റെ പേരും ലോഗോയും നൽകുക",
  "select.domain": "ഡൊമെയ്ൻ തിരഞ്ഞെടുക്കുക",
  "choose.store.url": "നിങ്ങളുടെ സ്റ്റോർ URL തിരഞ്ഞെടുക്കുക",
  "select.industry": "വ്യവസായം തിരഞ്ഞെടുക്കുക",
  "pick.business.operate": "നിങ്ങൾ പ്രവർത്തിക്കുന്ന ബിസിനസ്സ് തിരഞ്ഞെടുക്കുക",
  "select.theme": "തീം തിരഞ്ഞെടുക്കുക",
  "choose.theme.liking":
    "നിങ്ങൾക്ക് ഇഷ്ടമുള്ള തീം അല്ലെങ്കിൽ നിറങ്ങൾ തിരഞ്ഞെടുക്കുക",
  "download.now": "ഇപ്പോൾ ഡൗൺലോഡ് ചെയ്യുക",
  "unlock.ecommerce.power":
    "സോപ്പിംഗ് ആപ്പ് ഉപയോഗിച്ച് ഇ-കൊമേഴ്‌സിന്റെ ശക്തി അൺലോക്ക് ചെയ്യുക",
  "features.banner.one.description":
    "നിങ്ങളുടെ ഓൺലൈൻ സ്റ്റോർ സജ്ജീകരിക്കുന്നതും പ്രവർത്തിപ്പിക്കുന്നതും വളരെ എളുപ്പമാക്കി",
  "features.banner.two.description":
    "ഇൻസ്റ്റാൾ ചെയ്യാവുന്ന' വിപുലീകരണങ്ങളുടെ ഒരു ഹോസ്റ്റിലൂടെ വിപുലമായ ഇഷ്‌ടാനുസൃതമാക്കലുകൾ സാധ്യമാക്കി",
  "features.banner.three.description":
    "Zopping മൊബൈൽ ആപ്പ് വഴി എല്ലാ ഫീച്ചറുകളും ആക്‌സസ് ചെയ്യുക",
  hosting: "ഹോസ്റ്റിംഗ്",
  "hosting.desc":
    "സൈൻ അപ്പ് ചെയ്യുമ്പോൾ സൗജന്യ ഹോസ്റ്റിംഗിനൊപ്പം സൗജന്യ @zopping.com ഡൊമെയ്ൻ നേടുക. നിങ്ങൾക്ക് ഉടനടി അല്ലെങ്കിൽ പിന്നീടുള്ള തീയതിയിൽ നിങ്ങളുടെ സ്വന്തം ഡൊമെയ്‌നിലേക്ക് നീങ്ങാം.",
  "ssl.desc":
    "നിങ്ങളുടെ ഉപഭോക്താക്കൾക്ക് സുരക്ഷിതവും സുരക്ഷിതവുമായ ഷോപ്പിംഗ് അനുഭവം പ്രാപ്തമാക്കുന്നതിന് നിങ്ങളുടെ ഡൊമെയ്‌നിനായി ഞങ്ങൾ സൗജന്യ 256-ബിറ്റ് SSL സർട്ടിഫിക്കറ്റ് വാഗ്ദാനം ചെയ്യുന്നു.",
  "themes.desc":
    "നിങ്ങളുടെ ബിസിനസ്സിന്റെ സ്വഭാവവുമായി പൊരുത്തപ്പെടുന്ന ടെംപ്ലേറ്റുകളുടെ ഞങ്ങളുടെ ലൈബ്രറിയിൽ നിന്ന് തിരഞ്ഞെടുക്കുക. പേജ് ലേഔട്ടുകൾ, മെനുകൾ, നിറങ്ങൾ എന്നിവ ഇഷ്ടാനുസൃതമാക്കുക, ലോഗോകൾ, ഫാവിക്കോണുകൾ, സ്റ്റാറ്റിക് പേജുകൾ, ബ്ലോഗുകൾ എന്നിവ ചേർക്കുക, നിങ്ങളുടെ സ്റ്റോർ നിങ്ങളുടെ ബ്രാൻഡിനെ പ്രതിഫലിപ്പിക്കുന്നതാക്കുക. നിങ്ങളുടെ വെബ്‌സൈറ്റ് തകർക്കാതെ തീമുകൾക്കിടയിൽ എളുപ്പത്തിൽ മാറുക.",
  "search.box": "തിരയൽ ബോക്സ്",
  "search.box.desc":
    "ഞങ്ങളുടെ ബുദ്ധിപരവും വ്യക്തിഗതമാക്കിയതുമായ തിരയൽ പ്രവർത്തനം ഉപയോഗിച്ച് നിങ്ങളുടെ സ്റ്റോറിൽ നിന്നുള്ള ഉൽപ്പന്നങ്ങൾക്കായി തിരയാൻ നിങ്ങളുടെ ഉപഭോക്താക്കളെ പ്രാപ്തരാക്കുക. ഒരു പ്രത്യേക ഉൽപ്പന്നം/വിഭാഗം/ബ്രാൻഡ് പ്രമോട്ട് ചെയ്യുന്നതിനായി നിർദ്ദിഷ്ട തിരയൽ പദങ്ങൾക്കായി തിരയൽ ഫലങ്ങൾ തയ്യൽ ചെയ്ത് പരിഹരിക്കുക.",
  "multi.language": "ബഹുഭാഷ",
  "multi.language.desc":
    "നിങ്ങളുടെ eStore-ൽ നിങ്ങൾ പിന്തുണയ്‌ക്കാൻ ആഗ്രഹിക്കുന്ന ഭാഷകൾ സജ്ജീകരിക്കുകയും നിങ്ങളുടെ ഉപഭോക്താവിനെ അവർ ഇഷ്ടപ്പെടുന്ന ഭാഷയിൽ നിങ്ങളുടെ eStore-ൽ ബ്രൗസ് ചെയ്യാനും ഷോപ്പുചെയ്യാനും അനുവദിക്കുക.",
  "product.listing": "ഉൽപ്പന്ന ലിസ്‌റ്റിംഗ്",
  "product.listing.desc":
    "നിങ്ങളുടെ ഉൽപ്പന്നങ്ങളെ വിഭാഗങ്ങളിലേക്കും മൾട്ടി-ടയർ ഉപവിഭാഗങ്ങളിലേക്കും എളുപ്പത്തിൽ തരംതിരിക്കുക. അന്തർനിർമ്മിത ഇഷ്‌ടാനുസൃത ഫീൽഡുകൾ ഉപയോഗിച്ച് ചിത്രങ്ങൾ, വിവരണങ്ങൾ, ടാഗുകൾ, സ്റ്റോക്ക്, വിലകൾ, കിഴിവുകൾ, നികുതി നിരക്കുകൾ, സെസ് എന്നിവയും അതിലേറെയും ചേർക്കുക. നിങ്ങളുടെ ഉൽപ്പന്നങ്ങൾ വ്യക്തിഗതമായോ കൂട്ടമായോ സൗകര്യപ്രദമായി അപ്‌ലോഡ് ചെയ്യുകയോ എഡിറ്റ് ചെയ്യുകയോ ചെയ്യുക.",
  "sell.by.brand": "ബ്രാൻഡ് പ്രകാരം വിൽക്കുക",
  "sell.by.brand.desc":
    "ബ്രാൻഡ് നാമത്തെ അടിസ്ഥാനമാക്കി ഉൽപ്പന്നങ്ങൾ തിരയാനും ഫിൽട്ടർ ചെയ്യാനും നിങ്ങളുടെ ഉപഭോക്താക്കളെ പ്രാപ്തമാക്കുന്നതിന് കാറ്റലോഗിൽ നിങ്ങളുടെ ഉൽപ്പന്നങ്ങളിലേക്ക് ഒരു 'ബ്രാൻഡ്' ഫീൽഡ് ചേർക്കുക.",
  "items.sold.by.weight": "തൂക്കം അനുസരിച്ച് വിൽക്കുന്ന ഇനങ്ങൾ",
  "items.sold.by-weight.desc":
    "പഴങ്ങൾ, പച്ചക്കറികൾ, ധാന്യങ്ങൾ, മാംസം, ലോഹങ്ങൾ മുതലായവ പോലെയുള്ള അയഞ്ഞ വസ്തുക്കളും വിൽക്കുന്നവയും വിൽക്കാൻ തുടങ്ങുക.",
  "product.variants": "ഉൽപ്പന്ന വകഭേദങ്ങൾ",
  "product.variants.desc":
    "നിറം, വലിപ്പം, ഭാരം മുതലായവയിൽ വ്യത്യാസങ്ങളുള്ള ഉൽപ്പന്നങ്ങൾ പരിധികളില്ലാതെ കൈകാര്യം ചെയ്യുക. ഓരോ വേരിയന്റിനും ഫോട്ടോകളും വിലകളും കിഴിവുകളും അപ്‌ഡേറ്റ് ചെയ്യുക.",
  "product.subsitution": "ഉൽപ്പന്ന സബ്‌സ്റ്റിറ്റ്യൂഷൻ",
  "product.subsitution.desc":
    "നിങ്ങളുടെ ഉൽപ്പന്നങ്ങൾക്ക് പകരമുള്ളവ സജ്ജീകരിക്കുകയും യഥാർത്ഥത്തിൽ ഓർഡർ ചെയ്ത ഉൽപ്പന്നം ലഭ്യമല്ലാത്ത സാഹചര്യത്തിൽ പകരം ഉൽപ്പന്നങ്ങൾ അയച്ചുകൊണ്ട് നിങ്ങളുടെ ഫിൽ നിരക്ക് മെച്ചപ്പെടുത്തുകയും ചെയ്യുക.",
  "stock.management.desc":
    "സ്റ്റോക്ക് ഡാറ്റ അപ്‌ലോഡ് ചെയ്യുക അല്ലെങ്കിൽ ഡൗൺലോഡ് ചെയ്യുക, ബഫർ സ്റ്റോക്ക് സജ്ജീകരിക്കുക, ഏതെങ്കിലും ഉൽപ്പന്നം സ്റ്റോക്ക് തീരുമ്പോൾ അലേർട്ടുകൾ നേടുക.",
  "payment.gateway.3rd.party":
    "3 rd മുമ്പ് സജ്ജമാക്കിയിരിക്കുന്ന 3 ഒരു മുഴുവൻ ഹോസ്റ്റ് എടുത്തു മെച്ചം",
  "payment.gateway.3rd.party.desc":
    "പര്ത്യ് പേയ്മെന്റ് ഗേറ്റ്വേ ഇന്റഗ്രേഷൻ ആർ ഡി വേഗത്തിൽ പേയ്മെന്റ് സംവിധാനം സജ്ജമാക്കേണ്ടതുണ്ട് പാർട്ടി പെയ്മെന്റ് ഗേറ്റ്. നിങ്ങളുടെ വ്യാപാരി ഇടപാട് നിരക്കുകൾ ഒപ്റ്റിമൈസ് ചെയ്യുന്നതിന് ഒന്നിലധികം ഗേറ്റ്‌വേകളിലൂടെ നിങ്ങളുടെ പേയ്‌മെന്റ് റൂട്ടിംഗ് ബുദ്ധിപരമായി ഓട്ടോമേറ്റ് ചെയ്യുക.",
  "paypal.integration": "പേപാൽ സംയോജനം",
  "paypal.integration.desc":
    "ഞങ്ങളുടെ ഇൻ-ബിൽറ്റ് പേപാൽ സംയോജനത്തിലൂടെ അന്താരാഷ്ട്ര ഓർഡറുകളും പേയ്‌മെന്റുകളും സ്വീകരിക്കുക.",
  "your.ewallet": "നിങ്ങളുടെ ഇ വാലറ്റ്",
  "your.ewallet.desc":
    "നിങ്ങളുടെ ഉപഭോക്തൃ വിശ്വസ്തത മെച്ചപ്പെടുത്തുകയും ഉപഭോക്താക്കൾക്ക് നിങ്ങളുടെ ബ്രാൻഡഡ് ഇ വാലറ്റ് നൽകിക്കൊണ്ട് അവർക്ക് പ്രതിഫലം നൽകുകയും ചെയ്യുക. നിങ്ങളുടെ ഉപഭോക്താക്കൾക്ക് അവരുടെ വാലറ്റുകളിലേക്ക് പണം ചേർക്കാനും അവരുടെ ഭാവി വാങ്ങലുകളിൽ അത് ഉപയോഗിക്കാനും കഴിയും.",
  "gift.cards.desc":
    "നിങ്ങളുടെ സ്റ്റോറിൽ മാത്രം റിഡീം ചെയ്യാൻ കഴിയുന്ന ഇഷ്‌ടാനുസൃതമാക്കാവുന്ന ഇ-ഗിഫ്റ്റ് കാർഡുകൾ വിറ്റ് അവരുടെ പ്രിയപ്പെട്ടവർക്ക് സമ്മാനം നൽകാൻ നിങ്ങളുടെ ഉപഭോക്താക്കളെ പ്രാപ്തരാക്കുക.",
  cod: "ക്യാഷ് ഓൺ ഡെലിവറി (COD)",
  "cod.desc": "നിങ്ങളുടെ ഉപഭോക്താക്കൾക്ക് COD പ്രവർത്തനം വാഗ്ദാനം ചെയ്യുക.",
  "webpages.and.banners": "വെബ്‌പേജുകളും ബാനറുകളും",
  "webpages.and.banners.desc":
    "പുതിയ ഉൽപ്പന്ന ലോഞ്ചുകൾ, ഓഫറുകൾ, സീസണൽ, ഉത്സവകാല വിൽപ്പനകൾ മുതലായവ പ്രോത്സാഹിപ്പിക്കുന്നതിന് നിങ്ങളുടെ വെബ്‌സൈറ്റിന്റെ വിവിധ പേജുകൾക്കായി ഇഷ്‌ടാനുസൃത വെബ് പേജുകളും വെബ് ബാനറുകളും സൃഷ്‌ടിക്കുകയും ഷെഡ്യൂൾ ചെയ്യുകയും ചെയ്യുക",
  "offers.management": "ഓഫർ മാനേജ്മെന്റ്",
  "offers.management.desc":
    "ഉൽപ്പന്നങ്ങൾ/ വിഭാഗങ്ങൾ/ ബ്രാൻഡുകൾ/ ഉപഭോക്താവിന് 10+ ഓഫറുകൾ (ഫ്ലാറ്റ് ഓഫ്/ % ഓഫ്/ മിനിമം. പർച്ചേസ്/ കോമ്പോസ്/ വാങ്ങുക-ഒന്ന് നേടുക-ഒന്ന്/ % അധികമായി) സൃഷ്ടിക്കുക, ഇഷ്ടാനുസൃതമാക്കുക, പ്രവർത്തിപ്പിക്കുക, ട്രാക്ക് ചെയ്യുക സെഗ്മെന്റുകൾ. ഒരു ഓഫറിന്റെ പ്രയോഗക്ഷമതയ്ക്കായി നിങ്ങളുടെ പരിധികളും നിയമങ്ങളും സജ്ജമാക്കുക.",
  "campaign.management": "കാമ്പെയ്‌ൻ മാനേജ്‌മെന്റ്",
  "campaign.management.desc":
    "നിങ്ങളുടെ ഉപഭോക്താക്കൾക്കുള്ള ആപ്പ് അറിയിപ്പുകൾ, ഇമെയിലുകൾ, SMS എന്നിവയിലൂടെ പ്രമോഷണൽ കാമ്പെയ്‌നുകൾ സൃഷ്‌ടിക്കുക, ഷെഡ്യൂൾ ചെയ്യുക, പ്രവർത്തിപ്പിക്കുക, നിരീക്ഷിക്കുക. കൂപ്പണുകൾ വിതരണം ചെയ്യുക, ഉൽപ്പന്ന ലോഞ്ചുകൾ പ്രഖ്യാപിക്കുക, വിലക്കുറവ് അറിയിക്കുക തുടങ്ങിയവ. ടാർഗെറ്റുചെയ്‌ത കാമ്പെയ്‌നുകൾ പ്രവർത്തിപ്പിക്കുന്നതിന് ഇൻ-ബിൽറ്റ് കസ്റ്റമർ സെഗ്‌മെന്റേഷൻ ടൂൾ ഉപയോഗിക്കുക.",
  "coupons.desc":
    "നിങ്ങളുടെ ഉപഭോക്താക്കൾക്ക് ഉൽപ്പന്നം അല്ലെങ്കിൽ ഷിപ്പിംഗ് കിഴിവുകൾ വാഗ്ദാനം ചെയ്യുന്നതിനായി അദ്വിതീയ അല്ലെങ്കിൽ സാധാരണ കൂപ്പണുകൾ സൃഷ്ടിക്കുകയും വിതരണം ചെയ്യുകയും ചെയ്യുക. ഓർഡർ മൂല്യം/ പേയ്‌മെന്റ് ഓപ്ഷനുകൾ/ ഓർഡർ ദിവസം/ ഉപഭോക്തൃ വിഭാഗം/ സ്റ്റോർ എന്നിവയെ അടിസ്ഥാനമാക്കി കിഴിവ് പരിധികൾ സജ്ജീകരിക്കുകയും കൂപ്പണിന്റെ പ്രയോഗക്ഷമത നിയന്ത്രിക്കുകയും ചെയ്യുക. കൂപ്പണുകളുടെ വിതരണത്തിന്റെയും ഫലമായുണ്ടാകുന്ന വിൽപ്പനയുടെയും വിജയം അളക്കുകയും നിരീക്ഷിക്കുകയും ചെയ്യുക.",
  seo: "സെർച്ച് എഞ്ചിൻ ഒപ്റ്റിമൈസേഷൻ (SEO)",
  "seo.desc":
    "നിങ്ങളുടെ ഉപഭോക്താക്കൾ നിങ്ങളെയോ നിങ്ങളുടെ ഉൽപ്പന്നങ്ങളെയോ ഇന്റർനെറ്റിൽ തിരയുമ്പോൾ അവർ കണ്ടെത്തുക. നിങ്ങളുടെ പേജ് ശീർഷകങ്ങളും വിവരണങ്ങളും കീവേഡുകളും Google തിരയലുകളിൽ ഉയർന്നതായി ദൃശ്യമാക്കാൻ സജ്ജമാക്കുക.",
  "orders.dashboard": "ഓർഡറുകൾ ഡാഷ്‌ബോർഡ്",
  "orders.dashboard.desc":
    "ഓർഡർ നമ്പർ, ഉപഭോക്താവിന്റെ പേര്, ബന്ധപ്പെടാനുള്ള വിശദാംശങ്ങൾ, ഓർഡർ സമയം, ഓർഡർ നില, പേയ്‌മെന്റ് നില, ഓർഡർ മൂല്യം മുതലായവ പോലുള്ള പൂർണ്ണമായ വിശദാംശങ്ങളോടെ നിങ്ങളുടെ എല്ലാ ഓർഡറുകളും ഒരിടത്ത് കാണുന്നതിന് അവബോധജന്യവും നാവിഗേറ്റ് ചെയ്യാൻ എളുപ്പമുള്ളതുമായ ഡാഷ്‌ബോർഡ്.",
  "orders.alerts": "ഓർഡർ അലേർട്ടുകൾ",
  "orders.alerts.desc":
    "ഓർഡർ പ്ലേസ്‌മെന്റ്, ക്യാൻസലേഷൻ, ഡെലിവറി മുതലായ പ്രധാനപ്പെട്ട ഇവന്റുകളിൽ തൽക്ഷണ SMS/ പുഷ് അറിയിപ്പുകൾ ലഭിക്കുന്നതിലൂടെ നിങ്ങളുടെ ഉപഭോക്താക്കളുടെ ഓർഡറുകൾക്ക് മുകളിൽ തുടരുക.",
  "delivery.area.management": "ഡെലിവറി ഏരിയ മാനേജ്മെന്റ്",
  "delivery.area.management.desc":
    "പിൻകോഡുകൾ അല്ലെങ്കിൽ സ്റ്റോറിൽ നിന്നുള്ള ദൂരത്തെ അടിസ്ഥാനമാക്കി അല്ലെങ്കിൽ മാപ്പിൽ വരച്ചുകൊണ്ട് നിങ്ങൾക്ക് ഡെലിവറി ചെയ്യാനാകുന്ന മേഖലകൾ നിയന്ത്രിക്കുക.",
  "delivery.partner.integration": "ഡെലിവറി പാർട്ണർ ഇന്റഗ്രേഷൻ",
  "delivery.partner.integration.desc":
    "നിങ്ങളുടെ ഉപഭോക്തൃ ഓർഡറുകൾ സ്വന്തമായി ഡെലിവർ ചെയ്യുക അല്ലെങ്കിൽ നിങ്ങളുടെ നഗരത്തിനകത്തോ ഇന്ത്യയിലെവിടെയെങ്കിലുമോ ഷിപ്പ് ചെയ്യാൻ ഞങ്ങളുടെ ഡെലിവറി പങ്കാളികളുടെ പട്ടികയിൽ നിന്ന് തിരഞ്ഞെടുക്കുക.",
  "customer.details": "ഉപഭോക്തൃ വിശദാംശങ്ങൾ",
  "customer.details.desc":
    "എഡിറ്റ് ചെയ്യാനോ ഡൗൺലോഡ് ചെയ്യാനോ തിരയാനോ ഗ്രൂപ്പുചെയ്യാനോ നിങ്ങളുടെ എല്ലാ ഉപഭോക്തൃ ഡാറ്റയും അവരുടെ വാങ്ങൽ ചരിത്രവും ഒരിടത്ത് ലഭ്യമാണ്. നിങ്ങളുടെ നിലവിലുള്ള ഉപഭോക്തൃ ഡാറ്റ ഇമ്പോർട്ടുചെയ്‌ത് എല്ലാം ഒരിടത്ത് സൂക്ഷിക്കുക.",
  "live.chat": "തത്സമയ ചാറ്റ്",
  "live.chat.desc":
    "ഉപഭോക്താക്കൾ നിങ്ങളുടെ സ്റ്റോറിൽ ഷോപ്പിംഗ് നടത്തുമ്പോൾ തത്സമയ ചാറ്റ് വിജറ്റ് വഴി അവരുടെ ചോദ്യങ്ങൾ പരിഹരിക്കുക.",
  "order.returns": "ഓർഡർ റിട്ടേണുകൾ",
  "order.returns.desc":
    "ക്രമരഹിതമായി ഓർഡർ റിട്ടേണുകൾ നിയന്ത്രിക്കുക, നിങ്ങളുടെ ഇൻവെന്ററി സ്വയമേവ ക്രമീകരിക്കുകയും നിങ്ങളുടെ ഉപഭോക്താക്കൾക്ക് പണം തിരികെ നൽകുകയും ചെയ്യുക.",
  "multi.user.access": "മൾട്ടി-യൂസർ ആക്‌സസ്",
  "multi.user.access.desc":
    "നിങ്ങളുടെ സ്റ്റോർ മാനേജ് ചെയ്യാൻ ജീവനക്കാരെ അനുവദിക്കുക. റോളുകളും അനുമതികളും സജ്ജമാക്കുക. ഷിഫ്റ്റുകളും ഹാജർനിലയും നിയന്ത്രിക്കുക.",
  "multi.store": "മൾട്ടി-സ്റ്റോർ",
  "multi.store.desc":
    "ഒന്നിലധികം ഫിസിക്കൽ സ്റ്റോറുകൾ ഉണ്ടോ? വിലകൾ, ഓഫറുകൾ, ഡെലിവറി നിരക്കുകൾ എന്നിവയെ അടിസ്ഥാനമാക്കി നിയന്ത്രിക്കാൻ ഞങ്ങൾ നിങ്ങളെ അനുവദിക്കുന്നു",
  "reports.desc":
    "സ്റ്റാൻഡേർഡ് സെയിൽസ്, മാർക്കറ്റിംഗ്, ഓപ്പറേഷൻസ്, കസ്റ്റമർ, സ്റ്റോക്ക് റിപ്പോർട്ടുകൾ ഡൗൺലോഡ് ചെയ്യുക അല്ലെങ്കിൽ നിങ്ങളുടെ ഇഷ്ടാനുസൃത റിപ്പോർട്ട് സൃഷ്ടിക്കുക.",
  "analytics.desc":
    "നിങ്ങളുടെ ബിസിനസ്സിന്റെ ആരോഗ്യം ട്രാക്ക് ചെയ്യാൻ ഞങ്ങളുടെ ഡാഷ്ബോർഡ് ഉപയോഗിക്കുക. നിങ്ങളുടെ വിൽപ്പന, മാർക്കറ്റിംഗ് കാമ്പെയ്‌നുകൾ, പ്രവർത്തനങ്ങൾ, ഓർഡറുകൾ, ഉപഭോക്തൃ വളർച്ച, സ്റ്റോക്ക് എന്നിവ അർഥവത്തായ സ്ഥിതിവിവരക്കണക്കുകളും ബിസിനസ്സ് ബുദ്ധിയും നേടുന്നതിന് നിരീക്ഷിക്കുകയും താരതമ്യം ചെയ്യുകയും ചെയ്യുക.",
  "fb.analytics": "Facebook Analytics",
  "fb.analytics.desc":
    "നിങ്ങളുടെ Facebook പിക്‌സൽ ഐഡി ഉപയോഗിച്ച് നിങ്ങളുടെ Facebook പരസ്യങ്ങളുടെ പ്രകടനം എളുപ്പത്തിൽ സംയോജിപ്പിച്ച് ട്രാക്ക് ചെയ്യുക.",
  "google.analytics": "Google Analytics",
  "google.analytics.desc":
    "നിങ്ങളുടെ ഉപഭോക്തൃ ഏറ്റെടുക്കൽ ചാനലുകൾ, ജനസംഖ്യാശാസ്‌ത്രം, വരുമാനം, മറ്റ് സമ്പന്നമായ സ്ഥിതിവിവരക്കണക്കുകൾ എന്നിവ ട്രാക്കുചെയ്യുന്നതിന് നിങ്ങളുടെ eStore-മായി നിങ്ങളുടെ Google Analytics എളുപ്പത്തിൽ സംയോജിപ്പിക്കുക.",
  "customer.app": "ഉപഭോക്തൃ ആപ്പ്",
  "customer.app.desc":
    "നിങ്ങളുടെ സ്‌റ്റോറിനായി സൗജന്യ ഇഷ്‌ടാനുസൃതമാക്കിയതും ബ്രാൻഡ് ചെയ്‌തതുമായ iOS, Android ഉപഭോക്തൃ അപ്ലിക്കേഷൻ. നിങ്ങളുടെ ബ്രാൻഡ് പ്രതിഫലിപ്പിക്കുന്നതിന് നിങ്ങളുടെ ആപ്ലിക്കേഷന്റെ പേര്, ലോഞ്ച് ഐക്കൺ, സ്പ്ലാഷ് സ്ക്രീനുകൾ എന്നിവ സജ്ജമാക്കുക.",
  "delivery.app": "ഡെലിവറി ആപ്പ്",
  "delivery.app.desc":
    "നിങ്ങളുടെ ഡെലിവറി ജീവനക്കാർക്ക് ഓർഡറുകൾ തിരഞ്ഞെടുക്കുന്നതിനും മുൻഗണന നൽകുന്നതിനും ഡെലിവറി ചെയ്യുന്നതിനുമുള്ള ഒരു സൗജന്യ Android ആപ്പ്.",
  "picker.app": "പിക്കർ ആപ്പ്",
  "picker.app.desc":
    "ഓർഡറുകൾ തിരഞ്ഞെടുക്കാനും പാക്ക് ചെയ്യാനും പരിശോധിക്കാനും ലേബലുകൾ പ്രിന്റ് ചെയ്യാനും നിങ്ങളുടെ ജീവനക്കാർക്കുള്ള സൗജന്യ Android ആപ്പ്.",
  "admin.app": "അഡ്‌മിൻ ആപ്പ്",
  "admin.app.desc":
    "നിങ്ങളുടെ മൊബൈലിൽ നിങ്ങളുടെ ഓൺലൈൻ സ്റ്റോർ മാനേജ് ചെയ്യാനുള്ള സൗജന്യ iOS, Android ആപ്പ്. നിങ്ങളുടെ വിൽപ്പന ട്രാക്കുചെയ്യുക, ഓർഡറുകൾ അവലോകനം ചെയ്യുക, നിങ്ങളുടെ ഉൽപ്പന്നങ്ങൾ പരിഷ്‌ക്കരിക്കുക, മാർക്കറ്റിംഗ് കാമ്പെയ്‌നുകൾ നടത്തുക, എവിടെയായിരുന്നാലും നിങ്ങളുടെ സ്റ്റോർ ക്രമീകരണങ്ങൾ മാറ്റുക.",
  "custom.password.format": "ഉപഭോക്തൃ പാസ്‌വേഡ് ഫോർമാറ്റ് സജ്ജീകരണം",
  "custom.password.format.desc":
    "ഒരു പാസ്‌വേഡ് സൃഷ്ടിക്കുമ്പോൾ നിങ്ങളുടെ ഉപഭോക്താക്കൾ പാലിക്കേണ്ട ഇഷ്‌ടാനുസൃത നിയമങ്ങൾ സജ്ജമാക്കുക, അതുവഴി സുരക്ഷ മെച്ചപ്പെടുത്തുന്നു.",
  "data.security": "ഡാറ്റ സുരക്ഷ",
  "data.security.desc":
    "നിങ്ങളുടെ ഡാറ്റ നിങ്ങൾക്ക് മാത്രമേ ആക്‌സസ് ചെയ്യാനാകൂ.",
  "data.and.security": "ഡാറ്റയും സുരക്ഷയും",
  apps: "അപ്ലിക്കേഷനുകൾ",
  channels: "ചാനലുകൾ",
  "customer.management": "ഉപഭോക്തൃ മാനേജ്മെന്റ്",
  delivery: "ഡെലിവറി",
  marketing: "മാർക്കറ്റിംഗ്",
  cataloguing: "കാറ്റലോഗിംഗ്",
  "testimonials.ambros":
    "I was completely new to e-commerce and scared about the last-mile delivery operations. I couldn’t have asked for a better or easier solution than the Zopping e-commerce platform. The entire process from order placement on web/mobiles apps through last-mile delivery is easy and extremely efficient. A fantastic experience overall! Amazing solution for retailers at an affordable price from the Zopping team!",
  "testimonials.medinmin":
    "വെബ് & ആപ്പ് ഡെവലപ്‌മെന്റിനായി തിരയുന്ന എല്ലാവർക്കും ഇ-കോം പ്ലാറ്റ്‌ഫോം സോപ്പിംഗ് ചെയ്യാൻ ഞാൻ ശുപാർശ ചെയ്യുന്നു. നിങ്ങളുടെ ആവശ്യകതകൾ ഉടനടി നിറവേറ്റുന്ന പ്ലഗ്-ആൻഡ്-പ്ലേ പ്രവർത്തനങ്ങൾ അവയ്‌ക്കുണ്ട്. അവർ എന്റെ പ്രതീക്ഷകൾ നിറവേറ്റുക മാത്രമല്ല, അവയെ മറികടക്കുകയും ചെയ്തു. വെബ്, മൊബൈൽ ആപ്ലിക്കേഷനുകൾ വളരെ ആകർഷകമാണ് കൂടാതെ ഉപയോക്താക്കളുമായി മികച്ച ഇടപഴകലും വാഗ്ദാനം ചെയ്യുന്നു. അവരുടെ സേവനവും സോഫ്റ്റ്‌വെയറും പ്രൊഫഷണലിസവും എന്നെ ശരിക്കും ആകർഷിച്ചു.",
  "testimonials.blossom":
    "ഞങ്ങളുടെ പുസ്‌തക ബിസിനസ്സ് ഓൺലൈനായി എടുക്കുന്നതിന് ഒരു അപേക്ഷ ആവശ്യമായി വരുമ്പോൾ സോപ്പിംഗ് ഞങ്ങൾക്ക് ശരിയായ മാർഗ്ഗനിർദ്ദേശം വാഗ്ദാനം ചെയ്തു. ഞാൻ തീരുമാനിക്കുന്നതിന് മുമ്പ്, ഞങ്ങൾ അവരുടെ സെയിൽസ് ടീമുമായി ഒരു ചർച്ച നടത്തി, ഞങ്ങളുടെ ആശയം എങ്ങനെ ഒരു ഓൺലൈൻ ബുക്ക് സ്റ്റോറാക്കി മാറ്റാം എന്നതിനെക്കുറിച്ച് അത് ഞങ്ങളെ നയിച്ചു. പിന്നീട്, സജ്ജീകരണ പ്രക്രിയയിലുടനീളം ഓൺബോർഡിംഗ് ടീം ഞങ്ങളുമായി ബന്ധപ്പെടുകയും ഞങ്ങളുടെ ആവശ്യങ്ങൾക്കനുസരിച്ച് അവർ വെബ്‌സൈറ്റ് ഇഷ്‌ടാനുസൃതമാക്കുന്നുവെന്ന് ഉറപ്പാക്കുകയും ചെയ്തു. ഒരു ടൺ നന്ദി, സോപ്പിംഗ് ടീം!",
  "testimonials.name.ambros": "രോഹിത് കുമാർ",
  "testimonials.designation.ambros": "ഡയറക്ടർ",
  "testimonials.company.ambros": "ആംബ്രോസ് റീട്ടെയിൽ",
  "testimonials.name.medinmin": "ഇർഫാൻ ബി",
  "testimonials.designation.medinmin": "ഓപ്‌സ് മാനേജർ",
  "testimonials.company.medinmin": "മെഡിൻ മിനിറ്റ്സ്",
  "testimonials.name.blossom": "മായിഗൗഡ",
  "testimonials.designation.blossom": "മാനേജിംഗ് ഡയറക്ടർ",
  "testimonials.company.blossom": "ബ്ലോസം ബുക്ക് ഹൗസ്",
  Hyperlocal: "ഹൈപ്പർലോക്കൽ",
  "Hyperlocal.descroption":
    "സോപ്പിംഗ് നിങ്ങളുടെ സമീപസ്ഥലത്തോ പ്രദേശത്തോ വിൽക്കുന്നതിനും ഡിജിറ്റൽ മാർക്കറ്റ് പ്ലേസിൽ വിജയിക്കുന്നതിനും അനുയോജ്യമായ ഇ-കൊമേഴ്‌സ് പ്ലാറ്റ്‌ഫോം നൽകുന്നു. എന്തുകൊണ്ടാണ് സോപ്പിംഗ് മറ്റുള്ളവരിൽ നിന്ന് വേറിട്ടുനിൽക്കുന്നത്.",
  "Create.Your.Online.Store": "നിങ്ങളുടെ ഓൺലൈൻ സ്റ്റോർ സൃഷ്ടിക്കുക",
  "Best.suited.for.selling": "വിൽപ്പനയ്ക്ക് ഏറ്റവും അനുയോജ്യം",
  Grocery: "പലചരക്ക്",
  "Food.and.Beverage": "ഭക്ഷ്യ പാനീയം",
  Medicine: "മരുന്ന്",
  Bakery: "ബേക്കറി",
  Diary: "ഡയറി",
  Fish: "മത്സ്യം",
  "Embrace.Convenience": "സൗകര്യം സ്വീകരിക്കുക",
  "Embrace.Convenience.discription1":
    "സോപ്പിംഗ് സ്റ്റോർ പിക്കപ്പ്, ഡെലിവറി ഓപ്‌ഷനുകൾ വാഗ്ദാനം ചെയ്യുന്നു, നിങ്ങളുടെ ഉപഭോക്താക്കളുടെ മുൻഗണനകൾ നിറവേറ്റുന്നു..",
  "Embrace.Convenience.discription2":
    "നിങ്ങളുടെ കാറ്റലോഗ്, ഇൻവെന്ററി, ഓർഡറുകൾ, ഉപഭോക്തൃ ഡാറ്റ എന്നിവ സോപ്പിംഗിന്റെ ഓമ്‌നിചാനൽ പോയിന്റ് ഓഫ് സെയിൽ (POS) സിസ്റ്റവുമായി സമന്വയിപ്പിക്കുക..",
  "Flexible.Order.Management": "ഫ്ലെക്സിബിൾ ഓർഡർ മാനേജ്മെന്റ്",
  "Flexible.Order.Management.description1":
    "പിൻകോഡുകൾ/ഏരിയാ/അപ്പാർട്ട്‌മെന്റുകൾ പ്രകാരം നിങ്ങളുടെ സേവന മേഖലകൾ നിർവചിക്കുകയും നിങ്ങളുടെ പ്രാദേശിക ഉപഭോക്തൃ അടിത്തറ കാര്യക്ഷമമായി സേവിക്കുകയും ചെയ്യുക.",
  "Flexible.Order.Management.description2":
    "ഒന്നിലധികം ശാഖകളോ സ്റ്റോറുകളോ ഉള്ള ബിസിനസുകളെ സോപ്പിംഗ് പിന്തുണയ്ക്കുന്നു, പ്രവർത്തനങ്ങൾ കാര്യക്ഷമമാക്കുകയും വിശാലമായ ഉപഭോക്തൃ അടിത്തറയിൽ എത്തുകയും ചെയ്യുന്നു.",
  "Flexible.Order.Management.description3":
    "പ്രത്യേക പിക്കപ്പ് അല്ലെങ്കിൽ ഡെലിവറി സ്ലോട്ടുകൾ സർചാർജുകളോ ഡിസ്കൗണ്ടുകളോ ഉപയോഗിച്ച് പ്രോത്സാഹിപ്പിക്കുക.",
  "Flexible.Order.Management.description4":
    " ഓർഡറുകൾ കാര്യക്ഷമമായി കൈകാര്യം ചെയ്യുന്നതിനും പീക്ക് കാലയളവിലെ അമിതമായ ആവശ്യങ്ങൾ തടയുന്നതിനും പരിധികൾ സജ്ജമാക്കുക.",
  "Simplified.Delivery.Management": "ലളിതമായ ഡെലിവറി മാനേജ്മെന്റ്",
  "Simplified.Delivery.Management.description1":
    "സോപ്പിംഗ് ഡെലിവറി ആപ്പ് ഓർഡർ കൈകാര്യം ചെയ്യലിൽ വിപ്ലവം സൃഷ്ടിക്കുന്നു, വേഗത്തിലുള്ളതും വിശ്വസനീയവുമായ സേവനം ഉറപ്പാക്കുന്നു.",
  "Simplified.Delivery.Management.description2":
    "യാത്ര ചെയ്ത ദൂരത്തെ അടിസ്ഥാനമാക്കിയുള്ള വ്യത്യസ്ത ഡെലിവറി ഫീസ് ചെലവുകൾ വഹിക്കുമ്പോൾ ന്യായമായ വില ഉറപ്പാക്കുന്നു",
  "Delight.your.customers": "നിങ്ങളുടെ ഉപഭോക്താക്കളെ സന്തോഷിപ്പിക്കുക",
  "Delight.your.customers.description1":
    "നിങ്ങളുടെ വെബ്‌സൈറ്റിലും മൊബൈൽ ആപ്പിലും ഉടനീളം തടസ്സമില്ലാത്തതും സ്ഥിരതയുള്ളതുമായ ഷോപ്പിംഗ് അനുഭവം നൽകുക.",
  "Delight.your.customers.description2":
    "എവിടെനിന്നും എപ്പോൾ വേണമെങ്കിലും ഓർഡറുകൾ നൽകാനുള്ള സൗകര്യം വാഗ്ദാനം ചെയ്യുക.",
  "Zopping.Advantage": "സോപ്പിംഗ് പ്രയോജനം",
  "Zopping.Advantage.description1":
    "മത്സരത്തിൽ മുന്നിൽ നിൽക്കാൻ പതിവ് അപ്‌ഡേറ്റുകളും നിലവിലുള്ള മെച്ചപ്പെടുത്തലുകളും പ്രയോജനപ്പെടുത്തുക.",
  "Zopping.Advantage.description2":
    " നിങ്ങളുടെ ഇഷ്‌ടാനുസൃത-ബ്രാൻഡഡ് നേറ്റീവ് iOS, Android ആപ്പ് എന്നിവ ഒരു ദിവസത്തിൽ താഴെ സമയത്തിനുള്ളിൽ പ്രവർത്തനക്ഷമമാക്കുക.",
  "Zopping.Advantage.description3":
    "സ്ഥലം നിങ്ങളുടെ വെബ്‌സൈറ്റിലും മൊബൈൽ ആപ്പിലും സ്വയമേവ അപ്‌ഡേറ്റ് ചെയ്യുന്നു.",
  "Engage.your.customer.with.Push":
    "പുഷ് അറിയിപ്പുകൾ ഉപയോഗിച്ച് നിങ്ങളുടെ ഉപഭോക്താവിനെ ഇടപഴകുക",
  "Engage.your.customer.with.Push.description1":
    "വ്യക്തിഗത സന്ദേശങ്ങൾ, പ്രത്യേക ഓഫറുകൾ, പുതിയ വരവ് അറിയിപ്പുകൾ എന്നിവ ഉപയോഗിച്ച് വിൽപ്പന വർദ്ധിപ്പിക്കുകയും ഉപഭോക്താക്കളെ തത്സമയം ഇടപഴകുകയും ചെയ്യുക..",
};

export default messages;
