const messages = {
  "header.home": "హోమ్",
  "header.products": "ఉత్పత్తులు",
  "header.technology": "సాంకేతికం",
  "header.about.us": "మా గురించి",
  "header.careers": "కెరీర్లు",
  "header.contact.us": "మమ్మల్ని సంప్రదించండి",
  "header.language": "భాష",
  english: "ఆంగ్ల",
  hindi: "హిందీ",
  signin: "సైన్ ఇన్ చేయండి",
  "signing.in": "లోనికి ప్రవేశించు",
  signup: "చేరడం",
  home: "Home",
  features: "Features",
  pricing: "Pricing",
  contact: "మమ్మల్ని సంప్రదించండి",
  solutions: "పరిష్కారాలు",
  "contact.us": "మమ్మల్ని సంప్రదించండి",
  "products.smartstore.eazy": "స్మార్ట్‌స్టోర్ ఈజీ",
  "products.smartstore.enterprise": "స్మార్ట్‌స్టోర్ ఎంటర్‌ప్రైజ్",
  "products.smartproduct.locator": "స్మార్ట్ ఉత్పత్తి లొకేటర్",
  "products.smartpayment.gateway": "స్మార్ట్ చెల్లింపు గేట్‌వే",
  "products.smartpoint.sale": "స్మార్ట్ పాయింట్ ఆఫ్ సేల్",
  "products.grocery.express": "కిరాణా ఎక్స్‌ప్రెస్",
  "smartstore.eazy.heading": "కీ ఫీచర్లు",
  "smartstore.eazy.selling.online":
    "మీరు ఆన్‌లైన్‌లో విక్రయించడం ప్రారంభించాల్సిన అవసరం ఉంది",
  "smartstore.eazy.unique.store": "మీ ప్రత్యేకమైన ఆన్‌లైన్ స్టోర్‌ని పొందండి",
  "smartstore.eazy.feature.standout":
    "మీ ప్రత్యేక వెబ్‌సైట్‌ని సృష్టించడం ద్వారా గుంపులో ప్రత్యేకంగా నిలబడండి",
  "smartstore.eazy.feature.domain":
    "మీ స్వంత డొమైన్ పేరును ఉపయోగించండి మరియు మీ బ్రాండ్ విలువను పెంచుకోండి",
  "smartstore.eazy.feature.colors":
    "మీ బ్రాండ్‌ను ప్రతిబింబించే రంగులు మరియు థీమ్‌లను ఎంచుకోండి",
  "smartstore.eazy.feature.banners":
    "ఆకర్షణీయమైన బ్యానర్‌లను అప్‌లోడ్ చేయడం ద్వారా మీ వెబ్‌సైట్‌ను మెరుగుపరచండి",
  "smartstore.eazy.setup.shop": "మీ దుకాణాన్ని సులభంగా సెటప్ చేయండి",
  "smartstore.eazy.create.own":
    "మీ స్వంత దుకాణాన్ని సృష్టించండి - సాంకేతిక నైపుణ్యాలు అవసరం లేదు",
  "smartstore.eazy.feature.setup.online":
    "మీ ఆన్‌లైన్ షాప్‌ను సులభంగా సెటప్ చేయడానికి మా స్టెప్ బై స్టెప్ గైడ్‌ని ఉపయోగించండి",
  "smartstore.eazy.feature.text.boxes":
    "సాధారణ టెక్స్ట్ బాక్స్‌లు, డ్రాప్-డౌన్‌లు మరియు డ్రాగ్ & డ్రాప్ మెనులను ఉపయోగించి అన్ని మార్పులను చేయండి",
  "smartstore.eazy.feature.catalogue":
    "మీ కేటలాగ్‌ను సృష్టించండి, ధరలను సెట్ చేయండి, షిప్పింగ్ నియమాలను సెట్ చేయండి మరియు ఆన్‌లైన్‌లో అమ్మడం ప్రారంభించండి",
  "smartstore.eazy.feature.mobile": "తక్షణమే మొబైల్‌కి వెళ్లండి",
  "smartstore.eazy.additional.sales":
    "మొబైల్ పరికరాలలో విక్రయించడం ద్వారా అదనపు విక్రయాలను పొందండి",
  "smartstore.eazy.selling.mobile":
    "మొబైల్ పరికరాల్లో మీ వెబ్‌సైట్ మరింత అద్భుతంగా కనిపిస్తుంది కాబట్టి తక్షణమే మొబైల్‌లో విక్రయించడం ప్రారంభించండి",
  "smartstore.eazy.shopping.convenience":
    "పరికరాలలో షాపింగ్ కార్ట్ సాధారణంగా ఉంటుంది కాబట్టి షాపింగ్ సౌలభ్యాన్ని ప్రారంభించండి",
  "smartstore.eazy.international.app":
    "మీ స్వంత అంతర్జాతీయ నాణ్యత గల Android మరియు iOS యాప్‌ని పొందండి",
  "smartstore.eazy.manage.go": "ప్రయాణంలో దీన్ని నిర్వహించండి",
  "smartstore.eazy.easy.access":
    "మీ Android మొబైల్‌లో మీ షాప్-అడ్మిన్‌ని సులభంగా యాక్సెస్ చేయండి",
  "smartstore.eazy.update.prices": "ధరలు, స్టాక్ మరియు చిత్రాలను నవీకరించండి",
  "smartstore.eazy.important.alerts":
    "ఆర్డర్ ప్లేస్‌మెంట్, చెల్లింపు మరియు రద్దు వంటి ముఖ్యమైన ఈవెంట్‌లపై హెచ్చరికలను పొందండి",
  "smartstore.eazy.customer.reply":
    "ఏ ఆలస్యం లేకుండా కస్టమర్ ఇమెయిల్‌లు మరియు చాట్‌లకు ప్రత్యుత్తరం ఇవ్వండి",
  "smartstore.eazy.customer.reachout": "మీ కస్టమర్‌లను చేరుకోండి",
  "smartstore.eazy.powerful.seo":
    "మా శక్తివంతమైన SEOతో కొత్త కస్టమర్‌లను పొందండి",
  "smartstore.eazy.offers.coupons":
    "మీ కస్టమర్‌ల విభాగాన్ని లక్ష్యంగా చేసుకుని ఆఫర్‌లు మరియు కూపన్‌లను సృష్టించండి",
  "smartstore.eazy.marketing.campaigns":
    "నిర్దిష్ట కస్టమర్‌లకు SMS, ఇమెయిల్‌లు మరియు యాప్ నోటిఫికేషన్‌ల ద్వారా లక్ష్య మార్కెటింగ్ ప్రచారాలను అమలు చేయండి",
  "smartstore.eazy.manage.customers": "మీ కస్టమర్‌లను నిర్వహించండి",
  "smartstore.eazy.build.loyalty":
    "విశ్వసనీయతను పెంచుకోవడానికి మా శక్తివంతమైన CRMని ఉపయోగించండి",
  "smartstore.eazy.view.customers":
    "మీ కస్టమర్‌లందరిని వారి వివరణాత్మక కొనుగోలు చరిత్రతో ఒకే చోట వీక్షించండి",
  "smartstore.eazy.upload.details":
    "మీ ఆఫ్‌లైన్-కస్టమర్‌లకు అదే స్థాయి సేవను అందించడానికి వారి వివరాలను అప్‌లోడ్ చేయండి",
  "smartstore.eazy.add.edit.details":
    "మీ కస్టమర్‌ల చిరునామా, ఇమెయిల్ మరియు ఫోన్ నంబర్ వంటి వివరాలను జోడించండి లేదా సవరించండి",
  message404:
    "మీరు ఇంటర్నెట్‌ను విచ్ఛిన్నం చేయలేదు, కానీ మీరు వెతుకుతున్న దాన్ని మేము కనుగొనలేకపోయాము.",
  "go.home": "హోమ్‌పేజీకి వెళ్లండి",
  "zopping.pricing": "ధర ప్రణాళిక",
  "pay.for.use":
    "మీరు మా ద్వారా ఆన్‌లైన్‌లో విక్రయించే వాటికి మాత్రమే మాకు చెల్లించండి",
  "currency.selector": "కరెన్సీ సెలెక్టర్",
  "everything.free": "డెస్క్‌టాప్ & మొబైల్ వెబ్‌తో సహా అన్నీ ఉచితం",
  "android.app.customers": "కస్టమర్ల కోసం Android యాప్",
  "ios.app.customers": "వినియోగదారుల కోసం iOS యాప్",
  "picker.app.employees": "ఉద్యోగి కోసం పికర్ యాప్",
  "logistics.app.employees": "ఉద్యోగి కోసం లాజిస్టిక్ యాప్",
  "buy.now": "ఇప్పుడే కొనండి",
  "contact.us.support": "మద్దతు కోసం మమ్మల్ని సంప్రదించండి",
  "customization.integration":
    "అనుకూలీకరణ మరియు ఇంటిగ్రేషన్ కోసం మమ్మల్ని సంప్రదించండి",
  "cant.find.answer": "సమాధానం దొరకలేదా?",
  "pricing.faq": "ధర - తరచుగా అడిగే ప్రశ్నలు",
  "view.more": "మరిన్ని చూడండి",
  "view.less": "తక్కువ చూడండి",
  "more.than": "మించి",
  "month.sales": "నెల అమ్మకాలు",
  "upto.text": "వరకు",
  heroHeadingText: "మీ వ్యాపారాన్ని ఆన్‌లైన్‌లో పెంచుకోండి",
  heroParagraphText: "రిటైలర్ల కోసం అంతిమ E-కామర్స్ ప్లాట్‌ఫారమ్.",
  heroFormButtonText: "ఆన్‌లైన్‌లో ఉచితంగా అమ్మడం ప్రారంభించండి",
  heroFormInputPlaceholder: "ఇమెయిల్ చిరునామా లేదా ఫోన్ నంబర్",
  "email.two.days": "రెండు పని దినాలలో ఇమెయిల్ చేయండి",
  "email.instant.chat": "వీలైనంత త్వరగా ఇమెయిల్ & తక్షణ చాట్ చేయండి",
  "email.and.telephonic": "వీలైనంత త్వరగా ఇమెయిల్, తక్షణ చాట్, & టెలిఫోనిక్",
  "email.account.manager":
    "ఇమెయిల్, తక్షణ చాట్, టెలిఫోనిక్ & ఖాతా మేనేజర్ కేటాయించబడతాయి",
  testimonialsHeading: "మేము చేసే పనిని మా కస్టమర్‌లు ఇష్టపడతారు",
  testimonialsSubHeading:
    "మా కస్టమర్‌లు మా సేవలతో మాకు చిరునవ్వుల సమూహాన్ని పంపుతారు మరియు మేము వాటిని ప్రేమిస్తాము",
  "select.language.heading": "భాషను ఎంచుకోండి",
  "my.account": "నా ఖాతా",
  "logout.text": "లాగ్అవుట్",
  "privacy.policy": "గోప్యతా విధానం",
  "pricing.comparison.heading": "మమ్మల్ని ఎందుకు ఎంచుకోవాలి?",
  "pricing.comparison.subheading": "ఇతరులతో మా ఫీచర్ పోలికను చూడండి",
  "compare.with.others": "ఇతరులతో పోల్చండి",
  customDomain: "అనుకూల డొమైన్",
  onlineStore: "ఆన్‌లైన్ స్టోర్",
  catalogue: "జాబితా",
  payments: "చెల్లింపులు",
  marketingOffers: "మార్కెటింగ్ & ఆఫర్‌లు",
  orderManagement: "ఆర్డర్ నిర్వహణ",
  ratingsReviews: "రేటింగ్‌లు & సమీక్షలు",
  support: "ఇమెయిల్‌లు, చాట్‌లు & ఫోన్ కాల్‌ల ద్వారా మద్దతు",
  salesChannels: "విక్రయ ఛానెల్‌లు",
  orderCreation: "మాన్యువల్ ఆర్డర్ సృష్టి",
  discounts: "ఉత్పత్తి తగ్గింపు & కూపన్ తగ్గింపులు",
  ssl: "SSL సర్టిఫికేట్",
  cart: "వదిలేసిన బండి",
  giftCards: "గిఫ్ట్ కార్డ్‌లు",
  reportsAnalytics: "నివేదికలు & విశ్లేషణలు",
  reportAnalytics: "నివేదికలు & విశ్లేషణలు",
  yes: "అవును",
  no: "నం",
  limited: "పరిమితం చేయబడింది",
  unlimited: "అపరిమిత",
  hostingSsl: "హోస్టింగ్ మరియు SSL ప్రమాణపత్రం",
  themes: "థీమ్‌లు",
  customerSignup: "కస్టమర్ సైన్అప్ పేజీ",
  multiStore: "బహుళ దుకాణాలు",
  multiSales: "బహుళ సేల్స్ ఛానెల్",
  products: "ఉత్పత్తులు",
  collectionTags: "సేకరణలు & ట్యాగ్‌లు",
  weightBasedPrices: "బరువు ఆధారిత ధరలు",
  productSubstitution: "ఉత్పత్తి ప్రత్యామ్నాయాలు",
  stockManagement: "స్టాక్ మేనేజ్‌మెంట్",
  rackManagement: "ర్యాక్ నిర్వహణ",
  gatewayInteg: "గేట్‌వే ఇంటిగ్రేషన్",
  walletCashback: "వాలెట్‌లు మరియు క్యాష్‌బ్యాక్‌లు",
  cashOnDelivery: "వస్తువులు అందిన తరువాత నగదు చెల్లించడం",
  voucherGifts: "వోచర్‌లు/గిఫ్ట్ కార్డ్‌లు",
  campaigns: "ప్రచారాలు (ఇమెయిల్/ SMS)",
  notifications: "అనువర్తనాల ప్రకటనలు",
  customSeo: "కస్టమ్ SEO",
  coupons: "కూపన్‌లు",
  offers: "ఉత్పత్తులు/ సేకరణలు/ ట్యాగ్‌లపై ఆఫర్‌లు",
  manualOrder: "మాన్యువల్ ఆర్డర్ సృష్టి",
  pickerApp: "పికర్ మొబైల్ యాప్",
  intelligentPicking: "తెలివైన పికింగ్ క్యూ",
  deliveryManagement: "డెలివరీ నిర్వహణ",
  surcharge: "సర్‌ఛార్జ్",
  slotCharges: "స్లాట్ ఆధారిత ఛార్జీలు",
  vehiclePlanning: "వాహన ప్రణాళిక",
  routePlanning: "రూట్ ప్లానింగ్",
  deliveryPersonTracking: "డెలివరీ పర్సన్ ట్రాకింగ్",
  thirdPartyLogistics: "థర్డ్-పార్టీ లాజిస్టిక్స్ ఇంటిగ్రేషన్",
  staffManagement: "సిబ్బంది నిర్వహణ",
  multiUsers: "బహుళ-వినియోగదారులు",
  employeeMobileApp: "ఉద్యోగి మొబైల్ యాప్",
  attendanceSummary: "హాజరు సారాంశం",
  roleBasedPermissions: "పాత్ర-ఆధారిత అనుమతులు",
  reports: "నివేదికలు",
  analytics: "విశ్లేషణలు",
  monthlyFixedCharges: "నెలవారీ స్థిర ఛార్జీలు",
  commissions: "కమీషన్లు",
  freePlugins: "ఉచిత ప్లగిన్లు",
  customerMobileApp: "కస్టమర్ మొబైల్ యాప్ (iOS మరియు Android)",
  "contact.help.banner.question": "మేము మీకు ఎలా సహాయం చేయగలము?",
  "contact.help.banner.tagline":
    "మీ సంప్రదింపు వివరాలను దిగువన ఉంచండి మరియు మా ఇ-కామర్స్ నిపుణుడు వీలైనంత త్వరగా మిమ్మల్ని సంప్రదిస్తారు",
  "contact.placeholder.fullname": "పూర్తి పేరు",
  "contact.placeholder.phone": "ఫోను నంబరు",
  "contact.placeholder.email": "ఇమెయిల్ చిరునామా",
  "contact.placeholder.message": "సందేశం",
  "contact.reachout": "త్వరిత ప్రతిస్పందన కోసం మమ్మల్ని సంప్రదించండి",
  "contact.reachout.question": "ప్రశ్న ఉందా? మమ్మల్ని కలుస్తూ ఉండండి",
  "contact.chat": "మాతో చాట్ చేయండి",
  "map.message": "మేము ఇక్కడే ఉన్నాము",
  "map.viewmap": "మ్యాప్‌లో చూడండి",
  "zopping.welcome": "జోపింగ్‌కు స్వాగతం",
  "zopping.loginForm.subheading": "దిగువన మీ వివరాలను నమోదు చేయండి",
  "loginForm.username.placeholder":
    "మీ ఇమెయిల్ లేదా ఫోన్ నంబర్‌ను నమోదు చేయండి",
  "feature.one.description":
    "ఉచితంగా ప్రారంభించండి మరియు మీరు ఉపయోగించే వాటికి మాత్రమే చెల్లించండి",
  "feature.two.description":
    "అన్ని ఫీచర్లు మరియు యాప్‌లు ఎటువంటి అదనపు ఖర్చు లేకుండా పూర్తిగా అన్‌లాక్ చేయబడ్డాయి",
  "feature.three.description":
    "మీ నెలవారీ ఆన్‌లైన్ విక్రయాలను అనుసరించే వేరియబుల్ నెలవారీ ఛార్జ్",
  "highlight.heading": "మీరు ఏమి పొందుతారు",
  "monthly.store.sales": "నెలవారీ ఆన్‌లైన్ స్టోర్ విక్రయాలు",
  "charges.per.month": "నెలకు ఛార్జీలు",
  "setup.store.free": "మీ స్టోర్‌ని ఉచితంగా సెటప్ చేయండి!",
  password: "పాస్వర్డ్",
  "loginForm.password.placeholder": "మీ పాస్వర్డ్ ని నమోదుచేయండి",
  "remember.me": "నన్ను గుర్తు పెట్టుకో",
  "dont.have.account": "ఖాతా లేదు",
  "create.account": "ఒక ఎకౌంటు సృష్టించు",
  name: "పేరు",
  "enter.name": "మీ పేరు రాయుము, మీ పేరు రాయండి",
  "create.your.account": "మీ ఖాతాను సృష్టించండి",
  "creating.account": "మీ ఖాతాను సృష్టిస్తోంది",
  "already.have.account": "ఖాతా కలిగి ఉన్నారా",
  "forgot.password": "పాస్‌వర్డ్ మర్చిపోయారా?",
  "submit.text": "సమర్పించండి",
  submitting: "సమర్పిస్తోంది",
  "resetPassword.successMessage": "మీ పాస్‌వర్డ్ విజయవంతంగా రీసెట్ చేయబడింది",
  "resetPassword.loginLinkText": "లాగిన్‌కి వెళ్లండి",
  "can.try.zopping": "నేను చెల్లించకుండా Zopping ప్రయత్నించవచ్చా?",
  "sign.up.free.trial":
    "అవును. కేవలం సైన్ అప్ చేసి, ప్లాట్‌ఫారమ్ యొక్క 15 రోజుల ఉచిత ట్రయల్‌ని పొందండి.",
  "enter.credit.card":
    "ట్రయల్ తీసుకోవడానికి నేను నా క్రెడిట్ కార్డ్ నంబర్ లేదా ఏదైనా ఇతర చెల్లింపు వివరాలను నమోదు చేయాలా?",
  "no.requirement.payment.details":
    "లేదు. ట్రయల్ తీసుకోవడానికి ఎటువంటి చెల్లింపు వివరాలను నమోదు చేయవలసిన అవసరం లేదు. మీరు సైన్-అప్ చేసి ప్లాట్‌ఫారమ్‌ని ఉపయోగించడం ప్రారంభించవచ్చు",
  "trial.over": "నా విచారణ ముగిసిన తర్వాత ఏమి జరుగుతుంది?",
  "continue.using.platform":
    "మీరు ప్లాట్‌ఫారమ్‌ను ఉపయోగించడం కొనసాగించాలనుకుంటే, మీరు మీ ఇన్‌బిల్ట్ వాలెట్‌ను కనీసం రూ.2,000తో రీఛార్జ్ చేయడం ద్వారా చెల్లించాలి. ట్రయల్ గడువు ముగిసిన 15 రోజులలోపు మీరు మీ వాలెట్‌ని రీఛార్జ్ చేయకపోతే, మీ ఖాతా శాశ్వతంగా తొలగించబడుతుంది.",
  "how.do.i.pay": "నేను ఎలా చెల్లించగలను?",
  "account.wallet": "ఖాతా వాలెట్‌లో",
  "charge.admin.panel":
    "డబ్బును నిల్వ చేయవచ్చు మరియు రీఛార్జ్ చేయవచ్చు . మా చెల్లింపు గేట్‌వే అన్ని ప్రామాణిక క్రెడిట్ కార్డ్‌లు, డెబిట్ కార్డ్‌లు, బ్యాంక్ బదిలీలు, UPI మరియు ఇ-వాలెట్‌లను అంగీకరిస్తుంది.",
  "features.during.trial": "ట్రయల్ సమయంలో నేను ఏ ఫీచర్లను పొందగలను?",
  "features.page": "ఫీచర్స్ పేజీ",
  "addon.features": "యాడ్-ఆన్ ఫీచర్లు ఏమిటి?",
  "addon.features.answer":
    "యాడ్-ఆన్ ఫీచర్‌లు అనేది వ్యక్తిగత వ్యాపారాలకు ప్రత్యేకమైన కార్యాచరణలు మరియు ప్రతి ఒక్కరికీ అవసరం ఉండకపోవచ్చు. మీరు ఈ కార్యాచరణలన్నింటినీ వీక్షించవచ్చు మరియు మీ అవసరానికి అనుగుణంగా ఆన్ చేయవచ్చు.",
  "addon.features.charge": "యాడ్-ఆన్ ఫీచర్లు ఛార్జ్ చేయబడతాయా?",
  "addon.features.charge.answer":
    "చాలా యాడ్-ఆన్ ఫీచర్‌లు ఉచితం మరియు వాటిని ఉపయోగించడం ప్రారంభించడానికి మీరు వాటిని ప్రారంభించాలి. చెల్లింపు యాడ్-ఆన్ ఫీచర్‌ల కోసం, వ్యక్తిగత ఫీచర్ యొక్క ధర ప్రకారం చెల్లింపు మీ ఖాతా వాలెట్ నుండి తీసివేయబడుతుంది.",
  "turnoff.addon.features": "నేను యాడ్-ఆన్ ఫీచర్‌లను ఎప్పుడైనా ఆఫ్ చేయవచ్చా?",
  "turnoff.addon.features.answer":
    "అవును. కానీ మీరు ఈ ఫీచర్‌ల సంబంధిత డేటాను శాశ్వతంగా కోల్పోయే అవకాశం ఉన్నందున ఈ ఫీచర్‌లను ఆఫ్ చేసేటప్పుడు చాలా జాగ్రత్తగా ఉండాలి.",
  "remind.balance.low":
    "నా ఖాతా వాలెట్ బ్యాలెన్స్ తక్కువగా ఉంటే మీరు నాకు గుర్తు చేస్తారా?",
  "remind.balance.low.answer":
    "అవును. మీ ఖాతా బ్యాలెన్స్ రూ.2000 కంటే తక్కువగా ఉంటే మేము మీకు SMS మరియు ఇమెయిల్ ద్వారా బహుళ రిమైండర్‌లను పంపుతాము.",
  "negative.balance.wallet":
    "నెలవారీ ఛార్జీలు చెల్లించడానికి నా ఖాతా వాలెట్ బ్యాలెన్స్ సరిపోనప్పుడు ఏమి జరుగుతుంది?",
  "negative.balance.reminder":
    "మీ వాలెట్‌లో తగినంత నిధులు లేవని మేము ఇమెయిల్, పుష్ నోటిఫికేషన్‌లు మరియు SMS వంటి బహుళ ఛానెల్‌ల ద్వారా మిమ్మల్ని హెచ్చరిస్తాము మరియు మీ వాలెట్‌ని రీఛార్జ్ చేయమని అభ్యర్థిస్తున్నాము.",
  "negative.balance.case.one":
    "కేస్ 1: వాలెట్ బ్యాలెన్స్ నిరంతరం 15 రోజులకు పైగా ప్రతికూలంగా ఉంటే, మీ ఖాతా స్వయంచాలకంగా నిలిపివేయబడుతుంది అంటే మీ వెబ్‌సైట్ డౌన్ అవుతుంది, అయినప్పటికీ మీరు మీ ఖాతా-అడ్మిన్‌ను యాక్సెస్ చేయగలరు. మీరు మీ వాలెట్‌ని రీఛార్జ్ చేసి, బ్యాలెన్స్‌ను పాజిటివ్‌గా చేసుకున్న వెంటనే, మీ వెబ్‌సైట్ మళ్లీ ప్రత్యక్ష ప్రసారం చేయబడుతుంది.",
  "negative.balance.case.two":
    "కేస్ 2: వాలెట్ బ్యాలెన్స్ 60 రోజుల కంటే ఎక్కువ కాలం పాటు ప్రతికూలంగా ఉంటే, ఖాతా ఆటోమేటిక్‌గా తొలగించబడుతుంది అంటే మీ వెబ్‌సైట్ మరియు అడ్మిన్ ఖాతా రెండూ తొలగించబడతాయి. మీ డేటా మొత్తం శాశ్వతంగా పోతుంది.",
  "stop.using.platform":
    "నేను మీ ప్లాట్‌ఫారమ్‌ని ఉపయోగించడం ఆపివేయాలనుకుంటే, నేను నా వాలెట్ డబ్బును తిరిగి పొందగలనా?",
  "stop.using.platform.answer":
    "అవును. మీరు మా ప్లాట్‌ఫారమ్‌ని ఉపయోగించడం ఆపివేయాలనుకుంటున్నారని మీరు ఎప్పుడైనా మాకు తెలియజేయవచ్చు. అమలులో ఉన్న నెల అద్దె చెల్లింపు మరియు ఇప్పటి వరకు బకాయిలు తీసివేసిన తర్వాత మేము మీ వాలెట్ మొత్తాన్ని తిరిగి చెల్లిస్తాము.",
  install: "ఇన్‌స్టాల్ చేయండి",
  installing: "ఇన్‌స్టాల్ చేస్తోంది",
  uninstall: "అన్‌ఇన్‌స్టాల్ చేయండి",
  uninstalling: "అన్‌ఇన్‌స్టాల్ చేస్తోంది",
  installed: "ఇన్‌స్టాల్ చేయబడింది",
  "registered.user.text": "మీరు ఇదివరకే నమోదు అయ్యారు",
  "zopping.features": "జోపింగ్ ఫీచర్లు",
  "feature.description":
    "Zopping చేయగల అన్ని అద్భుతమైన విషయాల గురించి మెరుగైన అవగాహన పొందండి.",
  "login.or.text": "లేదా",
  "login.with.google": "Googleతో లాగిన్ చేయండి",
  "signup.with.google": "Googleతో సైన్ అప్ చేయండి",
  free: "ఉచిత",
  customizable: "అనుకూలీకరించదగినది",
  standard: "ప్రామాణికం",
  premium: "ప్రీమియం",
  "stay.in.touch": "అందుబాటులో ఉండు",
  "about.us": "మా గురించి",
  "company.text": "కంపెనీ",
  "blog.text": "బ్లాగ్",
  "terms.conditions": "నిబంధనలు మరియు షరతులు",
  "hidden.costs": "దాచిన ఖర్చులు ఏమైనా ఉన్నాయా?",
  "hidden.costs.answer":
    "లేదు. ఏవీ లేవు. అన్ని ఫీచర్లు, థీమ్‌లు, పొడిగింపులు మరియు యాప్‌లు పూర్తిగా ఉచితం. అయితే, మీరు మీ స్వంత డొమైన్ పేరును ఎంచుకుంటే, మీరు మీ డొమైన్ ప్రొవైడర్‌కు చెల్లించాల్సి రావచ్చు. మీరు మరియు సంబంధిత పక్షాల మధ్య అంగీకరించిన విధంగా డెలివరీ ఛార్జీలు మరియు చెల్లింపు గేట్‌వే ఛార్జీలు మా ధర ప్రణాళికలో చేర్చబడలేదు మరియు అందువల్ల మీరు భరించవలసి ఉంటుంది.",
  "cost.online.store": "నా ఆన్‌లైన్ స్టోర్‌ని సెటప్ చేయడానికి ఎంత ఖర్చవుతుంది?",
  "cost.online.store.answer":
    "మీ ఆన్‌లైన్ స్టోర్‌ని సెటప్ చేయడం పూర్తిగా ఉచితం. మీరు కనీసం రూ. మీ ఆన్‌లైన్ స్టోర్ ద్వారా నెలకు 20000 విలువైన ఆర్డర్‌లు. అక్కడ నుండి, ఛార్జీలు పైన పేర్కొన్న ధర ప్రణాళిక ఆధారంగా ఉంటాయి. మీ ఆన్‌లైన్ స్టోర్ ద్వారా మీ నెలవారీ అమ్మకాలు ప్రతి నెల మారుతూ ఉంటాయి కాబట్టి, మీ నెలవారీ ఛార్జీలు కూడా మారుతూ ఉంటాయి.",
  "highlight.hosting": "హోస్టింగ్ (జోపింగ్ సబ్‌డొమైన్) మరియు SSL ప్రమాణపత్రం",
  "highlight.themes": "పూర్తిగా అనుకూలీకరించదగిన థీమ్‌లు",
  "highlight.product.listing": "అపరిమిత ఉత్పత్తి జాబితాలు",
  "highlight.unlimited.users": "అపరిమిత వినియోగదారులు",
  "highlight.unlimited.operations": "అపరిమిత బహుళ-స్టోర్ కార్యకలాపాలు",
  "highlight.apps":
    "వినియోగదారు యాప్, పికర్ యాప్, డెలివరీ యాప్ మరియు అడ్మిన్ యాప్",
  "highlight.extensions": "అన్ని ప్లగిన్‌లు/ పొడిగింపులు",
  "highlight.gateway.integrations": "చెల్లింపు గేట్‌వే ఇంటిగ్రేషన్‌లు",
  "highlight.delivery.partner": "డెలివరీ భాగస్వామి ఏకీకరణలు",
  "highlight.social.media": "సోషల్ మీడియా మరియు Google API ఇంటిగ్రేషన్‌లు",
  "highlight.customer.support": "24x7 కస్టమర్ మద్దతు",
  "less.than": "కంటే తక్కువ",
  "email.error.msg":
    "దయచేసి చెల్లుబాటు అయ్యే ఇమెయిల్ చిరునామాను నమోదు చేయండి. ఉదా-abc@xyz.com",
  "anyone.can.sell.anything": "ఎవరైనా ఆన్‌లైన్‌లో ఏదైనా అమ్మవచ్చు!",
  "brands.retailers.resellers":
    "బ్రాండ్‌ల నుండి రిటైలర్‌ల నుండి పునఃవిక్రేత వరకు ఆన్‌లైన్-మొదటి వ్యవస్థాపకులు ఎవరైనా సెటప్ చేయవచ్చు మరియు అమలు చేయవచ్చు",
  "brands.Convenience": "సౌలభ్యాన్ని స్వీకరించండి",
  "matter.few.minutes": "కొన్ని నిమిషాల వ్యవధిలో",
  "setup.free.online.store": "మీ ఉచిత ఆన్‌లైన్ స్టోర్‌ని సెటప్ చేయండి",
  "schedule.demo": "డెమోని షెడ్యూల్ చేయండి",
  "zero.web.dev.skills": "జీరో వెబ్ డెవలప్‌మెంట్ స్కిల్స్ అవసరం",
  "payments.handle.deliveries":
    "చెల్లింపులు, డెలివరీలను నిర్వహించడం, మీ బ్రాండ్‌ను ప్రచారం చేయడం మరియు మా సహజమైన మరియు అనుకూలీకరించదగిన ఇంటర్‌ఫేస్‌ని ఉపయోగించి సులభంగా ఆన్‌లైన్‌లో వృద్ధి చెందడం.",
  "your.store.customers.rules":
    "మీ స్టోర్, మీ కస్టమర్‌లు, మీ మార్జిన్‌లు, మీ నియమాలు",
  "experience.online.marketplaces":
    "Amazon, Flipkart, Udaan, AliExpress, eBay మొదలైన ఆన్‌లైన్ మార్కెట్‌ప్లేస్‌ల ద్వారా విక్రయించడం మరియు మీ పోటీదారులకు కష్టపడి గెలిచిన కస్టమర్‌లను కోల్పోవడం వంటి అనుభవంతో విసుగు చెందారా? మీ స్వంత ఆన్‌లైన్ స్టోర్ మరియు మొబైల్ యాప్‌తో, మీరు మీ బ్రాండ్ మరియు కస్టమర్ అనుభవాన్ని నియంత్రించవచ్చు, కష్టపడి సంపాదించిన మార్జిన్‌లు మరియు కస్టమర్ డేటాను పూర్తిగా మీ వద్దే ఉంచుకోవచ్చు.",
  "explore.our.features": "మా ఫీచర్లను అన్వేషించండి",
  "platform.specific.business":
    "మీ వ్యాపారం యొక్క నిర్దిష్ట స్వభావం కోసం రూపొందించబడిన ప్లాట్‌ఫారమ్",
  "handle.thousands.transactions":
    "ఒక రోజులో వేలాది లావాదేవీలను సజావుగా నిర్వహిస్తుంది మరియు మీ వ్యాపారాన్ని పెంచుకోండి.",
  "explore.prices": "ధరలను అన్వేషించండి",
  "sell.groceries.medicines":
    "కిరాణా సామాగ్రి, మందులు, ఫర్నిచర్, పుస్తకాలు, ఎలక్ట్రానిక్స్, సౌందర్య ఉత్పత్తులు, ఆభరణాలు, బట్టలు, హస్తకళలు, బేకరీ ఉత్పత్తులు లేదా ఏదైనా ఆన్‌లైన్‌లో విక్రయించండి.",
  "store.information": "స్టోర్ సమాచారం",
  "enter.store.name.logo": "మీ స్టోర్ పేరు మరియు లోగోను నమోదు చేయండి",
  "select.domain": "డొమైన్‌ని ఎంచుకోండి",
  "choose.store.url": "మీ స్టోర్ URLని ఎంచుకోండి",
  "select.industry": "పరిశ్రమను ఎంచుకోండి",
  "pick.business.operate": "మీరు నిర్వహించే వ్యాపారాన్ని ఎంచుకోండి",
  "select.theme": "థీమ్‌ని ఎంచుకోండి",
  "choose.theme.liking": "మీకు నచ్చిన థీమ్ లేదా రంగులను ఎంచుకోండి",
  "download.now": "ఇపుడు డౌన్లోడ్ చేసుకోండి",
  "unlock.ecommerce.power": "Zopping యాప్‌తో E-కామర్స్ శక్తిని అన్‌లాక్ చేయండి",
  "features.banner.one.description":
    "మీ ఆన్‌లైన్ స్టోర్‌ని సెటప్ చేయడం మరియు అమలు చేయడం చాలా సులభం",
  "features.banner.two.description":
    "ఇన్‌స్టాల్ చేయదగిన' పొడిగింపుల హోస్ట్ ద్వారా అధునాతన అనుకూలీకరణలు సాధ్యమయ్యాయి",
  "features.banner.three.description":
    "Zopping మొబైల్ యాప్ ద్వారా అన్ని ఫీచర్లను యాక్సెస్ చేయండి",
  hosting: "హోస్టింగ్",
  "hosting.desc":
    "సైన్ అప్ చేసినప్పుడు ఉచిత హోస్టింగ్‌తో ఉచిత @zopping.com డొమైన్‌ను పొందండి. మీరు వెంటనే లేదా తర్వాత తేదీలో మీ స్వంత డొమైన్‌కు తరలించవచ్చు.",
  "ssl.desc":
    "మీ కస్టమర్‌లకు సురక్షితమైన మరియు సురక్షితమైన షాపింగ్ అనుభవాన్ని ప్రారంభించడానికి మేము మీ డొమైన్ కోసం ఉచిత 256-బిట్ SSL ప్రమాణపత్రాన్ని అందిస్తాము.",
  "themes.desc":
    "మీ వ్యాపార స్వభావానికి సరిపోయే మా టెంప్లేట్‌ల లైబ్రరీ నుండి ఎంచుకోండి. మీ స్టోర్ మీ బ్రాండ్‌ను ప్రతిబింబించేలా చేయడానికి పేజీ లేఅవుట్‌లు, మెనూలు మరియు రంగులను అనుకూలీకరించండి, లోగోలు, ఫేవికాన్‌లు, స్టాటిక్ పేజీలు మరియు బ్లాగ్‌లను జోడించండి. మీ వెబ్‌సైట్‌ను విచ్ఛిన్నం చేయకుండా సులభంగా థీమ్‌ల మధ్య మారండి.",
  "search.box": "శోధన పెట్టె",
  "search.box.desc":
    "మా తెలివైన మరియు వ్యక్తిగతీకరించిన శోధన కార్యాచరణను ఉపయోగించి మీ స్టోర్ నుండి ఉత్పత్తులను శోధించడానికి మీ కస్టమర్‌లను ప్రారంభించండి. నిర్దిష్ట ఉత్పత్తి/ వర్గం/ బ్రాండ్‌ను ప్రమోట్ చేయడానికి నిర్దిష్ట శోధన పదాల కోసం శోధన ఫలితాలను టైలర్ చేయండి మరియు పరిష్కరించండి.",
  "multi.language": "బహుళ-భాష",
  "multi.language.desc":
    "మీ eStoreలో మీరు సపోర్ట్ చేయాలనుకుంటున్న భాషలను సెట్ చేయండి మరియు మీ కస్టమర్ మీ eStoreలో వారు ఇష్టపడే భాషలో బ్రౌజ్ చేయడానికి మరియు షాపింగ్ చేయడానికి అనుమతించండి.",
  "product.listing": "ఉత్పత్తి జాబితా",
  "product.listing.desc":
    "మీ ఉత్పత్తులను కేటగిరీలు మరియు బహుళ-స్థాయి ఉప-వర్గాలుగా అప్‌లోడ్ చేయండి మరియు సులభంగా సమూహపరచండి. అంతర్నిర్మిత అనుకూల ఫీల్డ్‌లను ఉపయోగించి చిత్రాలు, వివరణలు, ట్యాగ్‌లు, స్టాక్, ధరలు, తగ్గింపులు, పన్ను రేట్లు, సెస్ మరియు మరిన్నింటిని జోడించండి. మీ ఉత్పత్తులను వ్యక్తిగతంగా లేదా పెద్దమొత్తంలో సౌకర్యవంతంగా అప్‌లోడ్ చేయండి లేదా సవరించండి.",
  "sell.by.brand": "బ్రాండ్ ద్వారా విక్రయించండి",
  "sell.by.brand.desc":
    "బ్రాండ్ పేరు ఆధారంగా ఉత్పత్తులను శోధించడానికి మరియు ఫిల్టర్ చేయడానికి మీ కస్టమర్‌లను అనుమతించడానికి కేటలాగ్‌లోని మీ ఉత్పత్తులకు 'బ్రాండ్' ఫీల్డ్‌ను జోడించండి.",
  "items.sold.by.weight": "బరువు వారీగా విక్రయించే వస్తువులు",
  "items.sold.by-weight.desc":
    "పండ్లు, కూరగాయలు, ధాన్యాలు, మాంసం, లోహాలు మొదలైనవి వంటి వదులుగా ఉన్న వస్తువులను విక్రయించడం మరియు విక్రయించడం ప్రారంభించండి.",
  "product.variants": "ఉత్పత్తి వైవిధ్యాలు",
  "product.variants.desc":
    "రంగు, పరిమాణం, బరువు మొదలైన వాటి ద్వారా వైవిధ్యాలు ఉన్న ఉత్పత్తులను సజావుగా నిర్వహించండి. ప్రతి వేరియంట్‌కు ఫోటోలు, ధరలు మరియు తగ్గింపులను అప్‌డేట్ చేయండి.",
  "product.subsitution": "ఉత్పత్తి ప్రత్యామ్నాయం",
  "product.subsitution.desc":
    "మీ ఉత్పత్తులకు ప్రత్యామ్నాయాలను సెట్ చేయండి మరియు వాస్తవానికి ఆర్డర్ చేసిన ఉత్పత్తి అందుబాటులో లేనట్లయితే ప్రత్యామ్నాయ ఉత్పత్తులను పంపడం ద్వారా మీ పూరక రేటును మెరుగుపరచండి.",
  "stock.management.desc":
    "స్టాక్ డేటాను అప్‌లోడ్ చేయండి లేదా డౌన్‌లోడ్ చేయండి, బఫర్ స్టాక్‌ను సెటప్ చేయండి మరియు ఏదైనా ఉత్పత్తి స్టాక్ అయిపోయినప్పుడు హెచ్చరికలను పొందండి.",
  "payment.gateway.3rd.party": "3 వ- పక్షం చెల్లింపు గేట్‌వే ఇంటిగ్రేషన్",
  "payment.gateway.3rd.party.desc":
    "మీ చెల్లింపు విధానాన్ని త్వరగా సెటప్ చేయడానికి ముందుగా కాన్ఫిగర్ చేసిన 3 వ పక్షం చెల్లింపు గేట్‌వేల యొక్క మొత్తం హోస్ట్‌ని సద్వినియోగం చేసుకోండి . మీ వ్యాపారి లావాదేవీ ఛార్జీలను ఆప్టిమైజ్ చేయడానికి బహుళ గేట్‌వేల ద్వారా మీ చెల్లింపు రూటింగ్‌ను తెలివిగా ఆటోమేట్ చేయండి.",
  "paypal.integration": "PayPal ఇంటిగ్రేషన్",
  "paypal.integration.desc":
    "మా అంతర్నిర్మిత PayPal ఇంటిగ్రేషన్‌తో అంతర్జాతీయ ఆర్డర్‌లు మరియు చెల్లింపులను అంగీకరించండి.",
  "your.ewallet": "మీ eWallet",
  "your.ewallet.desc":
    "మీ కస్టమర్ లాయల్టీని మెరుగుపరచండి మరియు కస్టమర్‌లకు మీ బ్రాండెడ్ eWalletని అందించడం ద్వారా రివార్డ్ చేయండి. మీ కస్టమర్‌లు వారి వాలెట్‌లకు డబ్బును జోడించవచ్చు మరియు వారి భవిష్యత్ కొనుగోళ్ల సమయంలో దాన్ని ఉపయోగించవచ్చు.",
  "gift.cards.desc":
    "మీ స్టోర్‌లో మాత్రమే రీడీమ్ చేయగల అనుకూలీకరించదగిన ఇ-గిఫ్ట్ కార్డ్‌లను విక్రయించడం ద్వారా మీ కస్టమర్‌లు వారి ప్రియమైన వారికి బహుమతిగా ఇవ్వడానికి వీలు కల్పించండి.",
  cod: "క్యాష్ ఆన్ డెలివరీ (COD)",
  "cod.desc": "మీ కస్టమర్‌లకు COD కార్యాచరణను ఆఫర్ చేయండి.",
  "webpages.and.banners": "వెబ్‌పేజీలు మరియు బ్యానర్‌లు",
  "webpages.and.banners.desc":
    "కొత్త ఉత్పత్తి లాంచ్‌లు, ఆఫర్‌లు, కాలానుగుణ మరియు పండుగ విక్రయాలు మొదలైనవాటిని ప్రోత్సహించడానికి మీ వెబ్‌సైట్ యొక్క వివిధ పేజీల కోసం అనుకూల వెబ్ పేజీలు మరియు వెబ్ బ్యానర్‌లను సృష్టించండి మరియు షెడ్యూల్ చేయండి",
  "offers.management": "ఆఫర్‌ల నిర్వహణ",
  "offers.management.desc":
    "ఉత్పత్తులు/ కేటగిరీలు/ బ్రాండ్‌లు/ కస్టమర్‌పై 10+ రకాల ఆఫర్‌లను సృష్టించండి, అనుకూలీకరించండి, అమలు చేయండి మరియు ట్రాక్ చేయండి (ఫ్లాట్ ఆఫ్/ % ఆఫ్/ కనిష్ట. కొనుగోలు/ కాంబోలు/ కొనుగోలు-ఒకటి పొందండి-ఒకటి/ % అదనపు) విభాగాలు. ఆఫర్ యొక్క వర్తింపు కోసం మీ పరిమితులు మరియు నియమాలను సెట్ చేయండి.",
  "campaign.management": "ప్రచార నిర్వహణ",
  "campaign.management.desc":
    "మీ కస్టమర్‌లకు యాప్ నోటిఫికేషన్‌లు, ఇమెయిల్‌లు మరియు SMSల ద్వారా ప్రచార ప్రచారాలను సృష్టించండి, షెడ్యూల్ చేయండి, అమలు చేయండి మరియు పర్యవేక్షించండి. కూపన్‌లను పంపిణీ చేయండి, ఉత్పత్తి లాంచ్‌లను ప్రకటించండి, ధర తగ్గింపులను హెచ్చరించండి మొదలైనవి. లక్ష్య ప్రచారాలను అమలు చేయడానికి అంతర్నిర్మిత కస్టమర్ సెగ్మెంటేషన్ సాధనాన్ని ఉపయోగించండి.",
  "coupons.desc":
    "మీ కస్టమర్‌లకు ఉత్పత్తి లేదా షిప్పింగ్ తగ్గింపులను అందించడానికి ప్రత్యేకమైన లేదా ప్రామాణిక కూపన్‌లను రూపొందించండి మరియు పంపిణీ చేయండి. డిస్కౌంట్ పరిమితులను సెట్ చేయండి మరియు ఆర్డర్ విలువ/ చెల్లింపు ఎంపికలు/ ఆర్డర్ డే/ కస్టమర్ సెగ్మెంట్/ స్టోర్ ఆధారంగా కూపన్ యొక్క వర్తింపును పరిమితం చేయండి. కూపన్‌ల పంపిణీ మరియు ఫలితంగా అమ్మకాల విజయాన్ని కొలవండి మరియు పర్యవేక్షించండి.",
  seo: "సెర్చ్ ఇంజన్ ఆప్టిమైజేషన్ (SEO)",
  "seo.desc":
    "మీ కస్టమర్‌లు మీ కోసం లేదా మీ ఉత్పత్తుల కోసం ఇంటర్నెట్‌లో శోధించినప్పుడు వారు కనుగొనబడతారు. Google శోధనలలో ఎక్కువగా కనిపించేలా మీ పేజీ శీర్షికలు, వివరణలు మరియు కీలకపదాలను సెట్ చేయండి.",
  "orders.dashboard": "ఆర్డర్‌ల డ్యాష్‌బోర్డ్",
  "orders.dashboard.desc":
    "ఆర్డర్ నంబర్, కస్టమర్ పేరు, సంప్రదింపు వివరాలు, ఆర్డర్ సమయం, ఆర్డర్ స్థితి, చెల్లింపు స్థితి, ఆర్డర్ విలువ మొదలైన పూర్తి వివరాలతో మీ అన్ని ఆర్డర్‌లను ఒకే చోట వీక్షించడానికి స్పష్టమైన మరియు సులభంగా నావిగేట్ చేయగల డాష్‌బోర్డ్.",
  "orders.alerts": "ఆర్డర్ హెచ్చరికలు",
  "orders.alerts.desc":
    "ఆర్డర్ ప్లేస్‌మెంట్, క్యాన్సిలేషన్, డెలివరీ మొదలైన ముఖ్యమైన ఈవెంట్‌లపై తక్షణ SMS/ పుష్ నోటిఫికేషన్‌లను పొందడం ద్వారా మీ కస్టమర్‌ల ఆర్డర్‌లపై అగ్రస్థానంలో ఉండండి.",
  "delivery.area.management": "డెలివరీ ఏరియా మేనేజ్‌మెంట్",
  "delivery.area.management.desc":
    "పిన్‌కోడ్‌లు లేదా స్టోర్ నుండి దూరం లేదా మ్యాప్‌పై గీయడం ద్వారా మీరు బట్వాడా చేయగల ప్రాంతాలను పరిమితం చేయండి.",
  "delivery.partner.integration": "డెలివరీ పార్టనర్ ఇంటిగ్రేషన్",
  "delivery.partner.integration.desc":
    "మీ కస్టమర్ ఆర్డర్‌లను మీ స్వంతంగా డెలివరీ చేయండి లేదా మీ నగరంలో లేదా భారతదేశంలో ఎక్కడికైనా రవాణా చేయడానికి మా డెలివరీ భాగస్వాముల జాబితా నుండి ఎంచుకోండి.",
  "customer.details": "కస్టమర్ వివరాలు",
  "customer.details.desc":
    "మీరు సవరించడానికి, డౌన్‌లోడ్ చేయడానికి, శోధించడానికి లేదా సమూహం చేయడానికి మీ మొత్తం కస్టమర్ డేటా మరియు వారి కొనుగోలు చరిత్ర ఒకే చోట అందుబాటులో ఉన్నాయి. మీ ప్రస్తుత కస్టమర్ డేటాను దిగుమతి చేసుకోండి మరియు అన్నింటినీ ఒకే చోట ఉంచండి.",
  "live.chat": "లైవ్ చాట్",
  "live.chat.desc":
    "మీ కస్టమర్‌లు మీ స్టోర్‌లో షాపింగ్ చేస్తున్నప్పుడు లైవ్ చాట్ విడ్జెట్ ద్వారా వారి ప్రశ్నలను పరిష్కరించండి.",
  "order.returns": "ఆర్డర్ రిటర్న్స్",
  "order.returns.desc":
    "ఆర్డర్ రిటర్న్‌లను సజావుగా నిర్వహించండి, మీ ఇన్వెంటరీని స్వయంచాలకంగా సర్దుబాటు చేయండి మరియు మీ కస్టమర్‌లకు తిరిగి చెల్లించండి.",
  "multi.user.access": "బహుళ-వినియోగదారు యాక్సెస్",
  "multi.user.access.desc":
    "మీ స్టోర్‌ని నిర్వహించడానికి మీ ఉద్యోగులను అనుమతించండి. పాత్రలు మరియు అనుమతులను సెట్ చేయండి. షిఫ్ట్‌లు మరియు హాజరును నిర్వహించండి.",
  "multi.store": "బహుళ-స్టోర్",
  "multi.store.desc":
    "ఒకటి కంటే ఎక్కువ భౌతిక దుకాణాలు ఉన్నాయా? ధరలు, ఆఫర్‌లు, డెలివరీ ఛార్జీల ఆధారంగా నిర్వహించేందుకు మేము మిమ్మల్ని అనుమతిస్తాము",
  "reports.desc":
    "ప్రామాణిక విక్రయాలు, మార్కెటింగ్, కార్యకలాపాలు, కస్టమర్, స్టాక్ నివేదికలను డౌన్‌లోడ్ చేయండి లేదా మీ అనుకూల నివేదికను సృష్టించండి.",
  "analytics.desc":
    "మీ వ్యాపారం యొక్క ఆరోగ్యాన్ని ట్రాక్ చేయడానికి మా డాష్‌బోర్డ్‌ని ఉపయోగించండి. మీ విక్రయాలు, మార్కెటింగ్ ప్రచారాలు, కార్యకలాపాలు, ఆర్డర్‌లు, కస్టమర్ వృద్ధి, స్టాక్‌ను అర్థవంతమైన అంతర్దృష్టులు మరియు వ్యాపార మేధస్సును పొందేందుకు పర్యవేక్షించండి మరియు సరిపోల్చండి.",
  "fb.analytics": "Facebook Analytics",
  "fb.analytics.desc":
    "మీ Facebook పిక్సెల్ IDని ఉపయోగించి మీ Facebook ప్రకటనల పనితీరును సులభంగా ఇంటిగ్రేట్ చేయండి మరియు ట్రాక్ చేయండి.",
  "google.analytics": "Google Analytics",
  "google.analytics.desc":
    "మీ కస్టమర్ సముపార్జన ఛానెల్‌లు, జనాభాలు, రాబడి మరియు ఇతర గొప్ప అంతర్దృష్టులను ట్రాక్ చేయడానికి మీ Google Analyticsని మీ eStoreతో సులభంగా ఇంటిగ్రేట్ చేయండి.",
  "customer.app": "కస్టమర్ యాప్",
  "customer.app.desc":
    "మీ స్టోర్ కోసం ఉచిత అనుకూలీకరించిన మరియు బ్రాండెడ్ iOS మరియు Android కస్టమర్ యాప్. మీ బ్రాండ్‌ను ప్రతిబింబించేలా మీ యాప్ పేరు, లాంచ్ ఐకాన్ మరియు స్ప్లాష్ స్క్రీన్‌లను సెట్ చేయండి.",
  "delivery.app": "డెలివరీ యాప్",
  "delivery.app.desc":
    "మీ డెలివరీ సిబ్బంది ఆర్డర్‌లను ఎంచుకోవడానికి, ప్రాధాన్యతనిస్తూ మరియు డెలివరీ చేయడానికి ఉచిత Android యాప్.",
  "picker.app": "పికర్ యాప్",
  "picker.app.desc":
    "ఆర్డర్‌లను ఎంచుకోవడానికి, ప్యాక్ చేయడానికి మరియు ధృవీకరించడానికి మరియు లేబుల్‌లను ప్రింట్ చేయడానికి మీ సిబ్బందికి ఉచిత Android యాప్.",
  "admin.app": "అడ్మిన్ యాప్",
  "admin.app.desc":
    "మీ మొబైల్‌లో మీ ఆన్‌లైన్ స్టోర్‌ని నిర్వహించడానికి మీకు ఉచిత iOS మరియు Android యాప్. మీ విక్రయాలను ట్రాక్ చేయండి, ఆర్డర్‌లను సమీక్షించండి, మీ ఉత్పత్తులను సవరించండి, మార్కెటింగ్ ప్రచారాలను అమలు చేయండి మరియు ప్రయాణంలో మీ స్టోర్ సెట్టింగ్‌లను మార్చండి.",
  "custom.password.format": "కస్టమర్ పాస్‌వర్డ్ ఫార్మాట్ సెటప్",
  "custom.password.format.desc":
    "పాస్‌వర్డ్‌ను సృష్టించేటప్పుడు మీ కస్టమర్‌లు అనుసరించాల్సిన అనుకూల నియమాలను సెట్ చేయండి తద్వారా భద్రతను మెరుగుపరుస్తుంది.",
  "data.security": "డేటా భద్రత",
  "data.security.desc": "మీ డేటా మీకు మాత్రమే అందుబాటులో ఉంటుంది.",
  "data.and.security": "డేటా మరియు భద్రత",
  apps: "యాప్‌లు",
  channels: "ఛానెల్‌లు",
  "customer.management": "కస్టమర్ మేనేజ్‌మెంట్",
  delivery: "డెలివరీ",
  marketing: "మార్కెటింగ్",
  cataloguing: "జాబితా",
  "testimonials.ambros":
    "I was completely new to e-commerce and scared about the last-mile delivery operations. I couldn’t have asked for a better or easier solution than the Zopping e-commerce platform. The entire process from order placement on web/mobiles apps through last-mile delivery is easy and extremely efficient. A fantastic experience overall! Amazing solution for retailers at an affordable price from the Zopping team!",
  "testimonials.medinmin":
    "వెబ్ & యాప్ డెవలప్‌మెంట్ కోసం చూస్తున్న ప్రతి ఒక్కరికీ ఇ-కామ్ ప్లాట్‌ఫారమ్‌ని జోపింగ్ చేయమని నేను సిఫార్సు చేస్తున్నాను. వారు మీ అవసరాలను వెంటనే తీర్చే ప్లగ్-అండ్-ప్లే కార్యాచరణలను కలిగి ఉన్నారు. అవి నా అంచనాలను నెరవేర్చడమే కాకుండా వాటిని మించిపోయాయి. వెబ్ & మొబైల్ యాప్‌లు చాలా ఆకర్షణీయంగా ఉంటాయి & వినియోగదారులతో అద్భుతమైన నిశ్చితార్థాన్ని కూడా అందిస్తాయి. వారి సేవ, సాఫ్ట్‌వేర్ మరియు వృత్తి నైపుణ్యానికి నేను నిజంగా ఆకట్టుకున్నాను.",
  "testimonials.blossom":
    "మా పుస్తకాల వ్యాపారాన్ని ఆన్‌లైన్‌లో తీసుకోవడానికి మాకు అప్లికేషన్ అవసరమైనప్పుడు జోపింగ్ మాకు సరైన మార్గదర్శకత్వాన్ని అందించింది. నేను నిర్ణయించుకునే ముందు, మేము వారి సేల్స్ టీమ్‌తో చర్చించాము మరియు మా ఆలోచనను ఆన్‌లైన్ బుక్ స్టోర్‌గా ఎలా మార్చాలో అది మాకు మార్గనిర్దేశం చేసింది. తరువాత, ఆన్‌బోర్డింగ్ బృందం సెటప్ ప్రక్రియ అంతటా మాతో సన్నిహితంగా ఉంది మరియు వారు మా అవసరాలకు అనుగుణంగా వెబ్‌సైట్‌ను అనుకూలీకరించేలా చూసుకున్నారు. టన్ను ధన్యవాదాలు, జోపింగ్ బృందం!",
  "testimonials.name.ambros": "రోహిత్ కుమార్",
  "testimonials.designation.ambros": "డైరెక్టర్",
  "testimonials.company.ambros": "ఆంబ్రోస్ రిటైల్",
  "testimonials.name.medinmin": "ఇర్ఫాన్ బి",
  "testimonials.designation.medinmin": "ఆప్స్ మేనేజర్",
  "testimonials.company.medinmin": "మెడిన్‌మినిట్స్",
  "testimonials.name.blossom": "మైగౌడ",
  "testimonials.designation.blossom": "మేనేజింగ్ డైరెక్టర్",
  "testimonials.company.blossom": "బ్లోసమ్ బుక్ హౌస్",
  Hyperlocal: "హైపర్లోకల్",
  "Hyperlocal.descroption":
    "Zopping మీ పరిసరాల్లో లేదా ప్రాంతంలో విక్రయించడానికి మరియు డిజిటల్ మార్కెట్‌ప్లేస్‌లో విజయం సాధించడానికి సరైన ఇ-కామర్స్ ప్లాట్‌ఫారమ్‌ను అందిస్తుంది. మిగిలిన వాటి నుండి జోపింగ్ ఎందుకు ప్రత్యేకంగా నిలుస్తుందో ఇక్కడ ఉంది",
  "Create.Your.Online.Store": "మీ ఆన్‌లైన్ స్టోర్‌ని సృష్టించండి",
  "Best.suited.for.selling": "విక్రయించడానికి ఉత్తమంగా సరిపోతుంది",
  Grocery: "కిరాణా",
  "Food.and.Beverage": "అన్నపానీయాలు",
  Medicine: "మందు",
  Bakery: "బేకరీ",
  Diary: "డైరీ",
  Fish: "చేప",
  "Embrace.Convenience": "సౌలభ్యాన్ని స్వీకరించండి",
  "Embrace.Convenience.discription1":
    "Zopping స్టోర్ పికప్ మరియు డెలివరీ ఎంపికలను అందిస్తుంది, మీ కస్టమర్ల ప్రాధాన్యతలను అందిస్తుంది..",
  "Embrace.Convenience.discription2":
    "జోపింగ్ యొక్క ఓమ్నిచానెల్ పాయింట్ ఆఫ్ సేల్ (POS) సిస్టమ్‌తో మీ కేటలాగ్, ఇన్వెంటరీ, ఆర్డర్‌లు మరియు కస్టమర్ డేటాను సమకాలీకరించండి..",
  "Flexible.Order.Management": "ఫ్లెక్సిబుల్ ఆర్డర్ మేనేజ్‌మెంట్",
  "Flexible.Order.Management.description1":
    "పిన్‌కోడ్‌లు/ప్రాంతం/అపార్ట్‌మెంట్‌ల ద్వారా మీ సేవా ప్రాంతాలను నిర్వచించండి మరియు మీ స్థానిక కస్టమర్ బేస్‌కు సమర్ధవంతంగా సేవలు అందించండి..జోపింగ్‌ఆఫర్‌లు స్టోర్ పికప్ మరియు డెలివరీ ఎంపికలు, మీ కస్టమర్‌ల ప్రాధాన్యతలకు అనుగుణంగా ఉంటాయి..",
  "Flexible.Order.Management.description2":
    "జోపింగ్ బహుళ శాఖలు లేదా స్టోర్‌లతో వ్యాపారాలకు మద్దతు ఇస్తుంది, కార్యకలాపాలను క్రమబద్ధీకరించడం మరియు విస్తృత కస్టమర్ బేస్‌ను చేరుకోవడం.",
  "Flexible.Order.Management.description3":
    "సర్‌ఛార్జ్‌లు లేదా డిస్కౌంట్‌లతో నిర్దిష్ట పికప్ లేదా డెలివరీ స్లాట్‌లను ప్రోత్సహించండి.",
  "Flexible.Order.Management.description4":
    "ఆర్డర్‌లను సమర్ధవంతంగా నిర్వహించడానికి మరియు పీక్ పీరియడ్‌లలో అధిక డిమాండ్‌లను నివారించడానికి పరిమితులను సెట్ చేయండి.",
  "Simplified.Delivery.Management": "సరళీకృత డెలివరీ నిర్వహణ",
  "Simplified.Delivery.Management.description1":
    "జోపింగ్ డెలివరీ యాప్ ఆర్డర్ హ్యాండ్లింగ్‌లో విప్లవాత్మక మార్పులు చేస్తుంది, ప్రాంప్ట్ మరియు నమ్మదగిన సేవను అందిస్తుంది.",
  "Simplified.Delivery.Management.description2":
    "ప్రయాణించిన దూరం ఆధారంగా మారుతున్న డెలివరీ రుసుము ఖర్చులను కవర్ చేసేటప్పుడు సరసమైన ధరను నిర్ధారిస్తుంది.",
  "Delight.your.customers": "Delight your customers",
  "Delight.your.customers.description1":
    "మీ వెబ్‌సైట్ మరియు మొబైల్ యాప్‌లో అతుకులు మరియు స్థిరమైన షాపింగ్ అనుభవాన్ని అందించండి.",
  "Delight.your.customers.description2":
    "ఎక్కడి నుండైనా, ఎప్పుడైనా ఆర్డర్లు చేసే సౌలభ్యాన్ని అందించండి.",
  "Zopping.Advantage": "జోపింగ్ అడ్వాంటేజ్",
  "Zopping.Advantage.description1":
    "పోటీలో ముందుండడానికి సాధారణ అప్‌డేట్‌లు మరియు కొనసాగుతున్న మెరుగుదలల నుండి ప్రయోజనం పొందండి.",
  "Zopping.Advantage.description2":
    " మీ అనుకూల-బ్రాండెడ్ స్థానిక iOS మరియు Android యాప్‌ని పొందండి మరియు ఒక రోజు కంటే తక్కువ వ్యవధిలో రన్ అవ్వండి.",
  "Zopping.Advantage.description3":
    " స్థలం మరియు ఇది మీ వెబ్‌సైట్ మరియు మొబైల్ యాప్‌లో స్వయంచాలకంగా నవీకరించబడుతుంది.",
  "Engage.your.customer.with.Push":
    "పుష్ నోటిఫికేషన్‌లతో మీ కస్టమర్‌ని ఎంగేజ్ చేయండి",
  "Engage.your.customer.with.Push.description1":
    "వ్యక్తిగతీకరించిన సందేశాలు, ప్రత్యేక ఆఫర్‌లు మరియు కొత్త అరైవల్ నోటిఫికేషన్‌లతో విక్రయాలను పెంచండి మరియు కస్టమర్‌లను నిజ సమయంలో నిమగ్నం చేయండి",
};

export default messages;
