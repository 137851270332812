const messages = {
  "header.home": "ಮನೆ",
  "header.products": "ಉತ್ಪನ್ನಗಳು",
  "header.technology": "ತಂತ್ರಜ್ಞಾನ",
  "header.about.us": "ನಮ್ಮ ಬಗ್ಗೆ",
  "header.careers": "ವೃತ್ತಿಗಳು",
  "header.contact.us": "ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
  "header.language": "ಭಾಷೆ",
  english: "ಆಂಗ್ಲ",
  hindi: "ಹಿಂದಿ",
  signin: "ಸೈನ್ ಇನ್ ಮಾಡಿ",
  "signing.in": "ಸೈನ್ ಇನ್ ಮಾಡಲಾಗುತ್ತಿದೆ",
  signup: "ಸೈನ್ ಅಪ್ ಮಾಡಿ",
  home: "ಮುಖಪುಟ",
  features: "ವೈಶಿಷ್ಟ್ಯಗಳು",
  pricing: "ಬೆಲೆ ನಿಗದಿ",
  contact: "ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
  solutions: "ಪರಿಹಾರಗಳು",
  "contact.us": "ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
  "products.smartstore.eazy": "ಸ್ಮಾರ್ಟ್‌ಸ್ಟೋರ್ ಸುಲಭ",
  "products.smartstore.enterprise": "ಸ್ಮಾರ್ಟ್‌ಸ್ಟೋರ್ ಎಂಟರ್‌ಪ್ರೈಸ್",
  "products.smartproduct.locator": "ಸ್ಮಾರ್ಟ್ ಉತ್ಪನ್ನ ಲೊಕೇಟರ್",
  "products.smartpayment.gateway": "ಸ್ಮಾರ್ಟ್ ಪಾವತಿ ಗೇಟ್‌ವೇ",
  "products.smartpoint.sale": "ಮಾರಾಟದ ಸ್ಮಾರ್ಟ್ ಪಾಯಿಂಟ್",
  "products.grocery.express": "ಗ್ರಾಸರಿ ಎಕ್ಸ್‌ಪ್ರೆಸ್",
  "smartstore.eazy.heading": "ಪ್ರಮುಖ ಲಕ್ಷಣಗಳು",
  "smartstore.eazy.selling.online":
    "ನೀವು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಮಾರಾಟ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಬೇಕಾದ ಎಲ್ಲವೂ",
  "smartstore.eazy.unique.store": "ನಿಮ್ಮ ಅನನ್ಯ ಆನ್ಲೈನ್ ​​ಸ್ಟೋರ್ ಪಡೆಯಿರಿ",
  "smartstore.eazy.feature.standout":
    "ನಿಮ್ಮ ಅನನ್ಯ ವೆಬ್‌ಸೈಟ್ ಅನ್ನು ರಚಿಸುವ ಮೂಲಕ ಗುಂಪಿನಲ್ಲಿ ಎದ್ದು ಕಾಣಿ",
  "smartstore.eazy.feature.domain":
    "ನಿಮ್ಮ ಸ್ವಂತ ಡೊಮೇನ್ ಹೆಸರನ್ನು ಬಳಸಿ ಮತ್ತು ನಿಮ್ಮ ಬ್ರ್ಯಾಂಡ್ ಮೌಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಿ",
  "smartstore.eazy.feature.colors":
    "ನಿಮ್ಮ ಬ್ರ್ಯಾಂಡ್ ಅನ್ನು ಪ್ರತಿಬಿಂಬಿಸುವ ಬಣ್ಣಗಳು ಮತ್ತು ಥೀಮ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  "smartstore.eazy.feature.banners":
    "ಆಕರ್ಷಕ ಬ್ಯಾನರ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡುವ ಮೂಲಕ ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಅನ್ನು ವರ್ಧಿಸಿ",
  "smartstore.eazy.setup.shop": "ನಿಮ್ಮ ಅಂಗಡಿಯನ್ನು ಸುಲಭವಾಗಿ ಹೊಂದಿಸಿ",
  "smartstore.eazy.create.own":
    "ನಿಮ್ಮ ಸ್ವಂತ ಅಂಗಡಿಯನ್ನು ರಚಿಸಿ - ಯಾವುದೇ ತಾಂತ್ರಿಕ ಕೌಶಲ್ಯಗಳ ಅಗತ್ಯವಿಲ್ಲ",
  "smartstore.eazy.feature.setup.online":
    "ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಅಂಗಡಿಯನ್ನು ಸುಲಭವಾಗಿ ಹೊಂದಿಸಲು ನಮ್ಮ ಹಂತ ಹಂತದ ಮಾರ್ಗದರ್ಶಿ ಬಳಸಿ",
  "smartstore.eazy.feature.text.boxes":
    "ಸರಳ ಪಠ್ಯ ಪೆಟ್ಟಿಗೆಗಳು, ಡ್ರಾಪ್-ಡೌನ್‌ಗಳು ಮತ್ತು ಡ್ರ್ಯಾಗ್ ಮತ್ತು ಡ್ರಾಪ್ ಮೆನುಗಳನ್ನು ಬಳಸಿಕೊಂಡು ಎಲ್ಲಾ ಬದಲಾವಣೆಗಳನ್ನು ಮಾಡಿ",
  "smartstore.eazy.feature.catalogue":
    "ನಿಮ್ಮ ಕ್ಯಾಟಲಾಗ್ ಅನ್ನು ರಚಿಸಿ, ಬೆಲೆಗಳನ್ನು ಹೊಂದಿಸಿ, ಶಿಪ್ಪಿಂಗ್ ನಿಯಮಗಳನ್ನು ಹೊಂದಿಸಿ ಮತ್ತು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಮಾರಾಟ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿ",
  "smartstore.eazy.feature.mobile": "ತಕ್ಷಣ ಮೊಬೈಲ್‌ಗೆ ಹೋಗಿ",
  "smartstore.eazy.additional.sales":
    "ಮೊಬೈಲ್ ಸಾಧನಗಳಲ್ಲಿ ಮಾರಾಟ ಮಾಡುವ ಮೂಲಕ ಹೆಚ್ಚುವರಿ ಮಾರಾಟವನ್ನು ಪಡೆಯಿರಿ",
  "smartstore.eazy.selling.mobile":
    "ಮೊಬೈಲ್ ಸಾಧನಗಳಲ್ಲಿ ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಇನ್ನಷ್ಟು ಬೆರಗುಗೊಳಿಸುತ್ತದೆ ಎಂದು ತಕ್ಷಣವೇ ಮೊಬೈಲ್‌ನಲ್ಲಿ ಮಾರಾಟ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿ",
  "smartstore.eazy.shopping.convenience":
    "ಶಾಪಿಂಗ್ ಕಾರ್ಟ್ ಸಾಧನಗಳಾದ್ಯಂತ ಸಾಮಾನ್ಯವಾಗಿರುವುದರಿಂದ ಶಾಪಿಂಗ್ ಅನುಕೂಲವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  "smartstore.eazy.international.app":
    "ನಿಮ್ಮ ಸ್ವಂತ ಅಂತರಾಷ್ಟ್ರೀಯ ಗುಣಮಟ್ಟದ Android ಮತ್ತು iOS ಅಪ್ಲಿಕೇಶನ್ ಪಡೆಯಿರಿ",
  "smartstore.eazy.manage.go": "ಪ್ರಯಾಣದಲ್ಲಿರುವಾಗ ಅದನ್ನು ನಿರ್ವಹಿಸಿ",
  "smartstore.eazy.easy.access":
    "ನಿಮ್ಮ Android ಮೊಬೈಲ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಅಂಗಡಿ ನಿರ್ವಾಹಕರನ್ನು ಸುಲಭವಾಗಿ ಪ್ರವೇಶಿಸಿ",
  "smartstore.eazy.update.prices": "ಬೆಲೆಗಳು, ಸ್ಟಾಕ್ ಮತ್ತು ಚಿತ್ರಗಳನ್ನು ನವೀಕರಿಸಿ",
  "smartstore.eazy.important.alerts":
    "ಆರ್ಡರ್ ಪ್ಲೇಸ್‌ಮೆಂಟ್, ಪಾವತಿ ಮತ್ತು ರದ್ದತಿಯಂತಹ ಪ್ರಮುಖ ಈವೆಂಟ್‌ಗಳ ಕುರಿತು ಎಚ್ಚರಿಕೆಗಳನ್ನು ಪಡೆಯಿರಿ",
  "smartstore.eazy.customer.reply":
    "ಯಾವುದೇ ವಿಳಂಬವಿಲ್ಲದೆ ಗ್ರಾಹಕರ ಇಮೇಲ್‌ಗಳು ಮತ್ತು ಚಾಟ್‌ಗಳಿಗೆ ಪ್ರತ್ಯುತ್ತರಿಸಿ",
  "smartstore.eazy.customer.reachout": "ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ತಲುಪಿ",
  "smartstore.eazy.powerful.seo":
    "ನಮ್ಮ ಪ್ರಬಲ SEO ನೊಂದಿಗೆ ಹೊಸ ಗ್ರಾಹಕರನ್ನು ಪಡೆಯಿರಿ",
  "smartstore.eazy.offers.coupons":
    "ನಿಮ್ಮ ಗ್ರಾಹಕರ ವಿಭಾಗವನ್ನು ಗುರಿಯಾಗಿಸಿಕೊಂಡು ಕೊಡುಗೆಗಳು ಮತ್ತು ಕೂಪನ್‌ಗಳನ್ನು ರಚಿಸಿ",
  "smartstore.eazy.marketing.campaigns":
    "ನಿರ್ದಿಷ್ಟ ಗ್ರಾಹಕರಿಗೆ SMS, ಇಮೇಲ್‌ಗಳು ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್-ಅಧಿಸೂಚನೆಗಳ ಮೂಲಕ ಉದ್ದೇಶಿತ ಮಾರುಕಟ್ಟೆ ಪ್ರಚಾರಗಳನ್ನು ರನ್ ಮಾಡಿ",
  "smartstore.eazy.manage.customers": "ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ನಿರ್ವಹಿಸಿ",
  "smartstore.eazy.build.loyalty":
    "ನಿಷ್ಠೆಯನ್ನು ನಿರ್ಮಿಸಲು ನಮ್ಮ ಶಕ್ತಿಯುತ CRM ಬಳಸಿ",
  "smartstore.eazy.view.customers":
    "ನಿಮ್ಮ ಎಲ್ಲಾ ಗ್ರಾಹಕರನ್ನು ಅವರ ವಿವರವಾದ ಖರೀದಿ ಇತಿಹಾಸದೊಂದಿಗೆ ಒಂದೇ ಸ್ಥಳದಲ್ಲಿ ವೀಕ್ಷಿಸಿ",
  "smartstore.eazy.upload.details":
    "ನಿಮ್ಮ ಆಫ್‌ಲೈನ್-ಗ್ರಾಹಕರಿಗೆ ಅದೇ ಮಟ್ಟದ ಸೇವೆಯನ್ನು ಒದಗಿಸಲು ಅವರ ವಿವರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
  "smartstore.eazy.add.edit.details":
    "ನಿಮ್ಮ ಗ್ರಾಹಕರ ವಿಳಾಸ, ಇಮೇಲ್ ಮತ್ತು ಫೋನ್ ಸಂಖ್ಯೆಯಂತಹ ವಿವರಗಳನ್ನು ಸೇರಿಸಿ ಅಥವಾ ಸಂಪಾದಿಸಿ",
  message404:
    "ನೀವು ಇಂಟರ್ನೆಟ್ ಅನ್ನು ಮುರಿದಿಲ್ಲ, ಆದರೆ ನೀವು ಹುಡುಕುತ್ತಿರುವುದನ್ನು ನಾವು ಹುಡುಕಲು ಸಾಧ್ಯವಿಲ್ಲ.",
  "go.home": "ಮುಖಪುಟಕ್ಕೆ ಹೋಗಿ",
  "zopping.pricing": "ಬೆಲೆ ಯೋಜನೆ",
  "pay.for.use":
    "ನಮ್ಮ ಮೂಲಕ ನೀವು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಮಾರಾಟ ಮಾಡುವುದಕ್ಕೆ ಮಾತ್ರ ನಮಗೆ ಪಾವತಿಸಿ",
  "currency.selector": "ಕರೆನ್ಸಿ ಸೆಲೆಕ್ಟರ್",
  "everything.free": "ಡೆಸ್ಕ್‌ಟಾಪ್ ಮತ್ತು ಮೊಬೈಲ್ ವೆಬ್ ಸೇರಿದಂತೆ ಎಲ್ಲವೂ ಉಚಿತವಾಗಿದೆ",
  "android.app.customers": "ಗ್ರಾಹಕರಿಗೆ Android ಅಪ್ಲಿಕೇಶನ್",
  "ios.app.customers": "ಗ್ರಾಹಕರಿಗಾಗಿ iOS ಅಪ್ಲಿಕೇಶನ್",
  "picker.app.employees": "ಉದ್ಯೋಗಕ್ಕಾಗಿ ಪಿಕರ್ ಅಪ್ಲಿಕೇಶನ್",
  "logistics.app.employees": "ಉದ್ಯೋಗಕ್ಕಾಗಿ ಲಾಜಿಸ್ಟಿಕ್ ಅಪ್ಲಿಕೇಶನ್",
  "buy.now": "ಈಗ ಖರೀದಿಸು",
  "contact.us.support": "ಬೆಂಬಲಕ್ಕಾಗಿ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
  "customization.integration": "ಗ್ರಾಹಕೀಕರಣ ಮತ್ತು ಏಕೀಕರಣಕ್ಕಾಗಿ ನಮ್ಮನ್ನು ತಲುಪಿ",
  "cant.find.answer": "ಉತ್ತರ ಸಿಗಲಿಲ್ಲವೇ?",
  "pricing.faq": "ಬೆಲೆ - ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು",
  "view.more": "ಇನ್ನಷ್ಟು ವೀಕ್ಷಿಸಿ",
  "view.less": "ಕಡಿಮೆ ವೀಕ್ಷಿಸಿ",
  "more.than": "ಗಿಂತ ಹೆಚ್ಚು",
  "month.sales": "ತಿಂಗಳ ಮಾರಾಟ",
  "upto.text": "ತನಕ",
  heroHeadingText: "ನಿಮ್ಮ ವ್ಯಾಪಾರವನ್ನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಬೆಳೆಸಿಕೊಳ್ಳಿ",
  heroParagraphText: "ಚಿಲ್ಲರೆ ವ್ಯಾಪಾರಿಗಳಿಗಾಗಿ ಅಂತಿಮ ಇ-ಕಾಮರ್ಸ್ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್.",
  heroFormButtonText: "ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಉಚಿತವಾಗಿ ಮಾರಾಟ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿ",
  heroFormInputPlaceholder: "ಇಮೇಲ್ ವಿಳಾಸ ಅಥವಾ ಫೋನ್ ಸಂಖ್ಯೆ",
  "email.two.days": "ಎರಡು ಕೆಲಸದ ದಿನಗಳಲ್ಲಿ ಇಮೇಲ್ ಮಾಡಿ",
  "email.instant.chat": "ಸಾಧ್ಯವಾದಷ್ಟು ಬೇಗ ಇಮೇಲ್ ಮತ್ತು ತತ್‌ಕ್ಷಣ ಚಾಟ್ ಮಾಡಿ",
  "email.and.telephonic":
    "ಸಾಧ್ಯವಾದಷ್ಟು ಬೇಗ ಇಮೇಲ್, ತತ್‌ಕ್ಷಣ ಚಾಟ್ ಮತ್ತು ಟೆಲಿಫೋನಿಕ್",
  "email.account.manager":
    "ಇಮೇಲ್, ತತ್‌ಕ್ಷಣ ಚಾಟ್, ಟೆಲಿಫೋನಿಕ್ ಮತ್ತು ಖಾತೆ ನಿರ್ವಾಹಕರನ್ನು ನಿಯೋಜಿಸಲಾಗುವುದು",
  testimonialsHeading: "ನಾವು ಮಾಡುವ ಕೆಲಸವನ್ನು ನಮ್ಮ ಗ್ರಾಹಕರು ಇಷ್ಟಪಡುತ್ತಾರೆ",
  testimonialsSubHeading:
    "ನಮ್ಮ ಗ್ರಾಹಕರು ನಮ್ಮ ಸೇವೆಗಳೊಂದಿಗೆ ನಮಗೆ ಸ್ಮೈಲ್‌ಗಳನ್ನು ಕಳುಹಿಸುತ್ತಾರೆ ಮತ್ತು ನಾವು ಅವರನ್ನು ಪ್ರೀತಿಸುತ್ತೇವೆ",
  "select.language.heading": "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  "my.account": "ನನ್ನ ಖಾತೆ",
  "logout.text": "ಲಾಗ್ ಔಟ್",
  "privacy.policy": "ಗೌಪ್ಯತಾ ನೀತಿ",
  "pricing.comparison.heading": "ನಮ್ಮನ್ನು ಏಕೆ ಆರಿಸಬೇಕು?",
  "pricing.comparison.subheading":
    "ಇತರರೊಂದಿಗೆ ನಮ್ಮ ವೈಶಿಷ್ಟ್ಯದ ಹೋಲಿಕೆಯನ್ನು ನೋಡಿ",
  "compare.with.others": "ಇತರರೊಂದಿಗೆ ಹೋಲಿಸಿ",
  customDomain: "ಕಸ್ಟಮ್ ಡೊಮೇನ್",
  onlineStore: "ಅಂತರ್ಜಾಲ ಮಾರುಕಟ್ಟೆ",
  catalogue: "ಕ್ಯಾಟಲಾಗ್",
  payments: "ಪಾವತಿಗಳು",
  marketingOffers: "ಮಾರ್ಕೆಟಿಂಗ್ ಮತ್ತು ಕೊಡುಗೆಗಳು",
  orderManagement: "ಆದೇಶ ನಿರ್ವಹಣೆ",
  ratingsReviews: "ರೇಟಿಂಗ್‌ಗಳು ಮತ್ತು ವಿಮರ್ಶೆಗಳು",
  support: "ಇಮೇಲ್‌ಗಳು, ಚಾಟ್‌ಗಳು ಮತ್ತು ಫೋನ್ ಕರೆಗಳ ಮೂಲಕ ಬೆಂಬಲ",
  salesChannels: "ಮಾರಾಟದ ಚಾನೆಲ್‌ಗಳು",
  orderCreation: "ಹಸ್ತಚಾಲಿತ ಆದೇಶ ರಚನೆ",
  discounts: "ಉತ್ಪನ್ನ ರಿಯಾಯಿತಿ ಮತ್ತು ಕೂಪನ್ ರಿಯಾಯಿತಿಗಳು",
  ssl: "SSL ಪ್ರಮಾಣಪತ್ರ",
  cart: "ಕೈಬಿಟ್ಟ ಬಂಡಿ",
  giftCards: "ಗಿಫ್ಟ್ ಕಾರ್ಡ್‌ಗಳು",
  reportsAnalytics: "ವರದಿಗಳು ಮತ್ತು ವಿಶ್ಲೇಷಣೆಗಳು",
  reportAnalytics: "ವರದಿಗಳು ಮತ್ತು ವಿಶ್ಲೇಷಣೆಗಳು",
  yes: "ಹೌದು",
  no: "ಸಂ",
  limited: "ಸೀಮಿತಗೊಳಿಸಲಾಗಿದೆ",
  unlimited: "ಅನಿಯಮಿತ",
  hostingSsl: "ಹೋಸ್ಟಿಂಗ್ ಮತ್ತು SSL ಪ್ರಮಾಣಪತ್ರ",
  themes: "ಥೀಮ್‌ಗಳು",
  customerSignup: "ಗ್ರಾಹಕ ಸೈನ್ ಅಪ್ ಪುಟ",
  multiStore: "ಬಹು ಮಳಿಗೆಗಳು",
  multiSales: "ಬಹು ಮಾರಾಟದ ಚಾನಲ್",
  products: "ಉತ್ಪನ್ನಗಳು",
  collectionTags: "ಸಂಗ್ರಹಣೆಗಳು ಮತ್ತು ಟ್ಯಾಗ್‌ಗಳು",
  weightBasedPrices: "ತೂಕ ಆಧಾರಿತ ಬೆಲೆಗಳು",
  productSubstitution: "ಉತ್ಪನ್ನ ಪರ್ಯಾಯಗಳು",
  stockManagement: "ಸ್ಟಾಕ್ ನಿರ್ವಹಣೆ",
  rackManagement: "ರ್ಯಾಕ್ ನಿರ್ವಹಣೆ",
  gatewayInteg: "ಗೇಟ್ವೇ ಏಕೀಕರಣ",
  walletCashback: "ವಾಲೆಟ್‌ಗಳು ಮತ್ತು ಕ್ಯಾಶ್‌ಬ್ಯಾಕ್‌ಗಳು",
  cashOnDelivery: "ತಲುಪಿದಾಗ ಹಣ ಪಾವತಿ",
  voucherGifts: "ವೋಚರ್‌ಗಳು/ ಗಿಫ್ಟ್ ಕಾರ್ಡ್‌ಗಳು",
  campaigns: "ಪ್ರಚಾರಗಳು (ಇಮೇಲ್/ SMS)",
  notifications: "ಆಪ್ ಸೂಚನೆಗಳು",
  customSeo: "ಕಸ್ಟಮ್ SEO",
  coupons: "ಕೂಪನ್‌ಗಳು",
  offers: "ಉತ್ಪನ್ನಗಳು/ ಸಂಗ್ರಹಣೆಗಳು/ ಟ್ಯಾಗ್‌ಗಳ ಮೇಲಿನ ಕೊಡುಗೆಗಳು",
  manualOrder: "ಹಸ್ತಚಾಲಿತ ಆದೇಶ ರಚನೆ",
  pickerApp: "ಪಿಕರ್ ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್",
  intelligentPicking: "ಬುದ್ಧಿವಂತ ಪಿಕಿಂಗ್ ಕ್ಯೂ",
  deliveryManagement: "ವಿತರಣಾ ನಿರ್ವಹಣೆ",
  surcharge: "ಸರ್ಚಾರ್ಜ್",
  slotCharges: "ಸ್ಲಾಟ್ ಆಧಾರಿತ ಶುಲ್ಕಗಳು",
  vehiclePlanning: "ವಾಹನ ಯೋಜನೆ",
  routePlanning: "ಮಾರ್ಗ ಯೋಜನೆ",
  deliveryPersonTracking: "ವಿತರಣಾ ವ್ಯಕ್ತಿ ಟ್ರ್ಯಾಕಿಂಗ್",
  thirdPartyLogistics: "ಥರ್ಡ್-ಪಾರ್ಟಿ ಲಾಜಿಸ್ಟಿಕ್ಸ್ ಇಂಟಿಗ್ರೇಷನ್",
  staffManagement: "ಸಿಬ್ಬಂದಿ ನಿರ್ವಹಣೆ",
  multiUsers: "ಬಹು-ಬಳಕೆದಾರರು",
  employeeMobileApp: "ಉದ್ಯೋಗಿ ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್",
  attendanceSummary: "ಹಾಜರಾತಿ ಸಾರಾಂಶ",
  roleBasedPermissions: "ಪಾತ್ರ-ಆಧಾರಿತ ಅನುಮತಿಗಳು",
  reports: "ವರದಿಗಳು",
  analytics: "ವಿಶ್ಲೇಷಣೆ",
  monthlyFixedCharges: "ಮಾಸಿಕ ಸ್ಥಿರ ಶುಲ್ಕಗಳು",
  commissions: "ಆಯೋಗಗಳು",
  freePlugins: "ಉಚಿತ ಪ್ಲಗಿನ್‌ಗಳು",
  customerMobileApp: "ಗ್ರಾಹಕ ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್ (iOS ಮತ್ತು Android)",
  "contact.help.banner.question": "ನಾವು ನಿಮಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡಬಹುದು?",
  "contact.help.banner.tagline":
    "ನಿಮ್ಮ ಸಂಪರ್ಕ ವಿವರಗಳನ್ನು ಕೆಳಗೆ ಬಿಡಿ ಮತ್ತು ನಮ್ಮ ಇ-ಕಾಮರ್ಸ್ ಪರಿಣಿತರು ನಿಮ್ಮೊಂದಿಗೆ ಸಾಧ್ಯವಾದಷ್ಟು ಬೇಗ ಸಂಪರ್ಕದಲ್ಲಿರುತ್ತಾರೆ",
  "contact.placeholder.fullname": "ಪೂರ್ಣ ಹೆಸರು",
  "contact.placeholder.phone": "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
  "contact.placeholder.email": "ಇಮೇಲ್ ವಿಳಾಸ",
  "contact.placeholder.message": "ಸಂದೇಶ",
  "contact.reachout": "ತ್ವರಿತ ಪ್ರತಿಕ್ರಿಯೆಗಾಗಿ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
  "contact.reachout.question": "ಪ್ರಶ್ನೆ ಇದೆಯೇ? ನಮ್ಮೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿರಿ",
  "contact.chat": "ನಮ್ಮೊಂದಿಗೆ ಚಾಟ್ ಮಾಡಿ",
  "map.message": "ನಾವು ಇಲ್ಲಿಯೇ ಇದ್ದೇವೆ",
  "map.viewmap": "ನಕ್ಷೆಯಲ್ಲಿ ವೀಕ್ಷಿಸಿ",
  "zopping.welcome": "Zopping ಗೆ ಸುಸ್ವಾಗತ",
  "zopping.loginForm.subheading": "ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಕೆಳಗೆ ನಮೂದಿಸಿ",
  "loginForm.username.placeholder": "ನಿಮ್ಮ ಇಮೇಲ್ ಅಥವಾ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
  "feature.one.description":
    "ಉಚಿತವಾಗಿ ಪ್ರಾರಂಭಿಸಿ ಮತ್ತು ನೀವು ಬಳಸುವುದಕ್ಕೆ ಮಾತ್ರ ಪಾವತಿಸಿ",
  "feature.two.description":
    "ಎಲ್ಲಾ ವೈಶಿಷ್ಟ್ಯಗಳು ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ಯಾವುದೇ ಹೆಚ್ಚುವರಿ ವೆಚ್ಚವಿಲ್ಲದೆ ಸಂಪೂರ್ಣವಾಗಿ ಅನ್‌ಲಾಕ್ ಮಾಡಲಾಗಿದೆ",
  "feature.three.description":
    "ನಿಮ್ಮ ಮಾಸಿಕ ಆನ್‌ಲೈನ್ ಮಾರಾಟವನ್ನು ಅನುಸರಿಸುವ ವೇರಿಯಬಲ್ ಮಾಸಿಕ ಶುಲ್ಕ",
  "highlight.heading": "ನೀವು ಏನು ಪಡೆಯುತ್ತೀರಿ",
  "monthly.store.sales": "ಮಾಸಿಕ ಆನ್‌ಲೈನ್ ಅಂಗಡಿ ಮಾರಾಟ",
  "charges.per.month": "ತಿಂಗಳಿಗೆ ಶುಲ್ಕಗಳು",
  "setup.store.free": "ನಿಮ್ಮ ಅಂಗಡಿಯನ್ನು ಉಚಿತವಾಗಿ ಹೊಂದಿಸಿ!",
  password: "ಗುಪ್ತಪದ",
  "loginForm.password.placeholder": "ನಿಮ್ಮ ಗುಪ್ತಪದವನ್ನು ನಮೂದಿಸಿ",
  "remember.me": "ನನ್ನನ್ನು ನೆನಪಿನಲ್ಲಿ ಇಡು",
  "dont.have.account": "ಖಾತೆಯನ್ನು ಹೊಂದಿಲ್ಲ",
  "create.account": "ಖಾತೆಯನ್ನು ತೆರೆಯಿರಿ",
  name: "ಹೆಸರು",
  "enter.name": "ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  "create.your.account": "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ರಚಿಸಿ",
  "creating.account": "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ರಚಿಸಲಾಗುತ್ತಿದೆ",
  "already.have.account": "ಈಗಾಗಲೇ ಖಾತೆ ಹೊಂದಿದ್ದೀರ",
  "forgot.password": "ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ?",
  "submit.text": "ಸಲ್ಲಿಸು",
  submitting: "ಸಲ್ಲಿಸಲು",
  "resetPassword.successMessage":
    "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರುಹೊಂದಿಸಲಾಗಿದೆ",
  "resetPassword.loginLinkText": "ಲಾಗಿನ್‌ಗೆ ಹೋಗಿ",
  "can.try.zopping": "ನಾನು ಪಾವತಿಸದೆ ಜೋಪಿಂಗ್ ಮಾಡಲು ಪ್ರಯತ್ನಿಸಬಹುದೇ?",
  "sign.up.free.trial":
    "ಹೌದು. ಕೇವಲ ಸೈನ್ ಅಪ್ ಮಾಡಿ ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನ 15 ದಿನಗಳ ಉಚಿತ ಪ್ರಯೋಗವನ್ನು ಪಡೆದುಕೊಳ್ಳಿ.",
  "enter.credit.card":
    "ಪ್ರಯೋಗವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ನಾನು ನನ್ನ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಸಂಖ್ಯೆ ಅಥವಾ ಯಾವುದೇ ಇತರ ಪಾವತಿ ವಿವರಗಳನ್ನು ನಮೂದಿಸಬೇಕೇ?",
  "no.requirement.payment.details":
    "ಇಲ್ಲ. ಪ್ರಯೋಗವನ್ನು ತೆಗೆದುಕೊಳ್ಳಲು ಯಾವುದೇ ಪಾವತಿ ವಿವರಗಳನ್ನು ನಮೂದಿಸುವ ಅಗತ್ಯವಿಲ್ಲ. ನೀವು ಕೇವಲ ಸೈನ್-ಅಪ್ ಮಾಡಬಹುದು ಮತ್ತು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸಲು ಪ್ರಾರಂಭಿಸಬಹುದು",
  "trial.over": "ನನ್ನ ವಿಚಾರಣೆ ಮುಗಿದ ನಂತರ ಏನಾಗುತ್ತದೆ?",
  "continue.using.platform":
    "ನೀವು ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸುವುದನ್ನು ಮುಂದುವರಿಸಲು ಬಯಸಿದರೆ, ಕನಿಷ್ಠ ರೂ.2,000 ನೊಂದಿಗೆ ನಿಮ್ಮ ಇನ್-ಬಿಲ್ಟ್ ವ್ಯಾಲೆಟ್ ಅನ್ನು ರೀಚಾರ್ಜ್ ಮಾಡುವ ಮೂಲಕ ನೀವು ಪಾವತಿಸಬೇಕಾಗುತ್ತದೆ. ಪ್ರಾಯೋಗಿಕ ಅವಧಿ ಮುಗಿದ 15 ದಿನಗಳಲ್ಲಿ ನಿಮ್ಮ ವ್ಯಾಲೆಟ್ ಅನ್ನು ನೀವು ರೀಚಾರ್ಜ್ ಮಾಡದಿದ್ದರೆ, ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲಾಗುತ್ತದೆ.",
  "how.do.i.pay": "ನಾನು ಹೇಗೆ ಪಾವತಿಸಲಿ?",
  "account.wallet": "ಖಾತೆಯ ವ್ಯಾಲೆಟ್‌ನಲ್ಲಿ ನೀವು",
  "charge.admin.panel":
    "ಹಣವನ್ನು ಸಂಗ್ರಹಿಸಬಹುದು ಮತ್ತು ರೀಚಾರ್ಜ್ ಮಾಡಬಹುದು. ನಮ್ಮ ಪಾವತಿ ಗೇಟ್‌ವೇ ಎಲ್ಲಾ ಪ್ರಮಾಣಿತ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್‌ಗಳು, ಡೆಬಿಟ್ ಕಾರ್ಡ್‌ಗಳು, ಬ್ಯಾಂಕ್ ವರ್ಗಾವಣೆಗಳು, UPI ಮತ್ತು ಇ-ವ್ಯಾಲೆಟ್‌ಗಳನ್ನು ಸ್ವೀಕರಿಸುತ್ತದೆ.",
  "features.during.trial":
    "ಪ್ರಯೋಗದ ಸಮಯದಲ್ಲಿ ನಾನು ಯಾವ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಪಡೆಯುತ್ತೇನೆ?",
  "features.page": "ವೈಶಿಷ್ಟ್ಯಗಳ ಪುಟ",
  "addon.features": "ಆಡ್-ಆನ್ ವೈಶಿಷ್ಟ್ಯಗಳು ಯಾವುವು?",
  "addon.features.answer":
    "ಆಡ್-ಆನ್ ವೈಶಿಷ್ಟ್ಯಗಳು ವೈಯಕ್ತಿಕ ವ್ಯವಹಾರಗಳಿಗೆ ನಿರ್ದಿಷ್ಟವಾದ ಕಾರ್ಯಚಟುವಟಿಕೆಗಳಾಗಿವೆ ಮತ್ತು ಎಲ್ಲರಿಗೂ ಅಗತ್ಯವಿರುವುದಿಲ್ಲ. ನೀವು ಈ ಎಲ್ಲಾ ಕಾರ್ಯಗಳನ್ನು ವೀಕ್ಷಿಸಬಹುದು ಮತ್ತು ನಿಮ್ಮ ಅಗತ್ಯಕ್ಕೆ ಅನುಗುಣವಾಗಿ ಆನ್ ಮಾಡಬಹುದು.",
  "addon.features.charge": "ಆಡ್-ಆನ್ ವೈಶಿಷ್ಟ್ಯಗಳು ಶುಲ್ಕ ವಿಧಿಸಬಹುದೇ?",
  "addon.features.charge.answer":
    "ಹೆಚ್ಚಿನ ಆಡ್-ಆನ್ ವೈಶಿಷ್ಟ್ಯಗಳು ಉಚಿತವಾಗಿರುತ್ತವೆ ಮತ್ತು ಅವುಗಳನ್ನು ಬಳಸಲು ಪ್ರಾರಂಭಿಸಲು ನೀವು ಅವುಗಳನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಬೇಕಾಗುತ್ತದೆ. ಪಾವತಿಸಿದ ಆಡ್-ಆನ್ ವೈಶಿಷ್ಟ್ಯಗಳಿಗಾಗಿ, ವೈಯಕ್ತಿಕ ವೈಶಿಷ್ಟ್ಯದ ಬೆಲೆಗೆ ಅನುಗುಣವಾಗಿ ಪಾವತಿಯನ್ನು ನಿಮ್ಮ ಖಾತೆಯ ವ್ಯಾಲೆಟ್‌ನಿಂದ ಕಡಿತಗೊಳಿಸಲಾಗುತ್ತದೆ.",
  "turnoff.addon.features":
    "ನಾನು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಆಡ್-ಆನ್ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಆಫ್ ಮಾಡಬಹುದೇ?",
  "turnoff.addon.features.answer":
    "ಹೌದು. ಆದರೆ ಈ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಆಫ್ ಮಾಡುವಾಗ ನೀವು ಅತ್ಯಂತ ಜಾಗರೂಕರಾಗಿರಬೇಕು ಏಕೆಂದರೆ ನೀವು ಅವುಗಳ ಅನುಗುಣವಾದ ಡೇಟಾವನ್ನು ಶಾಶ್ವತವಾಗಿ ಕಳೆದುಕೊಳ್ಳಬಹುದು.",
  "remind.balance.low":
    "ನನ್ನ ಖಾತೆಯ ವ್ಯಾಲೆಟ್ ಬ್ಯಾಲೆನ್ಸ್ ಕಡಿಮೆಯಿದ್ದರೆ ನೀವು ನನಗೆ ನೆನಪಿಸುತ್ತೀರಾ?",
  "remind.balance.low.answer":
    "ಹೌದು. ನಿಮ್ಮ ಖಾತೆಯ ಬ್ಯಾಲೆನ್ಸ್ ರೂ.2000ಕ್ಕಿಂತ ಕಡಿಮೆಯಿದ್ದರೆ ನಾವು ನಿಮಗೆ sms ಮತ್ತು ಇಮೇಲ್ ಮೂಲಕ ಬಹು ಜ್ಞಾಪನೆಗಳನ್ನು ಕಳುಹಿಸುತ್ತೇವೆ.",
  "negative.balance.wallet":
    "ಮಾಸಿಕ ಶುಲ್ಕಗಳನ್ನು ಪಾವತಿಸಲು ನನ್ನ ಖಾತೆಯ ವಾಲೆಟ್ ಬ್ಯಾಲೆನ್ಸ್ ಸಾಕಾಗದೇ ಇದ್ದಾಗ ಏನಾಗುತ್ತದೆ?",
  "negative.balance.reminder":
    "ನಿಮ್ಮ ವ್ಯಾಲೆಟ್‌ನಲ್ಲಿ ಸಾಕಷ್ಟು ಹಣವಿಲ್ಲದಿರುವ ಬಗ್ಗೆ ಇಮೇಲ್, ಪುಶ್ ಅಧಿಸೂಚನೆಗಳು ಮತ್ತು SMS ನಂತಹ ಬಹು ಚಾನೆಲ್‌ಗಳ ಮೂಲಕ ನಾವು ನಿಮ್ಮನ್ನು ಎಚ್ಚರಿಸುತ್ತೇವೆ ಮತ್ತು ನಿಮ್ಮ ವ್ಯಾಲೆಟ್ ಅನ್ನು ರೀಚಾರ್ಜ್ ಮಾಡಲು ವಿನಂತಿಸುತ್ತೇವೆ.",
  "negative.balance.case.one":
    "ಪ್ರಕರಣ 1: ವಾಲೆಟ್ ಬ್ಯಾಲೆನ್ಸ್ ನಿರಂತರವಾಗಿ 15 ದಿನಗಳಿಗಿಂತ ಹೆಚ್ಚು ಕಾಲ ಋಣಾತ್ಮಕವಾಗಿದ್ದರೆ ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗುತ್ತದೆ ಅಂದರೆ ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಡೌನ್ ಆಗಿರುತ್ತದೆ, ಆದರೂ ನೀವು ನಿಮ್ಮ ಖಾತೆ-ನಿರ್ವಾಹಕರನ್ನು ಪ್ರವೇಶಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ. ನಿಮ್ಮ ವ್ಯಾಲೆಟ್ ಅನ್ನು ನೀವು ರೀಚಾರ್ಜ್ ಮಾಡಿದ ತಕ್ಷಣ ಮತ್ತು ಬ್ಯಾಲೆನ್ಸ್ ಅನ್ನು ಧನಾತ್ಮಕವಾಗಿ ಮಾಡಿದ ತಕ್ಷಣ, ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಮತ್ತೆ ಲೈವ್ ಆಗುತ್ತದೆ.",
  "negative.balance.case.two":
    "ಪ್ರಕರಣ 2: ವಾಲೆಟ್ ಬ್ಯಾಲೆನ್ಸ್ ನಿರಂತರವಾಗಿ 60 ದಿನಗಳಿಗಿಂತ ಹೆಚ್ಚು ಋಣಾತ್ಮಕವಾಗಿದ್ದರೆ ಖಾತೆಯನ್ನು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಅಳಿಸಲಾಗುತ್ತದೆ ಅಂದರೆ ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಮತ್ತು ನಿರ್ವಾಹಕ ಖಾತೆ ಎರಡನ್ನೂ ಅಳಿಸಲಾಗುತ್ತದೆ. ನಿಮ್ಮ ಎಲ್ಲಾ ಡೇಟಾ ಶಾಶ್ವತವಾಗಿ ಕಳೆದುಹೋಗುತ್ತದೆ.",
  "stop.using.platform":
    "ನಾನು ನಿಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಬಳಸುವುದನ್ನು ನಿಲ್ಲಿಸಲು ಬಯಸಿದರೆ, ನಾನು ನನ್ನ ವ್ಯಾಲೆಟ್-ಹಣವನ್ನು ಮರಳಿ ಪಡೆಯುತ್ತೇನೆಯೇ?",
  "stop.using.platform.answer":
    "ಹೌದು. ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನೀವು ನಮ್ಮ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಬಳಸುವುದನ್ನು ನಿಲ್ಲಿಸಲು ಬಯಸುತ್ತೀರಿ ಎಂದು ನಮಗೆ ಸಂವಹನ ಮಾಡಬಹುದು. ಚಾಲನೆಯಲ್ಲಿರುವ ತಿಂಗಳ ಬಾಡಿಗೆ ಪಾವತಿ ಮತ್ತು ಇಲ್ಲಿಯವರೆಗಿನ ಬಾಕಿಗಳನ್ನು ಕಡಿತಗೊಳಿಸಿದ ನಂತರ ನಾವು ನಿಮ್ಮ ವ್ಯಾಲೆಟ್ ಮೊತ್ತವನ್ನು ಮರುಪಾವತಿ ಮಾಡುತ್ತೇವೆ.",
  install: "ಸ್ಥಾಪಿಸಿ",
  installing: "ಸ್ಥಾಪಿಸಲಾಗುತ್ತಿದೆ",
  uninstall: "ಅನ್‌ಇನ್‌ಸ್ಟಾಲ್ ಮಾಡಿ",
  uninstalling: "ಅನ್‌ಇನ್‌ಸ್ಟಾಲ್ ಮಾಡಲಾಗುತ್ತಿದೆ",
  installed: "ಸ್ಥಾಪಿಸಲಾಗಿದೆ",
  "registered.user.text": "ನೀವು ಮೊದಲೇ ನೊಂದಾಯಿಸಿದ್ದೀರಿ",
  "zopping.features": "ಜೋಪಿಂಗ್ ವೈಶಿಷ್ಟ್ಯಗಳು",
  "feature.description":
    "Zopping ಮಾಡಬಹುದಾದ ಎಲ್ಲಾ ಅದ್ಭುತ ವಿಷಯಗಳ ಬಗ್ಗೆ ಉತ್ತಮ ತಿಳುವಳಿಕೆಯನ್ನು ಪಡೆಯಿರಿ.",
  "login.or.text": "ಅಥವಾ",
  "login.with.google": "Google ನೊಂದಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ",
  "signup.with.google": "Google ನೊಂದಿಗೆ ಸೈನ್ ಅಪ್ ಮಾಡಿ",
  free: "ಉಚಿತ",
  customizable: "ಗ್ರಾಹಕೀಯಗೊಳಿಸಬಹುದಾದ",
  standard: "ಪ್ರಮಾಣಿತ",
  premium: "ಪ್ರೀಮಿಯಂ",
  "stay.in.touch": "ಸಂಪರ್ಕದಲ್ಲಿರಿ",
  "about.us": "ನಮ್ಮ ಬಗ್ಗೆ",
  "company.text": "ಕಂಪನಿ",
  "blog.text": "ಬ್ಲಾಗ್",
  "terms.conditions": "ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು",
  "hidden.costs": "ಯಾವುದೇ ಗುಪ್ತ ವೆಚ್ಚಗಳಿವೆಯೇ?",
  "hidden.costs.answer":
    "ಇಲ್ಲ. ಯಾವುದೂ ಇಲ್ಲ. ಎಲ್ಲಾ ವೈಶಿಷ್ಟ್ಯಗಳು, ಥೀಮ್‌ಗಳು, ವಿಸ್ತರಣೆಗಳು ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್‌ಗಳು ಸಂಪೂರ್ಣವಾಗಿ ಉಚಿತವಾಗಿದೆ. ಆದಾಗ್ಯೂ, ನೀವು ನಿಮ್ಮ ಸ್ವಂತ ಡೊಮೇನ್ ಹೆಸರನ್ನು ಆರಿಸಿದರೆ, ನಂತರ ನೀವು ನಿಮ್ಮ ಡೊಮೇನ್ ಪೂರೈಕೆದಾರರಿಗೆ ಪಾವತಿಸಬೇಕಾಗಬಹುದು. ನಿಮ್ಮ ಮತ್ತು ಸಂಬಂಧಿತ ಪಕ್ಷಗಳ ನಡುವೆ ಒಪ್ಪಂದ ಮಾಡಿಕೊಂಡಿರುವಂತೆ ವಿತರಣಾ ಶುಲ್ಕಗಳು ಮತ್ತು ಪಾವತಿ ಗೇಟ್‌ವೇ ಶುಲ್ಕಗಳು ನಮ್ಮ ಬೆಲೆ ಯೋಜನೆಯಲ್ಲಿ ಒಳಗೊಂಡಿಲ್ಲ ಮತ್ತು ಆದ್ದರಿಂದ ನೀವು ಭರಿಸಬೇಕಾಗುತ್ತದೆ.",
  "cost.online.store":
    "ನನ್ನ ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ ಅನ್ನು ಹೊಂದಿಸಲು ಎಷ್ಟು ವೆಚ್ಚವಾಗುತ್ತದೆ?",
  "cost.online.store.answer":
    "ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ ಅನ್ನು ಹೊಂದಿಸಲು ಇದು ಸಂಪೂರ್ಣವಾಗಿ ಉಚಿತವಾಗಿದೆ. ನೀವು ಕನಿಷ್ಟ ರೂ.ಗಳನ್ನು ಹೊಡೆಯುವವರೆಗೆ ನೀವು ನಮಗೆ ಏನನ್ನೂ ಪಾವತಿಸುವ ಅಗತ್ಯವಿಲ್ಲ. ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ ಮೂಲಕ ತಿಂಗಳಿಗೆ 20000 ಮೌಲ್ಯದ ಆರ್ಡರ್‌ಗಳು. ಅಲ್ಲಿಂದ, ಶುಲ್ಕಗಳು ಮೇಲೆ ತಿಳಿಸಿದ ಬೆಲೆ ಯೋಜನೆಯನ್ನು ಆಧರಿಸಿವೆ. ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಅಂಗಡಿಯ ಮೂಲಕ ನಿಮ್ಮ ಮಾಸಿಕ ಮಾರಾಟವು ಪ್ರತಿ ತಿಂಗಳು ಬದಲಾಗುವುದರಿಂದ, ನಿಮ್ಮ ಮಾಸಿಕ ಶುಲ್ಕಗಳು ಕೂಡಾ ಬದಲಾಗುತ್ತವೆ.",
  "highlight.hosting": "ಹೋಸ್ಟಿಂಗ್ (ಝೋಪಿಂಗ್ ಸಬ್ಡೊಮೈನ್) ಮತ್ತು SSL ಪ್ರಮಾಣಪತ್ರ",
  "highlight.themes": "ಸಂಪೂರ್ಣವಾಗಿ ಗ್ರಾಹಕೀಯಗೊಳಿಸಬಹುದಾದ ಥೀಮ್‌ಗಳು",
  "highlight.product.listing": "ಅನಿಯಮಿತ ಉತ್ಪನ್ನ ಪಟ್ಟಿಗಳು",
  "highlight.unlimited.users": "ಅನಿಯಮಿತ ಬಳಕೆದಾರರು",
  "highlight.unlimited.operations": "ಅನಿಯಮಿತ ಬಹು-ಅಂಗಡಿ ಕಾರ್ಯಾಚರಣೆಗಳು",
  "highlight.apps":
    "ಗ್ರಾಹಕ ಅಪ್ಲಿಕೇಶನ್, ಪಿಕರ್ ಅಪ್ಲಿಕೇಶನ್, ವಿತರಣಾ ಅಪ್ಲಿಕೇಶನ್ ಮತ್ತು ನಿರ್ವಾಹಕ ಅಪ್ಲಿಕೇಶನ್",
  "highlight.extensions": "ಎಲ್ಲಾ ಪ್ಲಗಿನ್‌ಗಳು/ವಿಸ್ತರಣೆಗಳು",
  "highlight.gateway.integrations": "ಪಾವತಿ ಗೇಟ್‌ವೇ ಸಂಯೋಜನೆಗಳು",
  "highlight.delivery.partner": "ವಿತರಣಾ ಪಾಲುದಾರ ಏಕೀಕರಣಗಳು",
  "highlight.social.media": "ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಮತ್ತು Google API ಸಂಯೋಜನೆಗಳು",
  "highlight.customer.support": "24x7 ಗ್ರಾಹಕ ಬೆಂಬಲ",
  "less.than": "ಕಡಿಮೆ",
  "email.error.msg":
    "ದಯವಿಟ್ಟು ಸರಿಯಾದ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ. ಉದಾ-abc@xyz.com",
  "anyone.can.sell.anything": "ಯಾರಾದರೂ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಏನನ್ನೂ ಮಾರಾಟ ಮಾಡಬಹುದು!",
  "brands.retailers.resellers":
    "ಬ್ರ್ಯಾಂಡ್‌ಗಳಿಂದ ಹಿಡಿದು ಚಿಲ್ಲರೆ ವ್ಯಾಪಾರಿಗಳಿಂದ ಮರುಮಾರಾಟಗಾರರಿಂದ ಆನ್‌ಲೈನ್-ಮೊದಲ ಉದ್ಯಮಿಗಳವರೆಗೆ ಯಾರಾದರೂ ಸ್ಥಾಪಿಸಬಹುದು ಮತ್ತು ಚಲಾಯಿಸಬಹುದು",
  "matter.few.minutes": "ಕೆಲವೇ ನಿಮಿಷಗಳಲ್ಲಿ",
  "setup.free.online.store": "ನಿಮ್ಮ ಉಚಿತ ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ ಅನ್ನು ಹೊಂದಿಸಿ",
  "schedule.demo": "ಡೆಮೊವನ್ನು ನಿಗದಿಪಡಿಸಿ",
  "zero.web.dev.skills": "ಶೂನ್ಯ ವೆಬ್ ಅಭಿವೃದ್ಧಿ ಕೌಶಲ್ಯಗಳು ಅಗತ್ಯವಿದೆ",
  "payments.handle.deliveries":
    "ಪಾವತಿಗಳು, ವಿತರಣೆಗಳನ್ನು ನಿರ್ವಹಿಸಿ, ನಿಮ್ಮ ಬ್ರ್ಯಾಂಡ್ ಅನ್ನು ಪ್ರಚಾರ ಮಾಡಿ ಮತ್ತು ನಮ್ಮ ಅರ್ಥಗರ್ಭಿತ ಮತ್ತು ಗ್ರಾಹಕೀಯಗೊಳಿಸಬಹುದಾದ ಇಂಟರ್ಫೇಸ್ ಅನ್ನು ಬಳಸಿಕೊಂಡು ಸುಲಭವಾಗಿ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಬೆಳೆಯಿರಿ.",
  "your.store.customers.rules":
    "ನಿಮ್ಮ ಅಂಗಡಿ, ನಿಮ್ಮ ಗ್ರಾಹಕರು, ನಿಮ್ಮ ಅಂಚುಗಳು, ನಿಮ್ಮ ನಿಯಮಗಳು",
  "experience.online.marketplaces":
    "Amazon, Flipkart, Udaan, AliExpress, eBay, ಇತ್ಯಾದಿಗಳಂತಹ ಆನ್‌ಲೈನ್ ಮಾರುಕಟ್ಟೆಗಳ ಮೂಲಕ ಮಾರಾಟ ಮಾಡುವ ಅನುಭವದಿಂದ ನಿರಾಶೆಗೊಂಡಿದ್ದೀರಾ ಮತ್ತು ನಿಮ್ಮ ಪ್ರತಿಸ್ಪರ್ಧಿಗಳಿಗೆ ನಿಮ್ಮ ಹಾರ್ಡ್ ಗೆದ್ದ ಗ್ರಾಹಕರನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತೀರಾ? ನಿಮ್ಮ ಸ್ವಂತ ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ ಮತ್ತು ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ, ನಿಮ್ಮ ಬ್ರ್ಯಾಂಡ್ ಮತ್ತು ಗ್ರಾಹಕರ ಅನುಭವವನ್ನು ನೀವು ನಿಯಂತ್ರಿಸಬಹುದು, ನಿಮ್ಮ ಕಷ್ಟಪಟ್ಟು ಗಳಿಸಿದ ಮಾರ್ಜಿನ್‌ಗಳು ಮತ್ತು ಗ್ರಾಹಕರ ಡೇಟಾವನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ನಿಮ್ಮೊಂದಿಗೆ ಇರಿಸಿಕೊಳ್ಳಿ.",
  "explore.our.features": "ನಮ್ಮ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಅನ್ವೇಷಿಸಿ",
  "platform.specific.business":
    "ನಿಮ್ಮ ವ್ಯಾಪಾರದ ನಿರ್ದಿಷ್ಟ ಸ್ವರೂಪಕ್ಕಾಗಿ ನಿರ್ಮಿಸಲಾದ ವೇದಿಕೆ",
  "handle.thousands.transactions":
    "ಒಂದು ದಿನದಲ್ಲಿ ಸಾವಿರಾರು ವಹಿವಾಟುಗಳನ್ನು ಮನಬಂದಂತೆ ನಿರ್ವಹಿಸುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ ವ್ಯಾಪಾರವನ್ನು ಹೆಚ್ಚಿಸಿ.",
  "explore.prices": "ಬೆಲೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ",
  "start.online.store":
    "ಕೆಲವು ಕ್ಲಿಕ್‌ಗಳೊಂದಿಗೆ ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ ಅನ್ನು ಪ್ರಾರಂಭಿಸಿ",
  "sell.groceries.medicines":
    "ದಿನಸಿ, ಔಷಧಿಗಳು, ಪೀಠೋಪಕರಣಗಳು, ಪುಸ್ತಕಗಳು, ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್, ಸೌಂದರ್ಯ ಉತ್ಪನ್ನಗಳು, ಆಭರಣಗಳು, ಬಟ್ಟೆಗಳು, ಕರಕುಶಲ ವಸ್ತುಗಳು, ಬೇಕರಿ ಉತ್ಪನ್ನಗಳು ಅಥವಾ ಯಾವುದನ್ನಾದರೂ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಮಾರಾಟ ಮಾಡಿ.",
  "store.information": "ಮಾಹಿತಿ ಸಂಗ್ರಹಿಸಿ",
  "enter.store.name.logo": "ನಿಮ್ಮ ಸ್ಟೋರ್ ಹೆಸರು ಮತ್ತು ಲೋಗೋ ನಮೂದಿಸಿ",
  "select.domain": "ಡೊಮೇನ್ ಆಯ್ಕೆಮಾಡಿ",
  "choose.store.url": "ನಿಮ್ಮ ಸ್ಟೋರ್ URL ಅನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  "select.industry": "ಉದ್ಯಮವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  "pick.business.operate": "ನೀವು ನಿರ್ವಹಿಸುವ ವ್ಯಾಪಾರವನ್ನು ಆರಿಸಿ",
  "select.theme": "ಥೀಮ್ ಆಯ್ಕೆಮಾಡಿ",
  "choose.theme.liking": "ನಿಮ್ಮ ಇಚ್ಛೆಯ ಥೀಮ್ ಅಥವಾ ಬಣ್ಣಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  "download.now": "ಈಗ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
  "unlock.ecommerce.power":
    "ಜೋಪಿಂಗ್ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಇ-ಕಾಮರ್ಸ್‌ನ ಶಕ್ತಿಯನ್ನು ಅನ್‌ಲಾಕ್ ಮಾಡಿ",
  "features.banner.one.description":
    "ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ ಅನ್ನು ಹೊಂದಿಸುವುದು ಮತ್ತು ಚಾಲನೆ ಮಾಡುವುದು ತುಂಬಾ ಸುಲಭವಾಗಿದೆ",
  "features.banner.two.description":
    "ಸ್ಥಾಪಿಸಬಹುದಾದ' ವಿಸ್ತರಣೆಗಳ ಹೋಸ್ಟ್ ಮೂಲಕ ಸುಧಾರಿತ ಗ್ರಾಹಕೀಕರಣಗಳು ಸಾಧ್ಯವಾಗಿದೆ",
  "features.banner.three.description":
    "Zopping ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್ ಮೂಲಕ ಎಲ್ಲಾ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಪ್ರವೇಶಿಸಿ",
  hosting: "ಹೋಸ್ಟಿಂಗ್",
  "hosting.desc":
    "ಸೈನ್ ಅಪ್ ಮಾಡುವಾಗ ಉಚಿತ ಹೋಸ್ಟಿಂಗ್‌ನೊಂದಿಗೆ ಉಚಿತ @zopping.com ಡೊಮೇನ್ ಪಡೆಯಿರಿ. ನೀವು ತಕ್ಷಣ ಅಥವಾ ನಂತರದ ದಿನಾಂಕದಲ್ಲಿ ನಿಮ್ಮ ಸ್ವಂತ ಡೊಮೇನ್‌ಗೆ ಹೋಗಬಹುದು.",
  "ssl.desc":
    "ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ಸುರಕ್ಷಿತ ಮತ್ತು ಸುರಕ್ಷಿತ ಶಾಪಿಂಗ್ ಅನುಭವವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ನಿಮ್ಮ ಡೊಮೇನ್‌ಗಾಗಿ ನಾವು ಉಚಿತ 256-ಬಿಟ್ SSL ಪ್ರಮಾಣಪತ್ರವನ್ನು ನೀಡುತ್ತೇವೆ.",
  "themes.desc":
    "ನಿಮ್ಮ ವ್ಯಾಪಾರದ ಸ್ವರೂಪಕ್ಕೆ ಹೊಂದಿಕೆಯಾಗುವ ಟೆಂಪ್ಲೇಟ್‌ಗಳ ನಮ್ಮ ಲೈಬ್ರರಿಯಿಂದ ಆರಿಸಿಕೊಳ್ಳಿ. ಪುಟ ವಿನ್ಯಾಸಗಳು, ಮೆನುಗಳು ಮತ್ತು ಬಣ್ಣಗಳನ್ನು ಕಸ್ಟಮೈಸ್ ಮಾಡಿ, ನಿಮ್ಮ ಅಂಗಡಿಯು ನಿಮ್ಮ ಬ್ರ್ಯಾಂಡ್ ಅನ್ನು ಪ್ರತಿಬಿಂಬಿಸಲು ಲೋಗೋಗಳು, ಫೆವಿಕಾನ್‌ಗಳು, ಸ್ಥಿರ ಪುಟಗಳು ಮತ್ತು ಬ್ಲಾಗ್‌ಗಳನ್ನು ಸೇರಿಸಿ. ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಅನ್ನು ಮುರಿಯದೆಯೇ ಥೀಮ್‌ಗಳ ನಡುವೆ ಸುಲಭವಾಗಿ ಬದಲಿಸಿ.",
  "search.box": "ಹುಡುಕಾಟ ಬಾಕ್ಸ್",
  "search.box.desc":
    "ನಮ್ಮ ಬುದ್ಧಿವಂತ ಮತ್ತು ವೈಯಕ್ತಿಕಗೊಳಿಸಿದ ಹುಡುಕಾಟ ಕಾರ್ಯವನ್ನು ಬಳಸಿಕೊಂಡು ನಿಮ್ಮ ಅಂಗಡಿಯಿಂದ ಉತ್ಪನ್ನಗಳನ್ನು ಹುಡುಕಲು ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ. ನಿರ್ದಿಷ್ಟ ಉತ್ಪನ್ನ/ವರ್ಗ/ಬ್ರ್ಯಾಂಡ್ ಅನ್ನು ಪ್ರಚಾರ ಮಾಡಲು ನಿರ್ದಿಷ್ಟ ಹುಡುಕಾಟ ಪದಗಳಿಗಾಗಿ ಹುಡುಕಾಟ ಫಲಿತಾಂಶಗಳನ್ನು ಹೊಂದಿಸಿ ಮತ್ತು ಸರಿಪಡಿಸಿ.",
  "multi.language": "ಬಹು-ಭಾಷೆ",
  "multi.language.desc":
    "ನಿಮ್ಮ eStore ನಲ್ಲಿ ನೀವು ಬೆಂಬಲಿಸಲು ಬಯಸುವ ಭಾಷೆಗಳನ್ನು ಹೊಂದಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಗ್ರಾಹಕರು ಅವರು ಆದ್ಯತೆ ನೀಡುವ ಭಾಷೆಯಲ್ಲಿ ನಿಮ್ಮ eStore ನಲ್ಲಿ ಬ್ರೌಸ್ ಮಾಡಲು ಮತ್ತು ಶಾಪಿಂಗ್ ಮಾಡಲು ಅನುಮತಿಸಿ.",
  "product.listing": "ಉತ್ಪನ್ನ ಪಟ್ಟಿ",
  "product.listing.desc":
    "ನಿಮ್ಮ ಉತ್ಪನ್ನಗಳನ್ನು ವರ್ಗಗಳು ಮತ್ತು ಬಹು-ಶ್ರೇಣಿಯ ಉಪ-ವರ್ಗಗಳಾಗಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ ಮತ್ತು ಸುಲಭವಾಗಿ ಗುಂಪು ಮಾಡಿ. ಅಂತರ್ನಿರ್ಮಿತ ಕಸ್ಟಮ್ ಕ್ಷೇತ್ರಗಳನ್ನು ಬಳಸಿಕೊಂಡು ಚಿತ್ರಗಳು, ವಿವರಣೆಗಳು, ಟ್ಯಾಗ್‌ಗಳು, ಸ್ಟಾಕ್, ಬೆಲೆಗಳು, ರಿಯಾಯಿತಿಗಳು, ತೆರಿಗೆ ದರಗಳು, ಸೆಸ್ ಮತ್ತು ಹೆಚ್ಚಿನದನ್ನು ಸೇರಿಸಿ. ಅನುಕೂಲಕರವಾಗಿ ನಿಮ್ಮ ಉತ್ಪನ್ನಗಳನ್ನು ಪ್ರತ್ಯೇಕವಾಗಿ ಅಥವಾ ದೊಡ್ಡ ಪ್ರಮಾಣದಲ್ಲಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ ಅಥವಾ ಸಂಪಾದಿಸಿ.",
  "sell.by.brand": "ಬ್ರ್ಯಾಂಡ್ ಮೂಲಕ ಮಾರಾಟ ಮಾಡಿ",
  "sell.by.brand.desc":
    "ಬ್ರ್ಯಾಂಡ್ ಹೆಸರಿನ ಆಧಾರದ ಮೇಲೆ ಉತ್ಪನ್ನಗಳನ್ನು ಹುಡುಕಲು ಮತ್ತು ಫಿಲ್ಟರ್ ಮಾಡಲು ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ಕ್ಯಾಟಲಾಗ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಉತ್ಪನ್ನಗಳಿಗೆ 'ಬ್ರಾಂಡ್' ಕ್ಷೇತ್ರವನ್ನು ಸೇರಿಸಿ.",
  "items.sold.by.weight": "ತೂಕದಿಂದ ಮಾರಾಟವಾದ ವಸ್ತುಗಳು",
  "items.sold.by-weight.desc":
    "Sಹಣ್ಣುಗಳು, ತರಕಾರಿಗಳು, ಧಾನ್ಯಗಳು, ಮಾಂಸ, ಲೋಹಗಳು ಇತ್ಯಾದಿಗಳಂತಹ ಸಡಿಲವಾದ ವಸ್ತುಗಳನ್ನು ಮತ್ತು ಮಾರಾಟದ ತೂಕದಿಂದ ಮಾರಾಟ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿ.",
  "product.variants": "ಉತ್ಪನ್ನ ರೂಪಾಂತರಗಳು",
  "product.variants.desc":
    "ಬಣ್ಣ, ಗಾತ್ರ, ತೂಕ ಇತ್ಯಾದಿಗಳ ಮೂಲಕ ವ್ಯತ್ಯಾಸಗಳನ್ನು ಹೊಂದಿರುವ ಉತ್ಪನ್ನಗಳನ್ನು ಮನಬಂದಂತೆ ನಿರ್ವಹಿಸಿ. ಪ್ರತಿ ರೂಪಾಂತರಕ್ಕೆ ಫೋಟೋಗಳು, ಬೆಲೆಗಳು ಮತ್ತು ರಿಯಾಯಿತಿಗಳನ್ನು ನವೀಕರಿಸಿ.",
  "product.subsitution": "ಉತ್ಪನ್ನ ಪರ್ಯಾಯ",
  "product.subsitution.desc":
    "ನಿಮ್ಮ ಉತ್ಪನ್ನಗಳಿಗೆ ಬದಲಿಗಳನ್ನು ಹೊಂದಿಸಿ ಮತ್ತು ಮೂಲತಃ ಆರ್ಡರ್ ಮಾಡಿದ ಉತ್ಪನ್ನವು ಲಭ್ಯವಿಲ್ಲದಿದ್ದಲ್ಲಿ ಬದಲಿ ಉತ್ಪನ್ನಗಳನ್ನು ಕಳುಹಿಸುವ ಮೂಲಕ ನಿಮ್ಮ ಭರ್ತಿ ದರವನ್ನು ಸುಧಾರಿಸಿ.",
  "stock.management.desc":
    "ಸ್ಟಾಕ್ ಡೇಟಾವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ ಅಥವಾ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ, ಬಫರ್ ಸ್ಟಾಕ್ ಅನ್ನು ಹೊಂದಿಸಿ ಮತ್ತು ಯಾವುದೇ ಉತ್ಪನ್ನವು ಸ್ಟಾಕ್ ಹೊರಗೆ ಹೋದಾಗ ಎಚ್ಚರಿಕೆಗಳನ್ನು ಪಡೆಯಿರಿ.",
  "payment.gateway.3rd.party": "3ನೇ-party ಪೇಮೆಂಟ್ ಗೇಟ್ ವೇ ಇಂಟಿಗ್ರೇಷನ್",
  "payment.gateway.3rd.party.desc":
    "ಪೂರ್ವ ಕಾನ್ಫಿಗರ್ 3 ಇಡೀ ಹೋಸ್ಟ್ ಲಾಭ RD ವ್ಯಕ್ತಿಯ ಪಾವತಿ ಗೇಟ್ವೇ ತ್ವರಿತವಾಗಿ ನಿಮ್ಮ ಪಾವತಿ ಯಾಂತ್ರಿಕ ಸ್ಥಾಪಿಸಲು. ನಿಮ್ಮ ವ್ಯಾಪಾರಿ ವಹಿವಾಟು ಶುಲ್ಕಗಳನ್ನು ಅತ್ಯುತ್ತಮವಾಗಿಸಲು ಬಹು ಗೇಟ್‌ವೇಗಳ ಮೂಲಕ ನಿಮ್ಮ ಪಾವತಿ ರೂಟಿಂಗ್ ಅನ್ನು ಬುದ್ಧಿವಂತಿಕೆಯಿಂದ ಸ್ವಯಂಚಾಲಿತಗೊಳಿಸಿ.",
  "paypal.integration": "PayPal ಇಂಟಿಗ್ರೇಷನ್",
  "paypal.integration.desc":
    "Aನಮ್ಮ ಅಂತರ್ನಿರ್ಮಿತ PayPal ಏಕೀಕರಣದೊಂದಿಗೆ ಅಂತರರಾಷ್ಟ್ರೀಯ ಆದೇಶಗಳು ಮತ್ತು ಪಾವತಿಗಳನ್ನು ಸ್ವೀಕರಿಸಿ.",
  "your.ewallet": "ನಿಮ್ಮ eWallet",
  "your.ewallet.desc":
    "ನಿಮ್ಮ ಗ್ರಾಹಕ ನಿಷ್ಠೆಯನ್ನು ಸುಧಾರಿಸಿ ಮತ್ತು ಗ್ರಾಹಕರಿಗೆ ನಿಮ್ಮ ಬ್ರಾಂಡೆಡ್ eWallet ಅನ್ನು ನೀಡುವ ಮೂಲಕ ಬಹುಮಾನ ನೀಡಿ. ನಿಮ್ಮ ಗ್ರಾಹಕರು ತಮ್ಮ ವ್ಯಾಲೆಟ್‌ಗಳಿಗೆ ಹಣವನ್ನು ಸೇರಿಸಬಹುದು ಮತ್ತು ಅವರ ಭವಿಷ್ಯದ ಖರೀದಿಯ ಸಮಯದಲ್ಲಿ ಅದನ್ನು ಬಳಸಬಹುದು.",
  "gift.cards.desc":
    "ಗ್ರಾಹಕೀಯಗೊಳಿಸಬಹುದಾದ ಇ-ಉಡುಗೊರೆ ಕಾರ್ಡ್‌ಗಳನ್ನು ಮಾರಾಟ ಮಾಡುವ ಮೂಲಕ ನಿಮ್ಮ ಗ್ರಾಹಕರು ತಮ್ಮ ಪ್ರೀತಿಪಾತ್ರರನ್ನು ಉಡುಗೊರೆಯಾಗಿ ನೀಡಲು ಸಕ್ರಿಯಗೊಳಿಸಿ ಅದನ್ನು ನಿಮ್ಮ ಅಂಗಡಿಯಲ್ಲಿ ಮಾತ್ರ ರಿಡೀಮ್ ಮಾಡಿಕೊಳ್ಳಬಹುದು.",
  cod: "ಕ್ಯಾಶ್ ಆನ್ ಡೆಲಿವರಿ (COD)",
  "cod.desc": "Oನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ COD ಕಾರ್ಯವನ್ನು ನೀಡಿ.",
  "webpages.and.banners": "ವೆಬ್‌ಪುಟಗಳು ಮತ್ತು ಬ್ಯಾನರ್‌ಗಳು",
  "webpages.and.banners.desc":
    "ಹೊಸ ಉತ್ಪನ್ನ ಬಿಡುಗಡೆಗಳು, ಕೊಡುಗೆಗಳು, ಕಾಲೋಚಿತ ಮತ್ತು ಹಬ್ಬದ ಮಾರಾಟಗಳು ಇತ್ಯಾದಿಗಳನ್ನು ಉತ್ತೇಜಿಸಲು ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್‌ನ ವಿವಿಧ ಪುಟಗಳಿಗಾಗಿ ಕಸ್ಟಮ್ ವೆಬ್ ಪುಟಗಳು ಮತ್ತು ವೆಬ್ ಬ್ಯಾನರ್‌ಗಳನ್ನು ರಚಿಸಿ ಮತ್ತು ನಿಗದಿಪಡಿಸಿ",
  "offers.management": "ಆಫರ್‌ಗಳ ನಿರ್ವಹಣೆ",
  "offers.management.desc":
    "ಉತ್ಪನ್ನಗಳು/ ವಿಭಾಗಗಳು/ ಬ್ರಾಂಡ್‌ಗಳು/ ಗ್ರಾಹಕರ ಮೇಲೆ (ಫ್ಲಾಟ್ ಆಫ್/ % ಆಫ್/ ಕನಿಷ್ಠ. ಖರೀದಿ/ ಕಾಂಬೊಸ್/ ಖರೀದಿಸಿ-ಒಂದು-ಪಡೆಯಿರಿ-ಒಂದು/% ಹೆಚ್ಚುವರಿ) ಆಫರ್‌ಗಳ 10+ ರೂಪಾಂತರಗಳನ್ನು ರಚಿಸಿ, ಕಸ್ಟಮೈಸ್ ಮಾಡಿ, ರನ್ ಮಾಡಿ ಮತ್ತು ಟ್ರ್ಯಾಕ್ ಮಾಡಿ ವಿಭಾಗಗಳು. ಕೊಡುಗೆಯ ಅನ್ವಯಕ್ಕಾಗಿ ನಿಮ್ಮ ಮಿತಿಗಳನ್ನು ಮತ್ತು ನಿಯಮಗಳನ್ನು ಹೊಂದಿಸಿ.",
  "campaign.management": "ಪ್ರಚಾರ ನಿರ್ವಹಣೆ",
  "campaign.management.desc":
    "ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ಅಪ್ಲಿಕೇಶನ್ ಅಧಿಸೂಚನೆಗಳು, ಇಮೇಲ್‌ಗಳು ಮತ್ತು SMS ಗಳ ಮೂಲಕ ಪ್ರಚಾರದ ಪ್ರಚಾರಗಳನ್ನು ರಚಿಸಿ, ನಿಗದಿಪಡಿಸಿ, ರನ್ ಮಾಡಿ ಮತ್ತು ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಿ. ಕೂಪನ್‌ಗಳನ್ನು ವಿತರಿಸಿ, ಉತ್ಪನ್ನದ ಉಡಾವಣೆಗಳನ್ನು ಪ್ರಕಟಿಸಿ, ಬೆಲೆ ಕುಸಿತವನ್ನು ಎಚ್ಚರಿಸಿ, ಇತ್ಯಾದಿ. ಉದ್ದೇಶಿತ ಪ್ರಚಾರಗಳನ್ನು ನಡೆಸಲು ಅಂತರ್ನಿರ್ಮಿತ ಗ್ರಾಹಕ ವಿಭಾಗೀಕರಣ ಸಾಧನವನ್ನು ಬಳಸಿ.",
  "coupons.desc":
    "ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ಉತ್ಪನ್ನ ಅಥವಾ ಶಿಪ್ಪಿಂಗ್ ರಿಯಾಯಿತಿಗಳನ್ನು ನೀಡಲು ಅನನ್ಯ ಅಥವಾ ಪ್ರಮಾಣಿತ ಕೂಪನ್‌ಗಳನ್ನು ರಚಿಸಿ ಮತ್ತು ವಿತರಿಸಿ. ರಿಯಾಯಿತಿ ಮಿತಿಗಳನ್ನು ಹೊಂದಿಸಿ ಮತ್ತು ಆರ್ಡರ್ ಮೌಲ್ಯ/ಪಾವತಿ ಆಯ್ಕೆಗಳು/ಆರ್ಡರ್ ಡೇ/ ಗ್ರಾಹಕ ವಿಭಾಗ/ ಅಂಗಡಿಯ ಆಧಾರದ ಮೇಲೆ ಕೂಪನ್‌ನ ಅನ್ವಯವನ್ನು ನಿರ್ಬಂಧಿಸಿ. ಕೂಪನ್‌ಗಳ ವಿತರಣೆ ಮತ್ತು ಪರಿಣಾಮವಾಗಿ ಮಾರಾಟದ ಯಶಸ್ಸನ್ನು ಅಳೆಯಿರಿ ಮತ್ತು ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಿ.",
  seo: "ಸರ್ಚ್ ಇಂಜಿನ್ ಆಪ್ಟಿಮೈಸೇಶನ್ (ಎಸ್‌ಇಒ)",
  "seo.desc":
    "ನಿಮ್ಮ ಗ್ರಾಹಕರು ಇಂಟರ್ನೆಟ್‌ನಲ್ಲಿ ನಿಮಗಾಗಿ ಅಥವಾ ನಿಮ್ಮ ಉತ್ಪನ್ನಗಳನ್ನು ಹುಡುಕಿದಾಗ ಅವರು ಕಂಡುಹಿಡಿಯಿರಿ. Google ಹುಡುಕಾಟಗಳಲ್ಲಿ ಹೆಚ್ಚು ಕಾಣಿಸಿಕೊಳ್ಳಲು ನಿಮ್ಮ ಪುಟದ ಶೀರ್ಷಿಕೆಗಳು, ವಿವರಣೆಗಳು ಮತ್ತು ಕೀವರ್ಡ್‌ಗಳನ್ನು ಹೊಂದಿಸಿ.",
  "orders.dashboard": "ಆರ್ಡರ್‌ಗಳ ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
  "orders.dashboard.desc":
    "ಆರ್ಡರ್ ಸಂಖ್ಯೆ, ಗ್ರಾಹಕರ ಹೆಸರು, ಸಂಪರ್ಕ ವಿವರಗಳು, ಆರ್ಡರ್ ಸಮಯ, ಆರ್ಡರ್ ಸ್ಥಿತಿ, ಪಾವತಿ ಸ್ಥಿತಿ, ಆರ್ಡರ್ ಮೌಲ್ಯ ಇತ್ಯಾದಿಗಳಂತಹ ಸಂಪೂರ್ಣ ವಿವರಗಳೊಂದಿಗೆ ನಿಮ್ಮ ಎಲ್ಲಾ ಆರ್ಡರ್‌ಗಳನ್ನು ಒಂದೇ ಸ್ಥಳದಲ್ಲಿ ವೀಕ್ಷಿಸಲು ಅರ್ಥಗರ್ಭಿತ ಮತ್ತು ಸುಲಭವಾದ ನ್ಯಾವಿಗೇಟ್ ಡ್ಯಾಶ್‌ಬೋರ್ಡ್.",
  "orders.alerts": "ಆರ್ಡರ್ ಎಚ್ಚರಿಕೆಗಳು",
  "orders.alerts.desc":
    "ಆರ್ಡರ್ ಪ್ಲೇಸ್‌ಮೆಂಟ್, ರದ್ದತಿ, ಡೆಲಿವರಿ ಇತ್ಯಾದಿ ಪ್ರಮುಖ ಈವೆಂಟ್‌ಗಳಲ್ಲಿ ತ್ವರಿತ SMS/ ಪುಶ್ ಅಧಿಸೂಚನೆಗಳನ್ನು ಪಡೆಯುವ ಮೂಲಕ ನಿಮ್ಮ ಗ್ರಾಹಕರ ಆರ್ಡರ್‌ಗಳ ಮೇಲೆ ಉಳಿಯಿರಿ.",
  "delivery.area.management": "ಡೆಲಿವರಿ ಏರಿಯಾ ಮ್ಯಾನೇಜ್‌ಮೆಂಟ್",
  "delivery.area.management.desc":
    "ಪಿನ್‌ಕೋಡ್‌ಗಳು ಅಥವಾ ಸ್ಟೋರ್‌ನಿಂದ ದೂರ ಅಥವಾ ಮ್ಯಾಪ್‌ನಲ್ಲಿ ಡ್ರಾಯಿಂಗ್ ಮಾಡುವ ಮೂಲಕ ನೀವು ತಲುಪಿಸಬಹುದಾದ ಪ್ರದೇಶಗಳನ್ನು ನಿರ್ಬಂಧಿಸಿ.",
  "delivery.partner.integration": "ವಿತರಣಾ ಪಾಲುದಾರ ಏಕೀಕರಣ",
  "delivery.partner.integration.desc":
    "ನಿಮ್ಮ ಗ್ರಾಹಕ ಆರ್ಡರ್‌ಗಳನ್ನು ನಿಮ್ಮದೇ ಆದ ಮೇಲೆ ತಲುಪಿಸಿ ಅಥವಾ ನಿಮ್ಮ ನಗರದೊಳಗೆ ಅಥವಾ ಭಾರತದಲ್ಲಿ ಎಲ್ಲಿಯಾದರೂ ಸಾಗಿಸಲು ನಮ್ಮ ವಿತರಣಾ ಪಾಲುದಾರರ ಪಟ್ಟಿಯಿಂದ ಆಯ್ಕೆಮಾಡಿ.",
  "customer.details": "ಗ್ರಾಹಕರ ವಿವರಗಳು",
  "customer.details.desc":
    "ನಿಮ್ಮ ಎಲ್ಲಾ ಗ್ರಾಹಕರ ಡೇಟಾ ಮತ್ತು ಅವರ ಖರೀದಿ ಇತಿಹಾಸವು ನಿಮಗೆ ಸಂಪಾದಿಸಲು, ಡೌನ್‌ಲೋಡ್ ಮಾಡಲು, ಹುಡುಕಲು ಅಥವಾ ಗುಂಪು ಮಾಡಲು ಒಂದೇ ಸ್ಥಳದಲ್ಲಿ ಲಭ್ಯವಿದೆ. ನಿಮ್ಮ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಗ್ರಾಹಕರ ಡೇಟಾವನ್ನು ಆಮದು ಮಾಡಿಕೊಳ್ಳಿ ಮತ್ತು ಎಲ್ಲವನ್ನೂ ಒಂದೇ ಸ್ಥಳದಲ್ಲಿ ಇರಿಸಿ.",
  "live.chat": "ಲೈವ್ ಚಾಟ್",
  "live.chat.desc":
    "ನಿಮ್ಮ ಗ್ರಾಹಕರು ನಿಮ್ಮ ಅಂಗಡಿಯಲ್ಲಿ ಶಾಪಿಂಗ್ ಮಾಡುತ್ತಿರುವಾಗ ಲೈವ್ ಚಾಟ್ ವಿಜೆಟ್ ಮೂಲಕ ಅವರ ಪ್ರಶ್ನೆಗಳನ್ನು ಪರಿಹರಿಸಿ.",
  "order.returns": "ಆರ್ಡರ್ ರಿಟರ್ನ್ಸ್",
  "order.returns.desc":
    "ಆರ್ಡರ್ ರಿಟರ್ನ್ಸ್ ಅನ್ನು ಮನಬಂದಂತೆ ನಿರ್ವಹಿಸಿ, ಸ್ವಯಂಚಾಲಿತವಾಗಿ ನಿಮ್ಮ ದಾಸ್ತಾನು ಹೊಂದಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ಮರುಪಾವತಿ ಮಾಡಿ.",
  "multi.user.access": "ಬಹು-ಬಳಕೆದಾರ ಪ್ರವೇಶ",
  "multi.user.access.desc":
    "ನಿಮ್ಮ ಅಂಗಡಿಯನ್ನು ನಿರ್ವಹಿಸಲು ನಿಮ್ಮ ಉದ್ಯೋಗಿಗಳಿಗೆ ಅನುಮತಿಸಿ. ಪಾತ್ರಗಳು ಮತ್ತು ಅನುಮತಿಗಳನ್ನು ಹೊಂದಿಸಿ. ಶಿಫ್ಟ್‌ಗಳು ಮತ್ತು ಹಾಜರಾತಿಯನ್ನು ನಿರ್ವಹಿಸಿ.",
  "multi.store": "ಬಹು-ಅಂಗಡಿ",
  "multi.store.desc":
    "ಒಂದಕ್ಕಿಂತ ಹೆಚ್ಚು ಭೌತಿಕ ಅಂಗಡಿಯನ್ನು ಹೊಂದಿರುವಿರಾ? ಬೆಲೆಗಳು, ಕೊಡುಗೆಗಳು, ವಿತರಣಾ ಶುಲ್ಕಗಳನ್ನು ಆಧರಿಸಿ ನಿರ್ವಹಿಸಲು ನಾವು ನಿಮಗೆ ಅವಕಾಶ ನೀಡುತ್ತೇವೆ",
  "reports.desc":
    "ಪ್ರಮಾಣಿತ ಮಾರಾಟ, ಮಾರ್ಕೆಟಿಂಗ್, ಕಾರ್ಯಾಚರಣೆಗಳು, ಗ್ರಾಹಕ, ಸ್ಟಾಕ್ ವರದಿಗಳನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ ಅಥವಾ ನಿಮ್ಮ ಕಸ್ಟಮ್ ವರದಿಯನ್ನು ರಚಿಸಿ.",
  "analytics.desc":
    "ನಿಮ್ಮ ವ್ಯಾಪಾರದ ಆರೋಗ್ಯವನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ನಮ್ಮ ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಬಳಸಿ. ಅರ್ಥಪೂರ್ಣ ಒಳನೋಟಗಳು ಮತ್ತು ವ್ಯವಹಾರ ಬುದ್ಧಿವಂತಿಕೆಯನ್ನು ಪಡೆಯಲು ನಿಮ್ಮ ಮಾರಾಟ, ಮಾರ್ಕೆಟಿಂಗ್ ಪ್ರಚಾರಗಳು, ಕಾರ್ಯಾಚರಣೆಗಳು, ಆದೇಶಗಳು, ಗ್ರಾಹಕರ ಬೆಳವಣಿಗೆ, ಸ್ಟಾಕ್ ಅನ್ನು ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಿ ಮತ್ತು ಹೋಲಿಕೆ ಮಾಡಿ.",
  "fb.analytics": "Facebook Analytics",
  "fb.analytics.desc":
    "ನಿಮ್ಮ Facebook ಪಿಕ್ಸೆಲ್ ID ಬಳಸಿಕೊಂಡು ನಿಮ್ಮ Facebook ಜಾಹೀರಾತುಗಳ ಕಾರ್ಯಕ್ಷಮತೆಯನ್ನು ಸುಲಭವಾಗಿ ಸಂಯೋಜಿಸಿ ಮತ್ತು ಟ್ರ್ಯಾಕ್ ಮಾಡಿ.",
  "google.analytics": "Google Analytics",
  "google.analytics.desc":
    "ನಿಮ್ಮ ಗ್ರಾಹಕರ ಸ್ವಾಧೀನ ಚಾನಲ್‌ಗಳು, ಜನಸಂಖ್ಯಾಶಾಸ್ತ್ರ, ಆದಾಯ ಮತ್ತು ಇತರ ಶ್ರೀಮಂತ ಒಳನೋಟಗಳನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ನಿಮ್ಮ eStore ನೊಂದಿಗೆ ನಿಮ್ಮ Google Analytics ಅನ್ನು ಸುಲಭವಾಗಿ ಸಂಯೋಜಿಸಿ.",
  "customer.app": "ಗ್ರಾಹಕ ಅಪ್ಲಿಕೇಶನ್",
  "customer.app.desc":
    "ನಿಮ್ಮ ಅಂಗಡಿಗಾಗಿ ಉಚಿತ ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಮತ್ತು ಬ್ರಾಂಡ್ ಮಾಡಿದ iOS ಮತ್ತು Android ಗ್ರಾಹಕ ಅಪ್ಲಿಕೇಶನ್. ನಿಮ್ಮ ಬ್ರ್ಯಾಂಡ್ ಅನ್ನು ಪ್ರತಿಬಿಂಬಿಸಲು ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಹೆಸರು, ಲಾಂಚ್ ಐಕಾನ್ ಮತ್ತು ಸ್ಪ್ಲಾಶ್ ಸ್ಕ್ರೀನ್‌ಗಳನ್ನು ಹೊಂದಿಸಿ.",
  "delivery.app": "ಡೆಲಿವರಿ ಅಪ್ಲಿಕೇಶನ್",
  "delivery.app.desc":
    "ನಿಮ್ಮ ಡೆಲಿವರಿ ಸಿಬ್ಬಂದಿಗೆ ಆರ್ಡರ್‌ಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲು, ಆದ್ಯತೆ ನೀಡಲು ಮತ್ತು ತಲುಪಿಸಲು ಉಚಿತ Android ಅಪ್ಲಿಕೇಶನ್.",
  "picker.app": "ಪಿಕರ್ ಅಪ್ಲಿಕೇಶನ್",
  "picker.app.desc":
    "ನಿಮ್ಮ ಸಿಬ್ಬಂದಿಗೆ ಆರ್ಡರ್‌ಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲು, ಪ್ಯಾಕ್ ಮಾಡಲು ಮತ್ತು ಪರಿಶೀಲಿಸಲು ಮತ್ತು ಲೇಬಲ್‌ಗಳನ್ನು ಮುದ್ರಿಸಲು ಉಚಿತ Android ಅಪ್ಲಿಕೇಶನ್.",
  "admin.app": "ನಿರ್ವಾಹಕ ಅಪ್ಲಿಕೇಶನ್",
  "admin.app.desc":
    "ನಿಮ್ಮ ಮೊಬೈಲ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ ಅನ್ನು ನಿರ್ವಹಿಸಲು ಉಚಿತ iOS ಮತ್ತು Android ಅಪ್ಲಿಕೇಶನ್. ನಿಮ್ಮ ಮಾರಾಟಗಳನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಿ, ಆರ್ಡರ್‌ಗಳನ್ನು ಪರಿಶೀಲಿಸಿ, ನಿಮ್ಮ ಉತ್ಪನ್ನಗಳನ್ನು ಮಾರ್ಪಡಿಸಿ, ಮಾರ್ಕೆಟಿಂಗ್ ಪ್ರಚಾರಗಳನ್ನು ರನ್ ಮಾಡಿ ಮತ್ತು ಪ್ರಯಾಣದಲ್ಲಿರುವಾಗ ನಿಮ್ಮ ಸ್ಟೋರ್ ಸೆಟ್ಟಿಂಗ್‌ಗಳನ್ನು ಬದಲಾಯಿಸಿ.",
  "custom.password.format": "ಗ್ರಾಹಕರ ಪಾಸ್‌ವರ್ಡ್ ಫಾರ್ಮ್ಯಾಟ್ ಸೆಟಪ್",
  "custom.password.format.desc":
    "ಪಾಸ್‌ವರ್ಡ್ ರಚಿಸುವಾಗ ನಿಮ್ಮ ಗ್ರಾಹಕರು ಅನುಸರಿಸಬೇಕಾದ ಕಸ್ಟಮ್ ನಿಯಮಗಳನ್ನು ಹೊಂದಿಸಿ ಆ ಮೂಲಕ ಭದ್ರತೆಯನ್ನು ಸುಧಾರಿಸುತ್ತದೆ.",
  "data.security": "ಡೇಟಾ ಭದ್ರತೆ",
  "data.security.desc": "ನಿಮ್ಮ ಡೇಟಾವನ್ನು ನೀವು ಮಾತ್ರ ಪ್ರವೇಶಿಸಬಹುದು.",
  "data.and.security": "ಡೇಟಾ ಮತ್ತು ಭದ್ರತೆ",
  apps: "ಅಪ್ಲಿಕೇಶನ್‌ಗಳು",
  channels: "ಚಾನಲ್‌ಗಳು",
  "customer.management": "ಗ್ರಾಹಕ ನಿರ್ವಹಣೆ",
  delivery: "ವಿತರಣೆ",
  marketing: "ಮಾರ್ಕೆಟಿಂಗ್",
  cataloguing: "ಕ್ಯಾಟಲಾಗ್ ಮಾಡುವುದು",
  "testimonials.ambros":
    "I was completely new to e-commerce and scared about the last-mile delivery operations. I couldn’t have asked for a better or easier solution than the Zopping e-commerce platform. The entire process from order placement on web/mobiles apps through last-mile delivery is easy and extremely efficient. A fantastic experience overall! Amazing solution for retailers at an affordable price from the Zopping team!",
  "testimonials.medinmin":
    "ವೆಬ್ ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್ ಅಭಿವೃದ್ಧಿಗಾಗಿ ನೋಡುತ್ತಿರುವ ಪ್ರತಿಯೊಬ್ಬರಿಗೂ ಇ-ಕಾಮ್ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಜೋಪಿಂಗ್ ಮಾಡಲು ನಾನು ಶಿಫಾರಸು ಮಾಡುತ್ತೇವೆ. ಅವರು ನಿಮ್ಮ ಅವಶ್ಯಕತೆಗಳನ್ನು ತಕ್ಷಣವೇ ಪೂರೈಸುವ ಪ್ಲಗ್ ಮತ್ತು ಪ್ಲೇ ಕಾರ್ಯನಿರ್ವಹಣೆಗಳನ್ನು ಹೊಂದಿದ್ದಾರೆ. ಅವರು ನನ್ನ ನಿರೀಕ್ಷೆಗಳನ್ನು ಪೂರೈಸಿದ್ದು ಮಾತ್ರವಲ್ಲದೆ ಅವುಗಳನ್ನು ಮೀರಿದ್ದಾರೆ. ವೆಬ್ ಮತ್ತು ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ಗಳು ಬಹಳ ಆಕರ್ಷಕವಾಗಿವೆ ಮತ್ತು ಬಳಕೆದಾರರೊಂದಿಗೆ ಅತ್ಯುತ್ತಮವಾದ ನಿಶ್ಚಿತಾರ್ಥವನ್ನು ಸಹ ನೀಡುತ್ತವೆ. ಅವರ ಸೇವೆ, ಸಾಫ್ಟ್‌ವೇರ್ ಮತ್ತು ವೃತ್ತಿಪರತೆಯಿಂದ ನಾನು ನಿಜವಾಗಿಯೂ ಪ್ರಭಾವಿತನಾಗಿದ್ದೇನೆ.",
  "testimonials.blossom":
    "ನಮ್ಮ ಪುಸ್ತಕಗಳ ವ್ಯಾಪಾರವನ್ನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ತೆಗೆದುಕೊಳ್ಳಲು ನಮಗೆ ಅಪ್ಲಿಕೇಶನ್ ಅಗತ್ಯವಿರುವಾಗ ಝೋಪಿಂಗ್ ನಮಗೆ ಸರಿಯಾದ ಮಾರ್ಗದರ್ಶನವನ್ನು ನೀಡಿತು. ನಾನು ನಿರ್ಧರಿಸುವ ಮೊದಲು, ನಾವು ಅವರ ಮಾರಾಟ ತಂಡದೊಂದಿಗೆ ಚರ್ಚೆ ನಡೆಸಿದ್ದೇವೆ ಮತ್ತು ನಮ್ಮ ಕಲ್ಪನೆಯನ್ನು ಆನ್‌ಲೈನ್ ಪುಸ್ತಕ ಅಂಗಡಿಯಾಗಿ ಪರಿವರ್ತಿಸುವುದು ಹೇಗೆ ಎಂಬುದರ ಕುರಿತು ನಮಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡಿತು. ನಂತರ, ಆನ್‌ಬೋರ್ಡಿಂಗ್ ತಂಡವು ಸೆಟಪ್ ಪ್ರಕ್ರಿಯೆಯ ಉದ್ದಕ್ಕೂ ನಮ್ಮೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿತ್ತು ಮತ್ತು ಅವರು ನಮ್ಮ ಅಗತ್ಯಗಳಿಗೆ ಅನುಗುಣವಾಗಿ ವೆಬ್‌ಸೈಟ್ ಅನ್ನು ಕಸ್ಟಮೈಸ್ ಮಾಡುತ್ತಾರೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಂಡರು. ಒಂದು ಟನ್ ಧನ್ಯವಾದಗಳು, ಜೋಪಿಂಗ್ ತಂಡ!",
  "testimonials.name.ambros": "ರೋಹಿತ್ ಕುಮಾರ್",
  "testimonials.designation.ambros": "ನಿರ್ದೇಶಕ",
  "testimonials.company.ambros": "ಆಂಬ್ರೋಸ್ ರಿಟೇಲ್",
  "testimonials.name.medinmin": "ಇರ್ಫಾನ್ ಬಿ",
  "testimonials.designation.medinmin": "ಓಪ್ಸ್ ಮ್ಯಾನೇಜರ್",
  "testimonials.company.medinmin": "ಮೆಡಿನ್ಮಿನಿಟ್ಸ್",
  "testimonials.name.blossom": "ಮಯೇಗೌಡ",
  "testimonials.designation.blossom": "ವ್ಯವಸ್ಥಾಪಕ ನಿರ್ದೇಶಕ",
  "testimonials.company.blossom": "ಬ್ಲಾಸಮ್ ಬುಕ್ ಹೌಸ್",
  Hyperlocal: "ಹೈಪರ್ಲೋಕಲ್",
  "Hyperlocal.descroption":
    "ನಿಮ್ಮ ನೆರೆಹೊರೆ ಅಥವಾ ಪ್ರದೇಶದಲ್ಲಿ ಮಾರಾಟ ಮಾಡಲು ಮತ್ತು ಡಿಜಿಟಲ್ ಮಾರ್ಕೆಟ್‌ಪ್ಲೇಸ್‌ನಲ್ಲಿ ಯಶಸ್ವಿಯಾಗಲು ಝೋಪಿಂಗ್ ಪರಿಪೂರ್ಣ ಐಕಾಮರ್ಸ್ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಅನ್ನು ಒದಗಿಸುತ್ತದೆ. ಝೋಪಿಂಗ್ ಉಳಿದವುಗಳಿಂದ ಏಕೆ ಎದ್ದು ಕಾಣುತ್ತದೆ ಎಂಬುದು ಇಲ್ಲಿದೆ.",
  "Create.Your.Online.Store": "ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಅಂಗಡಿಯನ್ನು ರಚಿಸಿ",
  "Best.suited.for.selling": "ಮಾರಾಟಕ್ಕೆ ಹೆಚ್ಚು ಸೂಕ್ತವಾಗಿದೆ",
  Grocery: "ದಿನಸಿ",
  "Food.and.Beverage": "ಆಹಾರ ಮತ್ತು ಪಾನೀಯ",
  Medicine: "ಔಷಧಿ",
  Bakery: "ಬೇಕರಿ",
  Diary: "ಡೈರಿ",
  Fish: "ಮೀನು",
  "Embrace.Convenience": "ಅನುಕೂಲತೆಯನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳಿ",
  "Embrace.Convenience.discription1":
    "ಝೋಪಿಂಗ್ ಸ್ಟೋರ್ ಪಿಕಪ್ ಮತ್ತು ಡೆಲಿವರಿ ಆಯ್ಕೆಗಳನ್ನು ನೀಡುತ್ತದೆ, ನಿಮ್ಮ ಗ್ರಾಹಕರ ಆದ್ಯತೆಗಳನ್ನು ಪೂರೈಸುತ್ತದೆ..",
  "Embrace.Convenience.discription2":
    "ನಿಮ್ಮ ಕ್ಯಾಟಲಾಗ್, ಇನ್ವೆಂಟರಿ, ಆರ್ಡರ್‌ಗಳು ಮತ್ತು ಗ್ರಾಹಕರ ಡೇಟಾವನ್ನು ಝೋಪಿಂಗ್‌ನ ಓಮ್ನಿಚಾನಲ್ ಪಾಯಿಂಟ್ ಆಫ್ ಸೇಲ್ (POS) ಸಿಸ್ಟಮ್‌ನೊಂದಿಗೆ ಸಿಂಕ್ ಮಾಡಿ..",
  "Flexible.Order.Management": "ಹೊಂದಿಕೊಳ್ಳುವ ಆದೇಶ ನಿರ್ವಹಣೆ",
  "Flexible.Order.Management.description1":
    "ಪಿನ್‌ಕೋಡ್‌ಗಳು/ ಪ್ರದೇಶ/ ಅಪಾರ್ಟ್‌ಮೆಂಟ್‌ಗಳ ಮೂಲಕ ನಿಮ್ಮ ಸೇವಾ ಪ್ರದೇಶಗಳನ್ನು ವಿವರಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಸ್ಥಳೀಯ ಗ್ರಾಹಕರ ನೆಲೆಯನ್ನು ಸಮರ್ಥವಾಗಿ ಪೂರೈಸಿ.. ಝೋಪಿಂಗ್‌ಆಫರ್‌ಗಳು ಸ್ಟೋರ್ ಪಿಕಪ್ ಮತ್ತು ಡೆಲಿವರಿ ಆಯ್ಕೆಗಳನ್ನು ನಿಮ್ಮ ಗ್ರಾಹಕರ ಆದ್ಯತೆಗಳನ್ನು ಪೂರೈಸುತ್ತದೆ.",
  "Flexible.Order.Management.description2":
    "ಝೋಪಿಂಗ್ ಬಹು ಶಾಖೆಗಳು ಅಥವಾ ಅಂಗಡಿಗಳೊಂದಿಗೆ ವ್ಯವಹಾರಗಳನ್ನು ಬೆಂಬಲಿಸುತ್ತದೆ, ಕಾರ್ಯಾಚರಣೆಗಳನ್ನು ಸುಗಮಗೊಳಿಸುತ್ತದೆ ಮತ್ತು ವಿಶಾಲವಾದ ಗ್ರಾಹಕರ ನೆಲೆಯನ್ನು ತಲುಪುತ್ತದೆ.",
  "Flexible.Order.Management.description3":
    " ಹೆಚ್ಚುವರಿ ಶುಲ್ಕಗಳು ಅಥವಾ ರಿಯಾಯಿತಿಗಳೊಂದಿಗೆ ನಿರ್ದಿಷ್ಟ ಪಿಕಪ್ ಅಥವಾ ಡೆಲಿವರಿ ಸ್ಲಾಟ್‌ಗಳನ್ನು ಪ್ರೋತ್ಸಾಹಿಸಿ.",
  "Flexible.Order.Management.description4":
    " ಆರ್ಡರ್‌ಗಳನ್ನು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ನಿರ್ವಹಿಸಲು ಮಿತಿಗಳನ್ನು ಹೊಂದಿಸಿ ಮತ್ತು ಗರಿಷ್ಠ ಅವಧಿಗಳಲ್ಲಿ ಅಗಾಧ ಬೇಡಿಕೆಗಳನ್ನು ತಡೆಯಿರಿ.",
  "Simplified.Delivery.Management": "ಸರಳೀಕೃತ ವಿತರಣಾ ನಿರ್ವಹಣೆ",
  "Simplified.Delivery.Management.description1":
    "ಝೋಪಿಂಗ್ ಡೆಲಿವರಿ ಅಪ್ಲಿಕೇಶನ್ ಆರ್ಡರ್ ಹ್ಯಾಂಡ್ಲಿಂಗ್ ಅನ್ನು ಕ್ರಾಂತಿಗೊಳಿಸುತ್ತದೆ, ಪ್ರಾಂಪ್ಟ್ ಮತ್ತು ವಿಶ್ವಾಸಾರ್ಹ ಸೇವೆಯನ್ನು ಖಾತ್ರಿಪಡಿಸುತ್ತದೆ.",
  "Simplified.Delivery.Management.description2":
    "ಪ್ರಯಾಣದ ದೂರದ ಆಧಾರದ ಮೇಲೆ ಬದಲಾಗುವ ವಿತರಣಾ ಶುಲ್ಕಗಳು ವೆಚ್ಚವನ್ನು ಒಳಗೊಂಡಿರುವಾಗ ನ್ಯಾಯಯುತ ಬೆಲೆಯನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ.",
  "Delight.your.customers": "ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ಸಂತೋಷಪಡಿಸಿ",
  "Delight.your.customers.description1":
    "ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಮತ್ತು ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ನಾದ್ಯಂತ ತಡೆರಹಿತ ಮತ್ತು ಸ್ಥಿರವಾದ ಶಾಪಿಂಗ್ ಅನುಭವವನ್ನು ಒದಗಿಸಿ.",
  "Delight.your.customers.description2":
    "ಎಲ್ಲಿಂದಲಾದರೂ, ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಆರ್ಡರ್‌ಗಳನ್ನು ಇರಿಸುವ ಅನುಕೂಲವನ್ನು ಒದಗಿಸಿ.",
  "Zopping.Advantage": "ಝೋಪಿಂಗ್ ಅಡ್ವಾಂಟೇಜ್",
  "Zopping.Advantage.description1":
    "ಸ್ಪರ್ಧೆಯ ಮುಂದೆ ಉಳಿಯಲು ನಿಯಮಿತ ನವೀಕರಣಗಳು ಮತ್ತು ನಡೆಯುತ್ತಿರುವ ಸುಧಾರಣೆಗಳಿಂದ ಪ್ರಯೋಜನ ಪಡೆಯಿರಿ.",
  "Zopping.Advantage.description2":
    " ನಿಮ್ಮ ಕಸ್ಟಮ್-ಬ್ರಾಂಡ್ ಮಾಡಲಾದ ಸ್ಥಳೀಯ iOS ಮತ್ತು Android ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಪಡೆದುಕೊಳ್ಳಿ ಮತ್ತು ಒಂದು ದಿನಕ್ಕಿಂತ ಕಡಿಮೆ ಅವಧಿಯಲ್ಲಿ ಚಾಲನೆಯಲ್ಲಿದೆ.",
  "Zopping.Advantage.description3":
    "ಸ್ಥಳ ಮತ್ತು ಇದು ನಿಮ್ಮ ವೆಬ್‌ಸೈಟ್ ಮತ್ತು ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಸ್ವಯಂಚಾಲಿತವಾಗಿ ನವೀಕರಿಸುತ್ತದೆ.",
  "Engage.your.customer.with.Push":
    "ಪುಶ್ ಅಧಿಸೂಚನೆಗಳೊಂದಿಗೆ ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳಿ",
  "Engage.your.customer.with.Push.description1":
    "ಮಾರಾಟವನ್ನು ಹೆಚ್ಚಿಸಿ ಮತ್ತು ವೈಯಕ್ತಿಕಗೊಳಿಸಿದ ಸಂದೇಶಗಳು, ವಿಶೇಷ ಕೊಡುಗೆಗಳು ಮತ್ತು ಹೊಸ ಆಗಮನದ ಅಧಿಸೂಚನೆಗಳೊಂದಿಗೆ ನೈಜ ಸಮಯದಲ್ಲಿ ಗ್ರಾಹಕರನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳಿ.",
};

export default messages;
