import React, { useContext } from "react";
import en from "../languages/en";
import { LanguageContext } from "../Providers";

export const Message = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const { dictKey } = props;
  return (
    <span
      className="translation-span"
      dangerouslySetInnerHTML={{
        __html: dictionary?.[dictKey] || en?.[dictKey] || dictKey,
      }}
    />
  );
};

export const useMessage = () => {
  const { dictionary } = useContext(LanguageContext);
  return function (dictKey) {
    return dictionary?.[dictKey] || en?.[dictKey] || dictKey;
  };
};

export const Input = ({ placeholderKey, ...props }) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <input
      placeholder={
        dictionary?.[placeholderKey] || en?.[placeholderKey] || placeholderKey
      }
      {...props}
    />
  );
};

export const Textarea = ({ placeholderKey, ...props }) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <textarea
      placeholder={
        dictionary?.[placeholderKey] || en?.[placeholderKey] || placeholderKey
      }
      {...props}
    />
  );
};
